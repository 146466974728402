import React from "react";
import styles from "./styles/PrimaryButton.module.css";
import Button from "./Button";

export default function PrimaryButton(props) {
  return (
    <Button
      target={props.target}
      download={props.download}
      style={props.style}
      destructive={props.destructive}
      disabled={props.disabled}
      linkClassName={props.linkClassName}
      to={props.to}
      spinner={props.spinner}
      size={props.size}
      onClick={props.onClick}
      className={`${styles.defaults} ${props.bright && styles.bright} ${
        props.disabled && styles.disabled
      } ${props.className}`}
    >
      {props.children}
    </Button>
  );
}
