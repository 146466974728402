import { gql } from "@apollo/client";

export const GET_REPORTS_BY_SITEID = gql`
  query getReportsBySiteId($siteId: Int!) {
    getReportsBySiteId(siteId: $siteId) {
      reportId
      siteId
      name
      status
      language
      reportType
      keywords
      instruction
      archived
    }
  }
`;