import { gql } from "@apollo/client";

export const GET_PROSPECTS_BY_PROSPECTOR = gql`
  query getProspectsByProspector($prospectedBy: String!) {
    getProspectsByProspector(prospectedBy: $prospectedBy) {
      prospectId
      name
      email
      prospectedBy
      optedOut
      stage
      lastSent
    }
  }
`;

export const GET_USERS = gql`
  query {
    getUsers {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const GET_USER_COUNT = gql`
  query {
    getUserCount
  }
`;

export const GET_STATS = gql`
  query getStatsByOwner($owner: String!) {
    getStatsByOwner(owner: $owner) {
      siteCount
      totalReportsCount
      totalArticlesCount
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
    }
  }
`;

export const AUTH_USER = gql`
  query authUser($email: String!, $password: String!) {
    authUser(email: $email, password: $password) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
    }
  }
`;

export const GET_USER_BY_HASH = gql`
  query getUserByHash($hash: String!) {
    getUserByHash(hash: $hash) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
    }
  }
`;

export const GET_USER_BY_LINK = gql`
  query getUserByLink($link: String!) {
    getUserByLink(link: $link) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      sections {
        sectionId
        name
        links {
          linkId
          sectionId
          uri
          owner
          slug
          archived
          visible
          description
          thumbnail
          tag
          tagColor
          tagTextColor
        }
        archived
        visible
      }
    }
  }
`;

export const LINK_EXISTS = gql`
  query linkExists($link: String!) {
    linkExists(link: $link)
  }
`;

export const GET_REPOS = gql`
  query getRepos($accessToken: String!) {
    getRepos(accessToken: $accessToken)
  }
`;

export const VERIFY_GOOGLE_AUTH = gql`
  query verifyGoogleAuth($token: String!) {
    verifyGoogleAuth(token: $token) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
    }
  }
`;
