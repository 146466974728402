import { useApolloClient } from "@apollo/client";
import { Edit2, Mail, MoreVertical, Trash, Upload } from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { ConfigContext } from "../../../context/ConfigProvider";
import { EmailContext } from "../../../context/EmailProvider";
import { StorageContext } from "../../../context/StorageProvider";
import {
  ADD_PROSPECTS,
  SET_PROSPECT_STAGE,
} from "../../../graphql/mutations/Users";
import { GET_PROSPECTS_BY_PROSPECTOR } from "../../../graphql/queries/Users";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import Table from "../../table/Table";
import H5 from "../../text/heading/H5";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/Prospects.module.css";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import Button from "../../buttons/Button";
import DeleteProspect from "../../modals/impl/DeleteProspect";
import { OverlayContext } from "../../../context/OverlayProvider";
import Modal from "../../modals/Modal";
import EditProspect from "../../modals/impl/EditProspect";

export default function Prospects(props) {
  const client = useApolloClient();

  const fileSelectionRef = useRef();

  const { userDetails } = useContext(StorageContext);

  const [prospects, setProspects] = useState(null);

  const [error, setError] = useState(null);

  const { sendEmail } = useContext(EmailContext);

  const { update, setUpdate } = useContext(ConfigContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
  useContext(OverlayContext);

  async function uploadProspects(imports) {
    const response = await client.mutate({
      mutation: ADD_PROSPECTS,
      variables: {
        prospects: imports,
      },
      fetchPolicy: "no-cache",
    });
    if (response && response.data && response.data.addProspects) {
      setProspects(response.data.addProspects);
    }
  }

  async function getProspects() {
    const response = await client
      .query({
        query: GET_PROSPECTS_BY_PROSPECTOR,
        variables: {
          prospectedBy: userDetails.email,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getProspectsByProspector) {
      setProspects(response.data.getProspectsByProspector);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        const imports = sheetData.map((row) => ({
          prospectId: -1,
          name: row["Name"],
          email: row["Email"],
          prospectedBy: userDetails.email,
          optedOut: false,
        }));
        await uploadProspects(imports);
        setProspects(null);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (prospects === null) {
      getProspects();
    }
  }, [prospects]);

  function isMoreThan3DaysApart(timestamp1, timestamp2) {
    const timeDifference = Math.abs(timestamp1 - timestamp2);
    const sevenDaysInMillis = 3 * 24 * 60 * 60 * 1000;
    return timeDifference >= sevenDaysInMillis;
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async function email() {
    prospects.map(async (prospect) => {
      if (prospect.optedOut === true) {
        return;
      }
      if (prospect.stage && prospect.stage > 0) {
        const currentDate = new Date();
        const unixTimestamp = currentDate.getTime();
        if (isMoreThan3DaysApart(prospect.lastSent, unixTimestamp)) {
          switch (prospect.stage) {
            case 1:
              await sendEmail({
                from: `${userDetails.name
                  .toLowerCase()
                  .replaceAll(" ", "")}@draftsai.com`,
                fromName: `${userDetails.name} with Drafts AI`,
                to: prospect.email,
                subject: "Regarding my last email",
                template: "rebound",
                templateParams: {
                  name: prospect.name,
                  email: prospect.email,
                  sender: capitalizeFirstLetter(userDetails.name),
                },
              });
              await client.mutate({
                mutation: SET_PROSPECT_STAGE,
                variables: {
                  prospectId: prospect.prospectId,
                  stage: 2,
                },
                fetchPolicy: "no-cache",
              });
              break;
          }
        }
      } else {
        await sendEmail({
          from: `${userDetails.name
            .toLowerCase()
            .replaceAll(" ", "")}@draftsai.com`,
          fromName: `${userDetails.name} with Drafts AI`,
          to: prospect.email,
          subject: "Automate your company blog with Drafts AI",
          template: "outbound",
          templateParams: {
            name: prospect.name,
            email: prospect.email,
            sender: capitalizeFirstLetter(userDetails.name),
          },
        });
        await client.mutate({
          mutation: SET_PROSPECT_STAGE,
          variables: {
            prospectId: prospect.prospectId,
            stage: 1,
          },
          fetchPolicy: "no-cache",
        });
      }
    });
    setTimeout(() => {
      setUpdate(true);
      setProspects(null);
    }, 5000);
  }

  function onDelete() {
    setProspects(null);
  }

  const openDeleteProspectModal = (item) => {
    openOverlay(
      <Modal title="Delete Prospect">
        <DeleteProspect onDelete={onDelete} prospect={item} />
      </Modal>
    );
  };

  const openEditProspectModal = (item) => {
    openOverlay(
      <Modal title="Edit Prospect">
        <EditProspect onEdit={onDelete} prospect={item} />
      </Modal>
    );
  };

  return (
    <View
      className={styles.contentContainer2}
      vertical
      style={{
        width: !props.showMenu ? `calc(100% - 75px)` : `calc(100% - 285px)`,
      }}
    >
      <View vertical className={styles.innerView}>
        <View vertical>
          <Spacer size="medium" />
          <View className={styles.pageHeader}>
            <View vertical className={styles.pageHeaderLeftContainer}>
            {prospects === null ? (
                undefined
              ) : (
                <H5 className={styles.smallH5}>Prospects ({prospects.length})</H5>
              )}
              <Spacer size="small" />
              <Paragraph>Upload a spreadsheet of prospects</Paragraph>
            </View>
            <View gap="small" className={styles.pageHeaderRightContainer}>
              <PrimaryButton
                disabled={prospects === null || prospects.length === 0}
                spinner
                onClick={email}
                size="small"
                className={styles.smallIconButton}
              >
                <Mail size="16px" /> Email prospects
              </PrimaryButton>
              <SecondaryButton size="small" className={styles.smallIconButton}>
                <input
                  onChange={handleFileChange}
                  ref={fileSelectionRef}
                  className={styles.absoluteInput}
                  type="file"
                  accept=".xlsx,.xls"
                />
                <Upload size="16px" /> Import prospects
              </SecondaryButton>
            </View>
          </View>
          <Spacer size="medium" />
          <Table
            emptyDataTitle={"No prospects found."}
            emptyDataMessage={"Your prospects will appear here."}
            data={prospects}
            fields={[
              {
                name: "Name",
                data: "name",
              },
              {
                name: "Email",
                data: "email",
              },
              {
                name: "Stage",
                data: "stage",
              },
            ]}
            onClickHandler={(item) => {}}
            menu={(item) => {
              return (
                <Menu>
                  <MenuButton>
                    <Button
                      style={{ maxWidth: "18px", padding: "0px !important" }}
                      className={styles.editListItem}
                      tooltip="More"
                      size="mini"
                    >
                      <MoreVertical size="16" />
                    </Button>
                  </MenuButton>
                  <MenuList className={styles.dropMenu}>
                    <MenuGroup className={styles.dropMenuGroup}>
                      <Button
                        onClick={() => {
                          openEditProspectModal(item);
                        }}
                        className={styles.listButton}
                      >
                        <Edit2 size="14px" /> Edit prospect
                      </Button>
                      <Button
                        onClick={() => {
                          openDeleteProspectModal(item);
                        }}
                        style={{
                          color: "var(--red)",
                        }}
                        className={styles.listButton}
                      >
                        <Trash size="14px" /> Delete prospect
                      </Button>
                    </MenuGroup>
                    <Spacer size="small" />
                  </MenuList>
                </Menu>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
}
