import React, { useContext } from "react";
import { X } from "react-feather";
import { OverlayContext } from "../../context/OverlayProvider";
import H6 from "../text/heading/H6";
import View from "../view/View";
import Separator from "../view/impl/Separator";
import styles from "./styles/Modal.module.css";

export default function Modal(props) {
  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const { closeOverlay } = useContext(OverlayContext);

  return (
    <div onClick={handleModalClick} vertical className={`${styles.defaults} ${props.className && props.className}`}>
      {props.title ? (
        <View className={styles.titleContainer}>
          <View centerVertically centerHorizontally>
            <H6 className={styles.smallTitle}>{props.title}</H6>
            <View className={styles.rightAligned}>
              <button
                className={styles.closeButton}
                onClick={() => closeOverlay()}
              >
                <X size="18" />
              </button>
            </View>
          </View>
        </View>
      ) : null}
      {props.title ? <Separator className={styles.separator} /> : null}
      {props.children}
    </div>
  );
}
