import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/AlertProvider";
import { ConfigContext } from "../../context/ConfigProvider";
import { RadioCellContext } from "../../context/RadioCellProvider";
import { ADD_USER } from "../../graphql/mutations/Users";
import Container from "../navigation/Container";
import RadioCellContainer from "../radiocell/RadioCellContainer";
import View from "../view/View";
import styles from "./styles/Join.module.css";
import { LINK_EXISTS } from "../../graphql/queries/Users";
import { Helmet } from "react-helmet";
import { EmailContext } from "../../context/EmailProvider";
import H5 from "../text/heading/H5";
import TextInput from "../input/TextInput";
import Paragraph from "../text/paragraph/Paragraph";
import Spacer from "../view/impl/Spacer";
import H6 from "../text/heading/H6";
import H4 from "../text/heading/H4";
import Logo from "../navigation/Logo";
import Link from "../links/Link";
import PrimaryButton from "../buttons/PrimaryButton";
import RadioButton from "../radiobutton/RadioButton";
import Carousel from "better-react-carousel";
import { useNavigate } from "react-router-dom";
import { StorageContext } from "../../context/StorageProvider";
import ReCAPTCHA from "react-google-recaptcha";

export default function Join(props) {

  const { cache, setCache } = useContext(RadioCellContext);

  const client = useApolloClient();

  const { showAlert } = useContext(AlertContext);

  const { sendEmail } = useContext(EmailContext);

  const { darkMode } = useContext(ConfigContext);

  const {
    receivedResponse,
    setReceivedResponse,
    update,
    setUpdate,
    prefixLink,
  } = useContext(ConfigContext);

  const [joinError, setJoinError] = useState(null);

  const [joinWarning, setJoinWarning] = useState(null);

  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState([]);

  const { userDetails, setUserDetails } = useContext(StorageContext);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function getErrorWithType(typeToCheck) {
    return errors.find((error) => error.type === typeToCheck);
  }

  function removeErrorWithType(typeToRemove) {
    const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
    setErrors(updatedErrors);
  }

  useEffect(() => {
    document.title = "Join now and start creating with Drafts";
  }, []);

  const [data, setData] = useState({
    finalMessage: "Welcome to Drafts!",
    questions: [
      {
        id: 1,
        questionType: 2,
        questionTitle: "What should we call you? 🤔",
        questionSubtitle: "We'll use this to personalize your experience.",
        question: "Enter your name",
        inputType: "text",
        inputPlaceholder: "John Doe",
        checkbox: true,
        checkboxRequired: true,
      },
      {
        id: 2,
        questionType: 2,
        questionTitle: "No spam, we promise. 🫶",
        questionSubtitle:
          "We will only use this to send you updates about your account and/or profile.",
        question: "Enter your email address",
        inputType: "email",
        inputPlaceholder: "john@doe.com",
      },
    ],
  });

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  async function createAccount() {
    setReceivedResponse(false);
    let handle = prefixLink;
    let response;
    if (prefixLink) {
      response = await client
        .query({
          query: LINK_EXISTS,
          variables: {
            link: prefixLink,
          },
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          setJoinError("Something went wrong. Please try again.");
        });
      if (response && response.data) {
        const linkExists = response.data.linkExists;
        if (linkExists) {
          handle = makeid(8);
        }
      }
    } else {
      handle = makeid(8);
    }
    response = await client
      .query({
        query: LINK_EXISTS,
        variables: {
          link: handle,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setJoinError("Something went wrong. Please try again.");
      });
    if (response && response.data) {
      const linkExists = response.data.linkExists;
      if (linkExists) {
        handle = makeid(8);
      }
    }
    const defaultAppearanceSettings = {
      backgroundType: 2,
      buttonType: 2,
      backgroundColor: "#0e0e0e",
      gradientColor1: "#393939",
      gradientColor2: "#0e0e0e",
      backgroundURL: null,
      buttonBorderRadius: "8",
      buttonColor: "#000",
      buttonBorderColor: "#fff",
      buttonBackgroundColor: "#fff",
      nameColor: "#fff",
      bioColor: "#c3c3c3",
      locationColor: "#c3c3c3",
      sectionColor: "#fff",
    };
    client
      .mutate({
        mutation: ADD_USER,
        variables: {
          name: name,
          email: email.toLowerCase(),
          type: email.toLowerCase() === "julius@nineteenmediagroup.com" ? 3 : 1,
          verification:
            email.toLowerCase() === "julius@nineteenmediagroup.com" ? 1 : 0,
          link: handle,
          appearanceSettings: JSON.stringify(defaultAppearanceSettings),
          password: password || ""
        },
      })
      .then(async (response) => {
        setCache(null);
        setJoinError(null);
        setUpdate(true);
        setReceivedResponse(true);
        await sendEmail({
          from: "noreply@draftsai.com",
          fromName: "Drafts AI (no-reply)",
          to: email.toLowerCase(),
          subject: "Welcome to Drafts AI!",
          template: "welcome",
          templateParams: {
            name: name
          }
        });
        console.log("response:", response);
        if (password.length >= 8) {
          setUserDetails(response.data.addUser);
          navigate("/app");
        } else {
          setJoinError("A link has been sent to your email to verify your account.");
          await sendEmail({
            from: "noreply@draftsai.com",
            fromName: "Drafts (no-reply)",
            to: email,
            subject: "Login to Drafts",
            template: "login",
            templateParams: {
              name: name,
              hash: "sha256",
              sessionEmail: email, // Passing this will tie the hash to the email.
            },
          });
        }
        return response;
      })
      .catch((error) => {
        switch (error.message) {
          case "already_exists":
            console.error("already exists");
            setCache(null);
            setJoinError("This email has already been used to create a Drafts AI account.");
            setUpdate(true);
            setReceivedResponse(true);
            break;

          default:
            setCache(null);
            setJoinError("Something went wrong. Please try again.");
            console.error(error.message);
            setUpdate(true);
            setReceivedResponse(true);
            break;
        }
      });
  }

  function handleCallback(questionIndex, selected, currentInput, selectedList) {
    switch (questionIndex) {
      case 1:
        setCache((prevCache) => {
          const updatedCache = { ...prevCache, userName: currentInput };
          return updatedCache;
        });
        break;

      case 2:
        setCache((prevCache) => {
          const updatedCache = { ...prevCache, userEmail: currentInput };
          return updatedCache;
        });
        break;
    }
  }

  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: 'inline-block',
        borderRadius: '50%',
        height: '6px',
        width: '6px',
        background: isActive ? 'var(--neutral-1000)' : 'var(--neutral-600)'
      }}
    ></span>
  )

  function signUp() {
    setJoinError("");
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 1,
          message: "Please enter your name.",
        },
      ]);
      prevent = true;
    }
    if (email.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "Please enter your email address.",
        },
      ]);
      prevent = true;
    }
    if (!validateEmail(email)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "Please enter a valid email address.",
        },
      ]);
      prevent = true;
    }
    if (password.length > 0 && password.length < 8) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "Please enter a password of at least 8 characters.",
        },
      ]);
      prevent = true;
    }
    if (password.length > 48) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "Your password may not exceed 48 characters.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    createAccount();
  }

  return (
    <Container className={`${styles.container} ${darkMode && styles.darkContainer}`}>
      <View className={styles.signupWrapper} centerHorizontally centerVertically>
        <View vertical className={styles.coloredBlock}>
          <View>
            <Logo inverted forceInvert={darkMode} />
          </View>
          <Spacer size="large" />
          <Spacer size="medium" />
          <H4 className={styles.boxHeading}>Automate your company blog with Drafts AI</H4>
          <Spacer size="medium" />
          <Paragraph size="large" className={styles.boxMessage}>Upload your list of target keywords and receive hundreds of high-quality articles within seconds.</Paragraph>
          <View className={styles.bottomContainer}>
            <View className={styles.carouselWrapper}>
              <Carousel containerStyle={{ width: '336px' }} scrollSnap={true} hideArrow={true} autoplay={3500} showDots={true} dot={MyDot} cols={1} rows={1} loop>
                <Carousel.Item>
                  <View vertical className={styles.reviewBlock}>
                    <Paragraph className={styles.reviewMessage}>Super efficient for generating tons of articles. I am now using it for my client’s Webflow sites and the integration is done very well.</Paragraph>
                    <Spacer size="medium" />
                    <View>
                      <View className={styles.reviewPic}>
                        <img src="https://draftsai.com/assets/images/reviews/sam.png" />
                      </View>
                      <Spacer size="small" />
                      <View className={styles.reviewTextBlock} vertical>
                        <Paragraph className={styles.reviewName}>Sam</Paragraph>
                        <Paragraph className={styles.reviewSubMessage}>Founder</Paragraph>
                      </View>
                    </View>
                  </View>
                </Carousel.Item>
                <Carousel.Item>
                  <View vertical className={styles.reviewBlock}>
                    <Paragraph className={styles.reviewMessage}>Drafts has facilitated my work in an incredible way. Easy to use, a real game changer!</Paragraph>
                    <Spacer size="medium" />
                    <View>
                      <View className={styles.reviewPic}>
                        <img src="https://draftsai.com/assets/images/reviews/attia.png" />
                      </View>
                      <Spacer size="small" />
                      <View className={styles.reviewTextBlock} vertical>
                        <Paragraph className={styles.reviewName}>Attia</Paragraph>
                        <Paragraph className={styles.reviewSubMessage}>Freelancer</Paragraph>
                      </View>
                    </View>
                  </View>
                </Carousel.Item>
                <Carousel.Item>
                  <View vertical className={styles.reviewBlock}>
                    <Paragraph className={styles.reviewMessage}>Drafts AI is a game-changer for anyone in the content creation and digital marketing sphere.</Paragraph>
                    <Spacer size="medium" />
                    <View>
                      <View className={styles.reviewPic}>
                        <img src="https://draftsai.com/assets/images/reviews/saliya.png" />
                      </View>
                      <Spacer size="small" />
                      <View className={styles.reviewTextBlock} vertical>
                        <Paragraph className={styles.reviewName}>Saliya</Paragraph>
                        <Paragraph className={styles.reviewSubMessage}>Software Developer</Paragraph>
                      </View>
                    </View>
                  </View>
                </Carousel.Item>
              </Carousel>
            </View>
          </View>
        </View>
        <Helmet>
          <link rel="canonical" href="https://draftsai.com/join" />
        </Helmet>
        <View vertical className={styles.contentBlock}>
          <View vertical centerVertically>
            <H5 className={styles.title}>Get started</H5>
            <Spacer size="medium" />
            <Paragraph size="small">Have an account already? <Link shiny to="/login">Sign in</Link></Paragraph>
            {
              joinError && joinError !== null && joinError.length > 0 ? (
                <>
                  <Spacer size="medium" />
                  <Paragraph
                    style={{ marginTop: "8px", color: (joinError === "A link has been sent to your email to verify your account." ? "var(--primary)" : "var(--red)") }}
                  >
                    {joinError}
                  </Paragraph></>
              ) : null
            }
          </View>
          <Spacer size="large" />
          <H6 className={styles.inputTitle}>Name</H6>
          <Spacer size="medium" />
          <TextInput value={name} onChange={e => {
            removeErrorWithType(1);
            setName(e.target.value);
          }} className={`${styles.input} ${getErrorWithType(1) && styles.inputError
            }`} size="small" placeholder="E.g. 'John'" />
          {getErrorWithType(1) && (
            <Paragraph
              style={{ marginTop: "8px", color: "var(--red)" }}
              size="small"
            >
              {getErrorWithType(1).message}
            </Paragraph>
          )}
          <Spacer size="large" />
          <H6 className={styles.inputTitle}>Email</H6>
          <Spacer size="medium" />
          <TextInput value={email} onChange={e => {
            removeErrorWithType(2);
            setEmail(e.target.value);
          }} className={`${styles.input} ${getErrorWithType(2) && styles.inputError
            }`} size="small" placeholder="E.g. 'john@johnsblog.com'" />
          {getErrorWithType(2) && (
            <Paragraph
              style={{ marginTop: "8px", color: "var(--red)" }}
              size="small"
            >
              {getErrorWithType(2).message}
            </Paragraph>
          )}
          <Spacer size="large" />
          <H6 className={styles.inputTitle}>Password (optional)</H6>
          <Spacer size="medium" />
          <TextInput value={password} onChange={e => {
            removeErrorWithType(3);
            setPassword(e.target.value);
          }} type="password" className={`${styles.input} ${getErrorWithType(3) && styles.inputError
            }`} size="small" />
          {getErrorWithType(3) && (
            <Paragraph
              style={{ marginTop: "8px", color: "var(--red)" }}
              size="small"
            >
              {getErrorWithType(3).message}
            </Paragraph>
          )}
          <Spacer size="large" />
          <RadioButton
            onChange={(newValue) => setAcceptPrivacyPolicy(newValue)}
            className={styles.checkboxRadioButton}
            component={
              <Paragraph className={styles.checkboxText} size="small">
                I have read and agree with our <Link to="/privacy-policy">Privacy Policy</Link>.
              </Paragraph>
            }
          />
          <Spacer size="large" />
          {
              /*
              <ReCAPTCHA
              sitekey="6Le3K2YoAAAAAE8XtrCnUqxxDWH46rcQk3-zIIRX"
            />
              */
            }
          <PrimaryButton className={styles.longButton} disabled={!acceptPrivacyPolicy} spinner onClick={signUp} size="small">Create Account</PrimaryButton>
        </View>
      </View>
    </Container>
  );
}
