import React, { useContext, useState } from "react";
import { Check, Clipboard, Copy } from "react-feather";
import { ConfigContext } from "../../../context/ConfigProvider";
import Button from "../../buttons/Button";
import styles from "./styles/CopyButton.module.css";

export default function CopyButton(props) {
  const { darkMode } = useContext(ConfigContext);

  const [checked, setChecked] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(props.copy);
    setChecked(true);
    setTimeout(() => {
      setChecked(false);
    }, 3000);
  }

  return (
    <Button
    style={props.style}
      tooltip={checked ? "Copied!" : "Copy to clipboard"}
      onClick={copyToClipboard}
      className={`${styles.squareButton} ${styles.copyChatButton} ${props.size === 'small' && styles.small} ${props.className}`}
    >
      {checked ? <Check size={props.size === 'small' ? "12" : "15"} /> : <Clipboard size={props.size === 'small' ? "12" : "15"} />}
    </Button>
  );
}
