import { Select } from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import config from "../../../config.json";
import { OverlayContext } from "../../../context/OverlayProvider";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/NewSiteModal.module.css";

export default function PublishOnWebflowModal(props) {
  const [loading, setLoading] = useState(true);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  const [webflowSites, setWebflowSites] = useState([]);

  const [currentWebflowSite, setCurrentWebflowSite] = useState(null);

  const [webflowCollections, setWebflowCollections] = useState([]);

  const [currentWebflowCollection, setCurrentWebflowCollection] =
    useState(null);

  const [webflowSchema, setWebflowSchema] = useState([]);

  const [currentWebflowSchema, setCurrentWebflowSchema] = useState(null);

  async function publish() {
    if (
      !currentWebflowSite ||
      !currentWebflowCollection ||
      !currentWebflowSchema ||
      !props.articles ||
      !props.site
    ) {
      console.log("Some required inputs are missing.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
          `/api/webflowcollections/${currentWebflowCollection}/publish?token=${props.site.webflowToken}`,
        { articles: props.articles, contentSchema: currentWebflowSchema }, // Sending the articles array as JSON
        {
          headers: {
            "Content-Type": "application/json", // Set the correct content type
            "accept-version": "1.0.0",
          },
        }
      );

      if (response && response.data) {
        setLoading(false);
        closeOverlay();
      }
    } catch (error) {
      console.error("Error publishing articles:", error);
    }
  }

  useEffect(() => {
    if (!isOverlayVisible) {
      setLoading(true);
      setWebflowSites([]);
      setCurrentWebflowSite(null);
      setWebflowCollections([]);
      setCurrentWebflowCollection(null);
      setWebflowSchema([]);
      setCurrentWebflowSchema(null);
    } else {
      if (props.site && props.site.webflowToken) {
        axios
          .get(
            `${
              config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL
            }/api/webflowsites?token=${props.site.webflowToken}`
          )
          .then((response) => {
            if (response && response.data) {
              console.log("response:", response.data);
              setWebflowSites(response.data);
              setCurrentWebflowSite(response.data[0]._id);
            }
          })
          .catch((error) => {
            console.error("Error fetching Webflow sites:", error);
          });
      }
    }
  }, [isOverlayVisible, props.site]);

  useEffect(() => {
    if (currentWebflowSite === null) {
      return;
    }
    axios
      .get(
        `${
          config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL
        }/api/webflowcollections/${currentWebflowSite}?token=${
          props.site.webflowToken
        }`
      )
      .then((response) => {
        if (response && response.data) {
          setWebflowCollections(response.data);
          setCurrentWebflowCollection(response.data[0]._id);
        }
      })
      .catch((error) => {
        console.error("Error fetching Webflow collections:", error);
      });
  }, [currentWebflowSite]);

  useEffect(() => {
    if (currentWebflowSite === null) {
      return;
    }
    axios
      .get(
        `${
          config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL
        }/api/webflowcollections/${currentWebflowCollection}/schema?token=${
          props.site.webflowToken
        }`
      )
      .then((response) => {
        if (response && response.data) {
          setWebflowSchema(response.data);
          console.log("response.data:", response.data);
          setCurrentWebflowSchema(response.data[0].slug);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error fetching Webflow collection schema:", error);
      });
  }, [currentWebflowCollection]);

  if (loading) {
    return (
      <View pad="large" centerVertically centerHorizontally>
        <View pad="large" centerVertically centerHorizontally>
          <Oval
            height={"24"}
            width={"24"}
            color="var(--neutral-400)"
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="var(--neutral-800)"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </View>
      </View>
    );
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <TextInput
          value={
            "Export " +
            (props.articles.length > 1 ? "articles" : "article") +
            " to Webflow"
          }
          className={styles.cardTitle}
        />
        <Spacer size="medium" />
        <Paragraph>
          Publish your drafts to your Webflow site in a couple of clicks.
        </Paragraph>
        <Spacer size="large" />
        {webflowSites.length > 0 && (
          <>
            <H6>
              Select a site
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="medium" />
            <Select
              onChange={(event) => setCurrentWebflowSite(event.target.value)}
              color="var(--neutral-100)"
              className={styles.select}
            >
              {webflowSites.map((site) => (
                <option value={site._id}>{site.name}</option>
              ))}
            </Select>
          </>
        )}
        {webflowCollections.length > 0 && (
          <>
            <Spacer size="large" />
            <H6>
              Select a collection
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="medium" />
            <Select
              onChange={(event) =>
                setCurrentWebflowCollection(event.target.value)
              }
              color="var(--neutral-100)"
              className={styles.select}
            >
              {webflowCollections.map((collection) => (
                <option value={collection._id}>{collection.name}</option>
              ))}
            </Select>
          </>
        )}
        {webflowSchema.length > 0 && (
          <>
            <Spacer size="large" />
            <H6>
              Select a schema
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              This is the field that the article will be exported to.
            </Paragraph>
            <Spacer size="medium" />
            <Select
              onChange={(event) => setCurrentWebflowSchema(event.target.value)}
              color="var(--neutral-100)"
              className={styles.select}
            >
              {webflowSchema.map((schema) => (
                <option value={schema.id}>{schema.name}</option>
              ))}
            </Select>
          </>
        )}
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            onClick={publish}
            spinner
            linkClassName={`${styles.fullWidthButton} ${styles.redButton}`}
            className={`${styles.fullWidthButton} ${styles.redButton}`}
            size="small"
          >
            Export
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
