import { useApolloClient } from "@apollo/client";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { SOFT_DELETE_PROSPECT } from "../../../graphql/mutations/Users";
import PrimaryButton from "../../buttons/PrimaryButton";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/ConfirmDeletion.module.css";

export default function DeleteProspect(props) {
  
  const { closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  async function deleteUser() {
    let response = await client
      .mutate({
        mutation: SOFT_DELETE_PROSPECT,
        variables: {
          email: props.prospect.email,
          archived: true,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.updateProspect) {
      closeOverlay();
      setUpdate(true);
      props.onDelete();
    }
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Delete {props.prospect.email}</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          If you want to permanently and irreversibly delete this prospect, press the button below.
        </Paragraph>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={deleteUser}
            linkClassName={`${styles.fullWidthButton} ${styles.redButton}`}
            className={`${styles.fullWidthButton} ${styles.redButton}`}
            size="small"
          >
            Confirm
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
