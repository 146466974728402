import { useApolloClient } from "@apollo/client";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { UPDATE_PROSPECT } from "../../../graphql/mutations/Users";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/ConfirmDeletion.module.css";
import TextArea from "../../input/TextArea";
import { StorageContext } from "../../../context/StorageProvider";
import { EmailContext } from "../../../context/EmailProvider";

export default function SendUserMail(props) {

    const { closeOverlay } = useContext(OverlayContext);

    const client = useApolloClient();

    const [errors, setErrors] = useState([]);

    const { setUpdate } = useContext(ConfigContext);

    const [email, setEmail] = useState("");

    const [subject, setSubject] = useState("");

    const { userDetails } = useContext(StorageContext);

    const { sendEmail } = useContext(EmailContext);

    function getErrorWithType(typeToCheck) {
        return errors.find((error) => error.type === typeToCheck);
    }

    function removeErrorWithType(typeToRemove) {
        const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
        setErrors(updatedErrors);
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    async function send() {
        let prevent = false;
        if (subject.length === 0) {
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    type: 1,
                    message: "You need to enter a subject.",
                },
            ]);
            prevent = true;
        }
        if (email.length === 0) {
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    type: 2,
                    message: "You need to enter an email.",
                },
            ]);
            prevent = true;
        }
        if (prevent) {
            setUpdate(true);
            return;
        }
        const emailVerifiedUsers = props.users.filter(user => {
            return user.hash === null;
        });
        emailVerifiedUsers.map(async (user) => {
            if (user.optedOut === true) {
                return;
            }
            await sendEmail({
                from: `${userDetails.name
                    .toLowerCase()
                    .replaceAll(" ", "")}@draftsai.com`,
                fromName: `${userDetails.name} with Drafts AI`,
                to: user.email,
                subject: subject,
                template: "custom",
                templateParams: {
                    html: email,
                    name: user.name,
                    email: user.email,
                    sender: capitalizeFirstLetter(userDetails.name),
                },
            });
        });
        setSubject("");
        setEmail("");
        setUpdate(true);
        closeOverlay();
    }

    return (
        <View vertical>
            <View className={styles.paddedContainer} vertical>
                <H6>Send User Email</H6>
                <Spacer size="large" />
                <H6>
                    Subject<span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
                </H6>
                <Spacer size="small" />
                <Paragraph size="small">Please enter the subject of the email.</Paragraph>
                <Spacer size="medium" />
                <TextInput
                    size="small"
                    value={subject}
                    onChange={(e) => {
                        removeErrorWithType(1);
                        setSubject(e.target.value);
                    }}
                    className={`${styles.fullWidth} ${getErrorWithType(1) && styles.inputError
                        }`}
                    placeholder="E.g. 'Our newest update'"
                />
                {getErrorWithType(1) && (
                    <Paragraph
                        style={{ marginTop: "8px", color: "var(--red)" }}
                        size="small"
                    >
                        {getErrorWithType(1).message}
                    </Paragraph>
                )}
                <Spacer size="large" />
                <H6>
                    Email<span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
                </H6>
                <Spacer size="small" />
                <Paragraph size="small">Please write the email you would like to send to all email-verified users.</Paragraph>
                <Spacer size="medium" />
                <TextArea
                    size="small"
                    value={email}
                    onChange={(e) => {
                        removeErrorWithType(2);
                        setEmail(e.target.value);
                    }}
                    className={`${styles.fullWidth} ${getErrorWithType(2) && styles.inputError
                        }`}
                    placeholder=""
                />
                {getErrorWithType(2) && (
                    <Paragraph
                        style={{ marginTop: "8px", color: "var(--red)" }}
                        size="small"
                    >
                        {getErrorWithType(2).message}
                    </Paragraph>
                )}
            </View>
            <Separator className={styles.separator} />
            <View className={styles.buttonContainer}>
                <View className={styles.buttonWrapper}>
                    <PrimaryButton
                        spinner
                        onClick={send}
                        linkClassName={`${styles.fullWidthButton}`}
                        className={`${styles.fullWidthButton}`}
                        size="small"
                    >
                        Send
                    </PrimaryButton>
                </View>
            </View>
        </View>
    );
}
