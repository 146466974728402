import React from "react";
import View from "../../view/View";
import styles from "./styles/CTA.module.css";
import H5 from "../../text/heading/H5";
import Spacer from "../../view/impl/Spacer";
import Paragraph from "../../text/paragraph/Paragraph";
import SecondaryButton from "../../buttons/SecondaryButton";
import PrimaryButton from "../../buttons/PrimaryButton";

export default function CTA(props) {
  return (
    <View
      vertical
      centerHorizontally
      centerVertically
      className={styles.container}
    >
      <View className={styles.containerBackground} />
      <H5 className={styles.heading}>Try Drafts AI for free today</H5>
      <Spacer size="medium" />
      <Paragraph center size="big" className={styles.paragraph}>
        Create and publish hundreds of SEO-optimized blog articles in seconds
      </Paragraph>
      <Spacer size="small" />
      <Spacer size="medium" />
      <PrimaryButton to="/app/billing" size="small">
        Get started –<span className={styles.boldSpan}>it's free</span>
      </PrimaryButton>
    </View>
  );
}