import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Paragraph from "../text/paragraph/Paragraph";
import Link from "../links/Link";
import H6 from "../text/heading/H6";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";

import styles from "./styles/Footer.module.css";
import { ConfigContext } from "../../context/ConfigProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import { Twitter } from "react-feather";
import Button from "../buttons/Button";
import { Linkedin } from "react-feather";
import Modal from "../modals/Modal";
import CookiePreferencesModal from "../modals/impl/CookiePreferencesModal";
import { OverlayContext } from "../../context/OverlayProvider";
import H5 from "../text/heading/H5";
import Logo from "./Logo";

export default function Footer() {

  const location = useLocation();

  const { darkMode } = useContext(ConfigContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  const openCookiePreferencesModal = () => {
    openOverlay(
      <Modal title="Cookie Preferences">
        <CookiePreferencesModal />
      </Modal>
    );
  };

  if (
    location.pathname === "/" ||
    location.pathname.includes("/brand") ||
    location.pathname.includes("/privacy-policy") ||
    location.pathname.includes("/cookie-policy") ||
    location.pathname.includes("/terms-of-service") ||
    location.pathname.includes("/auth")
  ) {
    return (
      <footer className={styles.container}>
        <View gap="large" className={styles.wrapper}>
          <View className={styles.someWrapper}>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Account</H6>
                  <Link className={styles.link} size="small" to="/join">
                    Get started
                  </Link>
                  <Link className={styles.link} size="small" to="/login">
                    Sign in
                  </Link>
                </View>
              </View>
            </View>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Legal</H6>
                  <Link className={styles.link} size="small" to="/privacy-policy">
                    Privacy policy
                  </Link>
                  <Link className={styles.link} size="small" to="/cookie-policy">
                    Cookie policy
                  </Link>
                  <Button
                    className={styles.noPadButton}
                    size="small"
                    onClick={openCookiePreferencesModal}
                  >
                    Cookie preferences
                  </Button>
                  <Link className={styles.link} size="small" to="/terms-of-service">
                    Terms of Service
                  </Link>
                </View>
              </View>
            </View>
          </View>
          <View className={styles.someWrapper}>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Company</H6>
                  <Button
                    className={styles.noPadButton}
                    size="small"
                    onClick={() => {
                      window.location.href =
                        "/blog";
                    }}
                  >
                    Blog
                  </Button>
                </View>
              </View>
            </View>
            <View centerHorizontally className={styles.thirdy}>
              <View>
              <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Support</H6>
                  <Button
                    className={styles.noPadButton}
                    size="small"
                    onClick={() => {
                      window.location.href =
                        "mailto:contact@draftsai.com?subject=Regarding Drafts";
                    }}
                  >
                    Get in touch
                  </Button>
                  <Button
                    className={styles.noPadButton}
                    size="small"
                    to="https://calendly.com/julius-egq/introductory-call" target="_blank"
                  >
                    Book demo
                  </Button>
                </View>
              </View>
            </View>
          </View>
          <div className={styles.rightContainer}></div>
        </View>
        <View
          gap="large"
          className={`${styles.wrapper} ${styles.borderedWrapper}`}
        >
          <View centerVertically centerHorizontally>
            <Paragraph center size="small">
              © 2023 Drafts AI – All rights reserved.
            </Paragraph>
          </View>
        </View>
      </footer>
    );
  }
}
