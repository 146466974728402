import React, { createContext, useState } from "react";
import Alert from "../components/alert/Alert";

// Create the context
export const AlertContext = createContext();

// Create the provider component
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  // Function to show an alert
  const showAlert = (message, type = "info") => {
    setAlert({ message, type });
  };

  // Function to hide the alert
  const hideAlert = () => {
    //setAlert(null);
  };

  // Context value to be provided
  const contextValue = {
    alert,
    showAlert,
    hideAlert,
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {alert && <Alert type={alert.type} message={alert.message} />}
    </AlertContext.Provider>
  );
};
