import { useApolloClient } from "@apollo/client";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { SectionContext } from "../../../context/SectionProvider";
import { StorageContext } from "../../../context/StorageProvider";
import PrimaryButton from "../../buttons/PrimaryButton";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/ConfirmDeletion.module.css";
import Spacer from "../../view/impl/Spacer";
import { SOFT_DELETE } from "../../../graphql/mutations/Users";

export default function ConfirmDeletion(props) {
  
  const { userDetails, setUserDetails } = useContext(StorageContext);

  const { sectionData } = useContext(SectionContext);

  const { closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  async function deleteSelection() {
    setTimeout(() => {
      closeOverlay();
      setUpdate(true);
      props.onUpdate();
    }, 500);
  }

  async function deleteUser() {
    let response = await client
      .mutate({
        mutation: SOFT_DELETE,
        variables: {
          email: userDetails.email,
          archived: true,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.updateUser) {
      closeOverlay();
      setUpdate(true);
      setUserDetails(null);
      navigate("/");
    }
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Delete your account</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          If you want to permanently and irreversibly delete your account, click the button below.
        </Paragraph>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={deleteUser}
            linkClassName={`${styles.fullWidthButton} ${styles.redButton}`}
            className={`${styles.fullWidthButton} ${styles.redButton}`}
            size="small"
          >
            Confirm
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
