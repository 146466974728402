import { gql } from "@apollo/client";

export const ADD_SITE = gql`
  mutation addSite(
    $siteId: Int!
    $owner: String!
    $name: String!
    $uri: String!
    $integrationType: Int!
    $wordpressUrl: String!
    $wordpressUser: String!
    $wordpressToken: String!
    $webflowToken: String!
    $shopifyUrl: String!
    $shopifyToken: String!
    $ghostUrl: String!
    $ghostToken: String!
    $webhookUrl: String!
    $webhookToken: String!
  ) {
    addSite(
      input: {
        siteId: $siteId
        owner: $owner
        name: $name
        uri: $uri
        integrationType: $integrationType
        wordpressUrl: $wordpressUrl
        wordpressUser: $wordpressUser
        wordpressToken: $wordpressToken
        webflowToken: $webflowToken
        shopifyUrl: $shopifyUrl
        shopifyToken: $shopifyToken
        ghostUrl: $ghostUrl
        ghostToken: $ghostToken
        webhookUrl: $webhookUrl
        webhookToken: $webhookToken
      }
    ) {
      siteId
      owner
      name
      uri
      integrationType
      wordpressUrl
      wordpressUser
      wordpressToken
      webflowToken
      shopifyUrl
      shopifyToken
      ghostUrl
      ghostToken
      webhookUrl
      webhookToken
    }
  }
`;

export const ARCHIVE_SITE = gql`
  mutation archiveSite($siteId: Int!) {
    archiveSite(input: { siteId: $siteId }) {
      siteId
      owner
      name
      uri
      integrationType
      wordpressUrl
      wordpressUser
      wordpressToken
      webflowToken
      shopifyUrl
      shopifyToken
      ghostUrl
      ghostToken
      webhookUrl
      webhookToken
      archived
    }
  }
`;

export const UPDATE_SITE = gql`
  mutation updateSite(
    $siteId: Int!
    $name: String!
    $uri: String!
    $integrationType: Int!
    $wordpressUrl: String!
    $wordpressUser: String!
    $wordpressToken: String!
    $webflowToken: String!
    $shopifyUrl: String!
    $shopifyToken: String!
    $ghostUrl: String!
    $ghostToken: String!
    $webhookUrl: String!
    $webhookToken: String!
  ) {
    updateSite(
      input: {
        siteId: $siteId
        name: $name
        uri: $uri
        integrationType: $integrationType
        wordpressUrl: $wordpressUrl
        wordpressUser: $wordpressUser
        wordpressToken: $wordpressToken
        webflowToken: $webflowToken
        shopifyUrl: $shopifyUrl
        shopifyToken: $shopifyToken
        ghostUrl: $ghostUrl
        ghostToken: $ghostToken
        webhookUrl: $webhookUrl
        webhookToken: $webhookToken
      }
    ) {
      siteId
      owner
      name
      uri
      integrationType
      wordpressUrl
      wordpressUser
      wordpressToken
      webflowToken
      shopifyUrl
      shopifyToken
      ghostUrl
      ghostToken
      webhookUrl
      webhookToken
      archived
    }
  }
`;