import { useApolloClient } from "@apollo/client";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { UPDATE_PROSPECT } from "../../../graphql/mutations/Users";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/ConfirmDeletion.module.css";

export default function EditProspect(props) {
  
  const { closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [errors, setErrors] = useState([]);

  const { setUpdate } = useContext(ConfigContext);

  const [name, setName] = useState(props.prospect.name);

  function getErrorWithType(typeToCheck) {
    return errors.find((error) => error.type === typeToCheck);
  }

  function removeErrorWithType(typeToRemove) {
    const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
    setErrors(updatedErrors);
  }

  async function saveUser() {
    let response = await client
      .mutate({
        mutation: UPDATE_PROSPECT,
        variables: {
          email: props.prospect.email,
          name,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
            ...prevErrors,
            {
              type: 2,
              message: "You need to enter a name.",
            },
          ]);
      });
    if (response && response.data && response.data.updateProspect) {
      closeOverlay();
      setUpdate(true);
      props.onEdit();
    }
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Edit {props.prospect.email}</H6>
        <Spacer size="large" />
        <H6>
          Name<span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">What is the name of this prospect?</Paragraph>
        <Spacer size="medium" />
        <TextInput
          size="small"
          value={name}
          onChange={(e) => {
            removeErrorWithType(2);
            setName(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            getErrorWithType(2) && styles.inputError
          }`}
          placeholder="E.g. 'John'"
        />
        {getErrorWithType(2) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(2).message}
          </Paragraph>
        )}
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={saveUser}
            linkClassName={`${styles.fullWidthButton}`}
            className={`${styles.fullWidthButton}`}
            size="small"
          >
            Save
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
