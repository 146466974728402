import React, { useState } from "react";
import styles from "./styles/Stats.module.css";
import View from "../view/View";
import Paragraph from "../text/paragraph/Paragraph";
import Spacer from "../view/impl/Spacer";
import Separator from "../view/impl/Separator";
import TagGrid from "../tags/TagGrid";
import H5 from "../text/heading/H5";
import { HelpCircle } from "lucide-react";
import { Tooltip } from "@chakra-ui/react";

export default function Stats(props) {
  return (
    <>
      <View vertical className={styles.infoCell}>
        <View centerVertically>
          <Paragraph className={styles.infoTitle}>Sites</Paragraph>
          <Tooltip className={styles.tooltip} label="You can add as many sites as you want.">
            <HelpCircle className={styles.helpCircle} size={15} />
          </Tooltip>
        </View>
        <H5 className={styles.heading}>
          {props.stats.siteCount.toLocaleString()}
        </H5>
        <TagGrid tags={["Just now"]} />
      </View>
      <Separator vertical className={styles.verticalSeparator} />
      <View vertical className={styles.infoCell}>
        <View centerVertically>
          <Paragraph className={styles.infoTitle}>Reports</Paragraph>
          <Tooltip className={styles.tooltip} label="Each report is a collection of articles.">
            <HelpCircle className={styles.helpCircle} size={15} />
          </Tooltip>
        </View>
        <H5 className={styles.heading}>
          {props.stats.totalReportsCount.toLocaleString()}
        </H5>
        <TagGrid tags={["Just now"]} />
      </View>
      <Separator vertical className={styles.verticalSeparator} />
      <View vertical className={styles.infoCell}>
        <View centerVertically>
          <Paragraph className={styles.infoTitle}>Articles</Paragraph>
          <Tooltip className={styles.tooltip} label="Create as many articles as you want, regardless of how many you chose when creating the report">
            <HelpCircle className={styles.helpCircle} size={15} />
          </Tooltip>
        </View>
        <H5 className={styles.heading}>
          {props.stats.totalArticlesCount.toLocaleString()}
        </H5>
        <TagGrid tags={["Just now"]} />
      </View>
    </>
  );
}
