import { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import View from "../../view/View";
import Spacer from "../../view/impl/Spacer";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import SecondaryButton from "../../buttons/SecondaryButton";
import H5 from "../../text/heading/H5";
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Switch,
} from "@chakra-ui/react";
import H4 from "../../text/heading/H4";
import PrimaryButton from "../../buttons/PrimaryButton";
import Separator from "../../view/impl/Separator";
import styles from "./styles/Tutorials.module.css";
import Link from "../../links/Link";
import Button from "../../buttons/Button";
import { Clock } from "lucide-react";

export default function Tutorials(props) {

  const { darkMode } = useContext(ConfigContext);

  const [sliderValue, setSliderValue] = useState(0);

  return (
    <View
      className={styles.contentContainer2}
      vertical
      style={{
        width: props.landing
          ? "100%"
          : !props.showMenu
            ? `calc(100% - 75px)`
            : `calc(100% - 285px)`,
      }}
    >
      <View
        vertical
        centerVertically
        className={`${props.landing ? styles.landingView : styles.innerView}`}
      >
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="medium" />
        <View centerHorizontally centerVertically gap="small">
          <H5>Video tutorials powered by</H5>
          <img style={{ height: '29px' }} src={"../assets/images/loom" + (!darkMode ? "_black" : "") + ".png"} />
        </View>
        <Spacer size="medium" />
        <Paragraph center>
          Need help with something different? <Link shiny onClick={() => {
            window.location.href =
              "mailto:contact@draftsai.com?subject=Regarding draftsai.com";
          }}>Reach out.</Link>
        </Paragraph>
        <Spacer size="large" />
        <Spacer size="medium" />
        <View gap="large" centerVertically centerHorizontally>
          <Button target="_blank" gap="small" className={styles.videoCell}>
            <View className={styles.videoThumbnail}>
              <img className={styles.thumbnail} src="https://draftsai.com/assets/images/seo-white.png" />
            </View>
            <Spacer size="small" />
            <H6>Coming soon</H6>
            <View gap="small" centerVertically>
              <Clock color="var(--neutral-400)" size={15} />
              <Paragraph className={styles.est} size="small">0 mins</Paragraph>
            </View>
          </Button>
        </View>
      </View>
      <Spacer size="large" />
    </View>
  );
}
