import { gql } from "@apollo/client";

export const ADD_REPORT = gql`
  mutation AddReport(
    $reportId: Int!
    $siteId: Int!
    $name: String!
    $status: String!
    $language: String!
    $reportType: Int!
    $keywords: String!
    $instruction: String!
    $archived: Boolean!
  ) {
    addReport(
      input: {
        reportId: $reportId
        siteId: $siteId
        name: $name
        status: $status
        language: $language
        reportType: $reportType
        keywords: $keywords
        instruction: $instruction
        archived: $archived
      }
    ) {
      reportId
      siteId
      name
      status
      language
      reportType
      keywords
      instruction
      archived
    }
  }
`;

export const ARCHIVE_REPORT = gql`
  mutation ArchiveReport($reportId: Int!) {
    archiveReport(input: { reportId: $reportId }) {
      reportId
      siteId
      name
      status
      language
      reportType
      keywords
      instruction
      archived
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($reportId: Int!, $name: String!, $reportType: Int!, $keywords: String!, $language: String!) {
    updateReport(input: { reportId: $reportId, name: $name, reportType: $reportType, keywords: $keywords, language: $language }) {
      reportId
      siteId
      name
      status
      language
      reportType
      keywords
      instruction
      archived
    }
  }
`;
