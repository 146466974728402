import React, { createContext, useEffect, useState } from "react";

export const StorageContext = createContext();

export function StorageProvider({ children }) {
  
  const [userDetails, setUserDetails] = useState(null);

  const [acceptedCookies, setAcceptedCookies] = useState(null);

  const [hideCookieBanner, setHideCookieBanner] = useState(false);

  useEffect(() => {
    // Fetch user details from localStorage on component mount
    const storedUserDetails = localStorage.getItem("userDetails");
    const acceptedCookies = localStorage.getItem("acceptedCookies");
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
    if (acceptedCookies) {
      setAcceptedCookies(JSON.parse(acceptedCookies));
    }
  }, []);

  useEffect(() => {
    // Update localStorage when userDetails changes
    if (userDetails) {
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    } else {
      localStorage.removeItem("userDetails");
    }
  }, [userDetails]);

  useEffect(() => {
    // Update localStorage when acceptedCookies changes
    if (acceptedCookies) {
      localStorage.setItem("acceptedCookies", JSON.stringify(acceptedCookies));
    }
  }, [acceptedCookies]);

  return (
    <StorageContext.Provider value={{ hideCookieBanner, setHideCookieBanner, acceptedCookies, setAcceptedCookies, userDetails, setUserDetails }}>
      {children}
    </StorageContext.Provider>
  );
}
