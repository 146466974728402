import React, { useContext, useEffect, useState } from "react";
import Container from "../navigation/Container";
import Nav from "../navigation/Nav";
import H4 from "../text/heading/H4";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Blog.module.css";
import { GET_BLOG_ARTICLE_BY_SLUG } from "../../graphql/queries/BlogArticles";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { StorageContext } from "../../context/StorageProvider";
import { Archive, ArrowLeft } from "lucide-react";
import SecondaryButton from "../buttons/SecondaryButton";
import { ARCHIVE_BLOG_ARTICLE } from "../../graphql/mutations/BlogArticles";
import Link from "../links/Link";
import { Helmet } from "react-helmet";
import BlogPopupModal from "../modals/impl/BlogPopupModal";
import Modal from "../modals/Modal";
import { OverlayContext } from "../../context/OverlayProvider";
import CTA from "./drafts/CTA";

export default function BlogArticle(props) {
  const client = useApolloClient();

  const [data, setData] = useState(null);

  const { slug } = useParams();

  const { userDetails } = useContext(StorageContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  function openPopup() {
    openOverlay(
      <Modal className={styles.noBorder}>
        <BlogPopupModal />
      </Modal>
    );
  }

  async function fetchBlogArticle() {
    if (!slug) {
      setData({
        data: null,
      });
    }
    const response = await client
      .query({
        query: GET_BLOG_ARTICLE_BY_SLUG,
        variables: {
          slug,
        },
      })
      .catch((error) => {
        console.error("Error fetching blog article", error);
        setData({
          data: null,
        });
      });
    if (response && response.data) {
      setData(response.data.getBlogArticleBySlug);
      openPopup();
    } else {
      setData({
        data: null,
      });
    }
  }

  async function archiveBlogArticle() {
    const response = await client
      .mutate({
        mutation: ARCHIVE_BLOG_ARTICLE,
        variables: {
          articleId: data.articleId,
        },
      })
      .catch((error) => {
        console.error("Error archiving blog article", error);
      });
    if (response && response.data) {
      window.location.href = "/blog";
    }
  }

  useEffect(() => {
    if (data === null) {
      fetchBlogArticle();
    }
  }, []);

  const formatDate = (unixTimestampMillis) => {
    const d = new Date(unixTimestampMillis);

    const day = d.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();

    // Function to add the "st," "nd," "rd," or "th" suffix to the day
    const getDayWithSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    return `${getDayWithSuffix(day)} ${month}, ${year}`;
  };

  if (data === null) {
    return (
      <>
        <Nav render />
        <View
          pad="large"
          className={styles.loadingArticleContainer}
          centerVertically
          centerHorizontally
        >
          <View pad="large" centerVertically centerHorizontally>
            <Oval
              height={"16"}
              width={"16"}
              color="var(--neutral-400)"
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--neutral-800)"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </View>
        </View>
      </>
    );
  }

  const content = data.image
    ? `<img src=${data.image} />` + `${data.content}`
    : data.content;

  function extractFirstImageSrc(htmlString) {
    // Regular expression to match the first <img> element's src attribute
    const imgSrcRegex = /<img[^>]+src=["']([^"']+)["']/i;

    // Find the first match
    const match = htmlString.match(imgSrcRegex);

    if (match && match[1]) {
      return match[1]; // Return the src attribute value
    } else {
      return null; // Return null if no match is found
    }
  }

  return (
    <Container className={styles.container}>
      <Nav render />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          property="og:image"
          content={"" + extractFirstImageSrc(data.content)}
        />
        <meta
          name="description"
          content="Write hundreds of SEO optimized articles in seconds"
        />
        <link rel="apple-touch-icon" href="https://draftsai.com/logo192.png" />

        <link rel="manifest" href="https://draftsai.com/manifest.json" />

        <title>{data.name}</title>

        <meta name="description" content={data.summary} />
        <meta
          name="keywords"
          content="Drafts AI, draftsai.com, AI article writer, AI bulk articles, AI bulk article writer, AI copywriting, tryluna.ai, drafthorse"
        />
        <meta name="author" content="Drafts AI" />

        <meta property="og:title" content={data.name} />
        <meta property="og:description" content={data.summary} />
        <meta
          property="og:image"
          content={"" + extractFirstImageSrc(data.content)}
        />
        <meta property="og:url" content="https://draftsai.com/blog" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Drafts AI" />
        <meta name="twitter:description" content={data.summary} />
        <meta
          name="twitter:image"
          content={"" + extractFirstImageSrc(data.content)}
        />
        <meta name="twitter:creator" content="@draftsai.com" />

        <link
          rel="icon"
          href="https://draftsai.com/assets/images/icon.png"
          type="image/x-icon"
        />

        <link rel="canonical" href="https://draftsai.com/blog" />

        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="referrer" content="no-referrer-when-downgrade" />
      </Helmet>
      <View className={styles.blogArticleContainer}>
        <View>
          <View className={styles.leftContainer}>
            <Link
              onClick={() => {
                window.location.href = "/blog";
              }}
              size="small"
              className={styles.smallIconButton}
            >
              <ArrowLeft size="16px" /> Return to blog
            </Link>
          </View>
          <View className={styles.rightContainer}>
            {userDetails && userDetails.type === 3 && (
              <Link
                onClick={archiveBlogArticle}
                size="small"
                className={styles.smallIconButton}
              >
                <Archive size="16px" /> Archive blog article
              </Link>
            )}
          </View>
        </View>
        <Spacer size="medium" className={styles.hideInMobile} />
        <Spacer size="large" />
        <H4>{data.name}</H4>
        <Spacer size="large" className={styles.hideInMobile} />
        <Spacer size="large" />
        <View
          centerVertically
          centerHorizontally
          gap="medium"
          className={styles.writerContainer}
        >
          <img
            className={styles.draftsIcon}
            src="https://draftsai.com/assets/images/icon.png"
          />
          <View vertical>
            <H6 className={styles.articleHeading}>Drafts AI Team</H6>
            <Paragraph size="small">
              Published on {formatDate(parseInt(data.date))}
            </Paragraph>
          </View>
        </View>
        <Spacer size="large" />
        <div
          className={styles.markup}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Spacer size="large" />
        <Spacer size="large" />
        <CTA />
      </View>
    </Container>
  );
}
