import React, { createContext, useState } from "react";

export const SiteContext = createContext();

export function SiteProdiver({ children }) {

  const [sites, setSites] = useState(null);

  const [reports, setReports] = useState(null);

  const [articles, setArticles] = useState(null);

  return (
    <SiteContext.Provider value={{ sites, setSites, reports, setReports, articles, setArticles }}>
      {children}
    </SiteContext.Provider>
  );
}
