import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { ADD_SITE, UPDATE_SITE } from "../../../graphql/mutations/Sites";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import RadioCellContainer from "../../radiocell/RadioCellContainer";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/NewSiteModal.module.css";

export default function NewSiteModal(props) {
  const { setUpdate } = useContext(ConfigContext);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const { userDetails } = useContext(StorageContext);

  const [name, setName] = useState("");

  const [uri, setUri] = useState("");

  const [site, setSite] = useState(null);

  const [errors, setErrors] = useState([]);

  const [integrationType, setIntegrationType] = useState(null);

  const [webflowToken, setWebflowToken] = useState("");

  const [wordpressUrl, setWordpressUrl] = useState("");

  const [wordpressUser, setWordpressUser] = useState("");

  const [wordpressToken, setWordpressToken] = useState("");

  const [shopifyUrl, setShopifyUrl] = useState("");

  const [shopifyToken, setShopifyToken] = useState("");

  const [ghostUrl, setGhostUrl] = useState("");

  const [ghostToken, setGhostToken] = useState("");

  const [webhookUrl, setWebhookUrl] = useState("");

  const [webhookToken, setWebhookToken] = useState("");

  useEffect(() => {
    if (!isOverlayVisible) {
      setSite(null);
      setName("");
      setIntegrationType(null);
      setWebflowToken("");
      setWordpressUrl("");
      setWordpressUser("");
      setWordpressToken("");
      setShopifyUrl("");
      setShopifyToken("");
      setGhostUrl("");
      setGhostToken("");
      setWebhookUrl("");
      setWebhookToken("");
      setUri("");
      setErrors([]);
    } else {
      if (props.site) {
        setSite(props.site);
        setName(props.site.name);
        setUri(props.site.uri);
        setIntegrationType(props.site.integrationType);
        setWordpressUrl(props.site.wordpressUrl);
        setWordpressUser(props.site.wordpressUser);
        setWordpressToken(props.site.wordpressToken);
        setWebflowToken(props.site.webflowToken);
        setShopifyUrl(props.site.shopifyUrl);
        setShopifyToken(props.site.shopifyToken);
        setGhostUrl(props.site.ghostUrl);
        setGhostToken(props.site.ghostToken);
        setWebhookUrl(props.site.webhookUrl);
        setWebhookToken(props.site.webhookToken);
      }
    }
  }, [isOverlayVisible, props.site]); // Include props.site in the dependency array

  const [integrationTypeData, setIntegrationTypeData] = useState({
    questions: [
      {
        id: 1,
        questionType: 1,
        questionTitle: null,
        questionSubtitle: null,
        question: null,
        unique: true,
        preventUnselect: false,
        hideButton: true,
        options: [
          {
            id: 1,
            title: "Webflow (V1)",
            subtitle: "This is a Webflow site",
            selected: true,
            image:
              "https://cdn.icon-icons.com/icons2/2699/PNG/512/webflow_logo_icon_169218.png",
          },
          {
            id: 2,
            title: "Wordpress",
            subtitle: "This is a Wordpress site",
            selected: true,
            image:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png",
          },
          {
            id: 3,
            title: "Shopify",
            subtitle: "This is a Shopify site",
            selected: true,
            image:
              "https://cdn-icons-png.flaticon.com/512/2504/2504814.png",
          },
          {
            id: 4,
            title: "Ghost",
            subtitle: "This is a Ghost site",
            selected: true,
            image:
              "https://ghost.org/images/logos/ghost-logo-orb.png",
          },
          {
            id: 5,
            title: "Webhook",
            subtitle: "This is a custom site",
            selected: true,
            icon: "webhook"
          }
        ],
      },
    ],
  });

  function getErrorWithType(typeToCheck) {
    return errors.find((error) => error.type === typeToCheck);
  }

  function removeErrorWithType(typeToRemove) {
    const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
    setErrors(updatedErrors);
  }

  function handleSiteChange() {
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "You need to enter a name.",
        },
      ]);
      prevent = true;
    }
    if (uri.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "You need to enter an URL.",
        },
      ]);
      prevent = true;
    }
    if (!isURL(uri)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "You need to enter a valid URL.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 1 && webflowToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 5,
          message: "You need to enter your API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 2 && wordpressToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 5,
          message: "You need to enter your API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 2 && wordpressUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 6,
          message: "You need to enter a URL.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 2 && wordpressUser.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 7,
          message: "You need to enter your user.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 3 && shopifyUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 8,
          message: "You need to enter your Shopify Store ID.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 3 && shopifyToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 9,
          message: "You need to enter your Shopify API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 4 && ghostUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 10,
          message: "You need to enter your Ghost Site ID.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 4 && ghostToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 11,
          message: "You need to enter your Ghost API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 5 && webhookToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 13,
          message: "You need to enter your webhook token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 5 && webhookUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 12,
          message: "You need to enter your webhook URL.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    setTimeout(() => {
      addSite();
    }, 500);
  }

  function editSite() {
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "You need to enter a name.",
        },
      ]);
      prevent = true;
    }
    if (uri.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "You need to enter an URL.",
        },
      ]);
      prevent = true;
    }
    if (!isURL(uri)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "You need to enter a valid URL.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 1 && webflowToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 5,
          message: "You need to enter your API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 2 && wordpressToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 5,
          message: "You need to enter your API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 2 && wordpressUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 6,
          message: "You need to enter a URL.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 2 && wordpressUser.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 7,
          message: "You need to enter your user.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 3 && shopifyUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 8,
          message: "You need to enter your Shopify Store ID.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 3 && shopifyToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 9,
          message: "You need to enter your Shopify API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 4 && ghostUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 10,
          message: "You need to enter your Ghost URL.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 4 && ghostToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 11,
          message: "You need to enter your Ghost API token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 5 && webhookToken.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 13,
          message: "You need to enter your webhook token.",
        },
      ]);
      prevent = true;
    }
    if (integrationType === 5 && webhookUrl.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 12,
          message: "You need to enter your webhook URL.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    setTimeout(() => {
      updateSite();
    }, 500);
  }

  async function addSite() {
    const site = {
      siteId: -1,
      owner: userDetails.email,
      name,
      uri,
      integrationType: integrationType || 0,
      wordpressUrl: wordpressUrl || "",
      wordpressUser: wordpressUser || "",
      wordpressToken: wordpressToken || "",
      webflowToken: webflowToken || "",
      shopifyUrl: shopifyUrl || "",
      shopifyToken: shopifyToken || "",
      ghostUrl: ghostUrl || "",
      ghostToken: ghostToken || "",
      webhookUrl: webhookUrl || "",
      webhookToken: webhookToken || "",
    };
    const response = await client
      .query({
        query: ADD_SITE,
        variables: site,
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    setUpdate(true);
    if (response && response.data && response.data.addSite) {
      closeOverlay();
      setName("");
      setIntegrationType(null);
      setWordpressUrl("");
      setWordpressUser("");
      setWordpressToken("");
      setWebflowToken("");
      setShopifyUrl("");
      setShopifyToken("");
      setGhostUrl("");
      setGhostToken("");
      setWebhookUrl("");
      setWebhookToken("");
      setUri("");
      setErrors([]);
      props.addSite(response.data.addSite);
    }
  }

  function isURL(str) {
    const urlPattern = /^(?:https?:\/\/)?(?:www\.)?[^\s.]+\.\S{2,}$/i;
    return urlPattern.test(str);
  }

  async function updateSite() {
    const response = await client
      .query({
        query: UPDATE_SITE,
        variables: {
          siteId: site.siteId,
          name,
          uri,
          integrationType: integrationType || 0,
          wordpressUser: wordpressUser || "",
          wordpressUrl: wordpressUrl || "",
          wordpressToken: wordpressToken || "",
          webflowToken: webflowToken || "",
          shopifyUrl: shopifyUrl || "",
          shopifyToken: shopifyToken || "",
          ghostUrl: ghostUrl || "",
          ghostToken: ghostToken || "",
          webhookUrl: webhookUrl || "",
          webhookToken: webhookToken || "",
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    setUpdate(true);
    if (response && response.data && response.data.updateSite) {
      console.log("response:", response.data.updateSite);
      closeOverlay();
      setName("");
      setIntegrationType(null);
      setWordpressUrl("");
      setWordpressUser("");
      setWordpressToken("");
      setWebflowToken("");
      setShopifyUrl("");
      setShopifyToken("");
      setGhostUrl("");
      setGhostToken("");
      setWebhookUrl("");
      setWebhookToken("");
      setUri("");
      setErrors([]);
      props.updateSite(response.data.updateSite);
    }
  }

  function onSelectCallback(type) {
    setIntegrationType(type);
  }

  return (
    <View vertical className={styles.main}>
      <View className={styles.paddedContainer} vertical>
        <TextInput
          value={site === null ? "Add new site" : "Edit " + site.name}
          className={styles.cardTitle}
        />
        <Spacer size="medium" />
        <Paragraph>
          Write SERP optimized articles for keywords or top list product review
          articles for specified links.
        </Paragraph>
        <Spacer size="large" />
        <H6>
          Name<span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">What is the name of this site?</Paragraph>
        <Spacer size="medium" />
        <TextInput
          size="small"
          value={name}
          onChange={(e) => {
            removeErrorWithType(2);
            setName(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            getErrorWithType(2) && styles.inputError
          }`}
          placeholder="E.g. 'John's Blog'"
        />
        {getErrorWithType(2) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(2).message}
          </Paragraph>
        )}
        <Spacer size="large" />
        <H6>
          URL<span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          Enter the URL of the site you would like to add.
        </Paragraph>
        <Spacer size="medium" />
        <TextInput
          size="small"
          value={uri}
          onChange={(e) => {
            removeErrorWithType(3);
            setUri(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            getErrorWithType(3) && styles.inputError
          }`}
          placeholder="E.g. 'https://www.johnsblog.com'"
        />
        {getErrorWithType(3) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(3).message}
          </Paragraph>
        )}
        <Spacer size="large" />
        <H6>Integration (optional)</H6>
        <Spacer size="small" />
        <Paragraph size="small">
          Publish your drafts directly to your blog provider.
        </Paragraph>
        <Spacer size="medium" />
        {(integrationType !== null && integrationType !== 0) && (
          <RadioCellContainer
            className={styles.radioCells}
            selected={integrationType}
            onSelectCallback={onSelectCallback}
            rows={2}
            type="background3"
            data={integrationTypeData}
          />
        )}
        {(integrationType === null || integrationType === 0) && (
          <RadioCellContainer
            className={styles.radioCells}
            onSelectCallback={onSelectCallback}
            rows={2}
            type="background4"
            data={integrationTypeData}
          />
        )}
        {integrationType === 1 && (
          <>
            <Spacer size="large" />
            <H6>
              Webflow (V1) API Token
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter the V1 API token to your Webflow website.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              type="password"
              size="small"
              value={webflowToken}
              onChange={(e) => {
                removeErrorWithType(5);
                setWebflowToken(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(5) && styles.inputError
              }`}
              placeholder="********"
            />
            {getErrorWithType(5) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(5).message}
              </Paragraph>
            )}
          </>
        )}
        {integrationType === 2 && (
          <>
            <Spacer size="large" />
            <H6>
              Wordpress URL
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter the URL of your Wordpress website.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={wordpressUrl}
              onChange={(e) => {
                removeErrorWithType(6);
                setWordpressUrl(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(6) && styles.inputError
              }`}
              placeholder="E.g. 'https://www.johnsblog.com'"
            />
            {getErrorWithType(6) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(6).message}
              </Paragraph>
            )}
            <Spacer size="large" />
            <H6>
              Wordpress user
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter your Wordpress user.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={wordpressUser}
              onChange={(e) => {
                removeErrorWithType(7);
                setWordpressUser(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(7) && styles.inputError
              }`}
              placeholder="E.g. 'John'"
            />
            {getErrorWithType(7) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(7).message}
              </Paragraph>
            )}
            <Spacer size="large" />
            <H6>
              Wordpress Application Password
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter the application password to your Wordpress website.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              type="password"
              size="small"
              value={wordpressToken}
              onChange={(e) => {
                removeErrorWithType(5);
                setWordpressToken(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(5) && styles.inputError
              }`}
              placeholder="********"
            />
            {getErrorWithType(5) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(5).message}
              </Paragraph>
            )}
          </>
        )}
        {integrationType === 3 && (
          <>
            <Spacer size="large" />
            <H6>
              Shopify Store ID
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter your Shopify Store ID.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={shopifyUrl}
              onChange={(e) => {
                removeErrorWithType(8);
                setShopifyUrl(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(8) && styles.inputError
              }`}
              placeholder="E.g. 'drafts'"
            />
            {getErrorWithType(8) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(8).message}
              </Paragraph>
            )}
            <Spacer size="large" />
            <H6>
              Shopify API token
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter your Shopify API token.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={shopifyToken}
              onChange={(e) => {
                removeErrorWithType(9);
                setShopifyToken(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(9) && styles.inputError
              }`}
              placeholder="E.g. 'John'"
            />
            {getErrorWithType(9) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(9).message}
              </Paragraph>
            )}
          </>
        )}
        {integrationType === 4 && (
          <>
            <Spacer size="large" />
            <H6>
              Ghost API URL
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter your Ghost API URL (including https://).
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={ghostUrl}
              onChange={(e) => {
                removeErrorWithType(10);
                setGhostUrl(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(10) && styles.inputError
              }`}
              placeholder="E.g. 'https://drafts.ghost.io'"
            />
            {getErrorWithType(10) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(10).message}
              </Paragraph>
            )}
            <Spacer size="large" />
            <H6>
              Ghost Admin API key
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter your Ghost Admin API key.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={ghostToken}
              onChange={(e) => {
                removeErrorWithType(11);
                setGhostToken(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(11) && styles.inputError
              }`}
              placeholder="E.g. 'John'"
            />
            {getErrorWithType(11) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(11).message}
              </Paragraph>
            )}
          </>
        )}
        {integrationType === 5 && (
          <>
            <Spacer size="large" />
            <Paragraph>Once you export your Drafts, we will send a POST request with the article(s) to your webhook URL. Use your webhook token to authenticate requests from Drafts.</Paragraph>
            <Spacer size="large" />
            <H6>
              Webhook URL
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter the URL of your webhook.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={webhookUrl}
              onChange={(e) => {
                removeErrorWithType(12);
                setWebhookUrl(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(12) && styles.inputError
              }`}
              placeholder="E.g. 'https://draftsai.com/webhook'"
            />
            {getErrorWithType(12) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(12).message}
              </Paragraph>
            )}
            <Spacer size="large" />
            <H6>
              Webhook token
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              Enter a webhook token. This will be used to authenticate the requests.
            </Paragraph>
            <Spacer size="medium" />
            <TextInput
              size="small"
              value={webhookToken}
              onChange={(e) => {
                removeErrorWithType(13);
                setWebhookToken(e.target.value);
              }}
              className={`${styles.fullWidth} ${
                getErrorWithType(13) && styles.inputError
              }`}
              placeholder="************"
            />
            {getErrorWithType(13) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(13).message}
              </Paragraph>
            )}
          </>
        )}
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={site === null ? handleSiteChange : editSite}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            {site === null ? "Add site" : "Save " + site.name}
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
