import { ArrowLeft, Plus } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { StorageContext } from "../../context/StorageProvider";
import SecondaryButton from "../buttons/SecondaryButton";
import Link from "../links/Link";
import Container from "../navigation/Container";
import Nav from "../navigation/Nav";
import SearchBar from "../search/SearchBar";
import H4 from "../text/heading/H4";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Blog.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TextInput from "../input/TextInput";
import TextArea from "../input/TextArea";
import PrimaryButton from "../buttons/PrimaryButton";
import { useApolloClient } from "@apollo/client";
import { ADD_BLOG_ARTICLE } from "../../graphql/mutations/BlogArticles";

export default function NewBlogArticle(props) {

    const { userDetails } = useContext(StorageContext);

    const [content, setContent] = useState("");

    const [title, setTitle] = useState("");

    const [summary, setSummary] = useState("");

    const [image, setImage] = useState("");

    const client = useApolloClient();

    const editorConfig = {
        editorStyle: "body { width: 100%; font-size: 16px; background-color: #f5f5f5; }", // Your custom styles
        // ...other configuration options
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                // Add more heading levels if needed
            ],
        },
    };

    async function addBlogArticle() {
        await client
            .mutate({
                mutation: ADD_BLOG_ARTICLE,
                variables: {
                    articleId: -1,
                    name: title,
                    content,
                    summary,
                    image,
                    archived: false
                },
            })
            .catch((error) => {
                console.error("Error updating user:", error);
            });
        window.location.href = "/blog";
    }

    useEffect(() => {
        document.title = "Oops! Looks like you're lost.";
    }, []);

    return (
        <Container className={styles.container}>
            <View className={styles.header}>
                <H4>New blog article</H4>
                <Spacer size="small" />
                <Link
                    to="/blog"
                    size="small"
                    className={styles.smallIconButton}
                >
                    <ArrowLeft size="16px" /> Return to blog
                </Link>
            </View>
            <Nav render />
            <View className={styles.editorContainer}>
                <TextInput value={title} onChange={e => setTitle(e.target.value)} className={styles.blogInput} placeholder="Enter a title" />
                <Spacer size="large" />
                <TextInput value={image} onChange={e => setImage(e.target.value)} className={styles.blogInput} placeholder="Enter an image URL" />
                <Spacer size="large" />
                <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                    }}
                    data={content}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                    }}
                />
                <Spacer size="large" />
                <TextArea value={summary} onChange={e => setSummary(e.target.value)} className={styles.blogInput} placeholder="Enter a summary" />
                <Spacer size="large" />
                <PrimaryButton onClick={addBlogArticle} size="medium">Add article</PrimaryButton>
            </View>
        </Container>
    );
}
