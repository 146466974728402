import React, { createContext, useState, useMemo, useCallback } from "react";
import Overlay from "../components/overlay/Overlay";
import Paragraph from "../components/text/paragraph/Paragraph";

// Create the overlay context
export const OverlayContext = createContext();

// Create a provider component for the overlay context
export function OverlayProvider({ children }) {

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [overlayContent, setOverlayContent] = useState(<Paragraph>Default</Paragraph>);

  const openOverlay = useCallback((content) => {
    setOverlayContent(content);
    setIsOverlayVisible(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setIsOverlayVisible(false);
  }, []);

  const overlayContextValue = useMemo(() => {
    return {
      isOverlayVisible,
      openOverlay,
      closeOverlay
    };
  }, [isOverlayVisible, openOverlay, closeOverlay]);

  return (
    <OverlayContext.Provider value={overlayContextValue}>
      <Overlay isVisible={isOverlayVisible}>{overlayContent}</Overlay>
      {children}
    </OverlayContext.Provider>
  );
}
