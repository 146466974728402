import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { ADD_SITE, UPDATE_SITE } from "../../../graphql/mutations/Sites";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import TextArea from "../../input/TextArea";
import RadioCellContainer from "../../radiocell/RadioCellContainer";
import H5 from "../../text/heading/H5";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/NewSiteModal.module.css";
import {
  ADD_ARTICLE,
  UPDATE_ARTICLE,
} from "../../../graphql/mutations/Articles";
import { Select } from "@chakra-ui/react";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import Link from "../../links/Link";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Triangle } from "react-feather";
import { UPDATE_CREDITS, UPDATE_USER } from "../../../graphql/mutations/Users";
import { set } from "date-fns";

export default function NewArticleModal(props) {
  const { setUpdate } = useContext(ConfigContext);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const { userDetails } = useContext(StorageContext);

  const [name, setName] = useState("");

  const [content, setContent] = useState("");

  const [instruction, setInstruction] = useState("");

  const [article, setArticle] = useState(null);

  const [errors, setErrors] = useState([]);

  const getTitle = () => {
    switch (props.articleType) {

      case "listicle":
        return "Add new listicle";

      case "academic_essay":
        return "Add new academic essay";

      case "editorial_essay":
        return "Add new editorial essay";

      case "press_release":
        return "Add new press release";

      case "news_article":
        return "Add new news article";

      case "opinion_piece":
        return "Add new opinion piece";

      default:
        return "Add new article";
    }
  };

  const getPlaceholder = () => {
    switch (props.articleType) {
      
      case "listicle":
        return "E.g. 'Top 10 Astronauts'";

      case "academic_essay":
        return "E.g. 'Generative AI'";

      case "editorial_essay":
        return "E.g. 'Generative AI'";

      case "press_release":
        return "E.g. 'NASA launches new rocket'";

      case "news_article":
        return "E.g. 'NASA launches new rocket'";

      case "opinion_piece":
        return "E.g. 'Airplane food'";

      default:
        return "Add new article";
    }
  };

  useEffect(() => {
    if (!isOverlayVisible) {
      setArticle(null);
      setName("");
      setInstruction("");
      setContent("");
      setErrors([]);
    } else {
      if (props.article) {
        setArticle(props.article);
        setName(props.article.name);
        setInstruction(props.article.instruction);
        setContent(props.article.content);
      }
    }
  }, [isOverlayVisible, props.article]);

  function getErrorWithType(typeToCheck) {
    return errors.find((error) => error.type === typeToCheck);
  }

  function removeErrorWithType(typeToRemove) {
    const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
    setErrors(updatedErrors);
  }

  function handleArticleChange() {
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "You need to enter a name.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    setTimeout(() => {
      addArticle();
    }, 500);
  }

  function editArticle() {
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "You need to enter a name.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    setTimeout(() => {
      updateArticle();
    }, 500);
  }

  async function addArticle() {
    if (!userDetails.credits || userDetails.credits < 1) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 10,
          message: "You don't have enough credits to add a new article.",
        },
      ]);
      setUpdate(true);
      return;
    }
    userDetails.credits -= 1;
    await client
      .mutate({
        mutation: UPDATE_CREDITS,
        variables: {
          email: userDetails.email,
          credits: userDetails.credits,
        },
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    const article = {
      articleId: -1,
      reportId: props.report.reportId,
      name,
      content,
      instruction,
      archived: false,
    };
    const response = await client
      .mutate({
        mutation: ADD_ARTICLE,
        variables: article,
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    setUpdate(true);
    if (response && response.data && response.data.addArticle) {
      closeOverlay();
      setName("");
      setInstruction("");
      setContent("");
      setErrors([]);
      props.addArticle(response.data.addArticle);
    }
  }

  function isURL(str) {
    const urlPattern = /^(?:https?:\/\/)?(?:www\.)?[^\s.]+\.\S{2,}$/i;
    return urlPattern.test(str);
  }

  async function updateArticle() {
    const response = await client
      .query({
        query: UPDATE_ARTICLE,
        variables: {
          articleId: article.articleId,
          name,
          content,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    setUpdate(true);
    if (response && response.data && response.data.updateArticle) {
      console.log("response:", response.data.updateArticle);
      closeOverlay();
      setName("");
      setInstruction("");
      setContent("");
      setErrors([]);
      props.updateArticle(response.data.updateArticle);
    }
  }

  return (
    <View vertical className={styles.main}>
      <View className={styles.paddedContainer} vertical>
        <TextInput
          value={article === null ? getTitle() : "Edit " + article.name}
          className={styles.cardTitle}
        />
        <Spacer size="medium" />
        <Paragraph>
          We'll use the information you provided in the report to create a new
          article.
        </Paragraph>
        <Spacer size="large" />
        <H6>
          Subject<span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          What is the subject of the article?
        </Paragraph>
        <Spacer size="medium" />
        <TextInput
          size="small"
          value={name}
          onChange={(e) => {
            removeErrorWithType(2);
            setName(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            getErrorWithType(2) && styles.inputError
          }`}
          placeholder={"" + getPlaceholder()}
        />
        {getErrorWithType(2) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(2).message}
          </Paragraph>
        )}
        <Spacer size="large" />
        <H6>
          Instruction (optional)
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          Instruct our AI to include or exclude certain information.
        </Paragraph>
        <Spacer size="medium" />
        <TextArea
          size="small"
          value={instruction}
          onChange={(e) => {
            removeErrorWithType(3);
            setInstruction(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            getErrorWithType(3) && styles.inputError
          }`}
          placeholder={"E.g. 'Include information about the history of the topic'"}
        />
        {getErrorWithType(3) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(3).message}
          </Paragraph>
        )}
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        {getErrorWithType(10) && (
          <Paragraph
            style={{ minWidth: "400px", width: "100%", color: "var(--red)" }}
            size="small"
          >
            You have ran out of credits.{" "}
            <Link
              size="small"
              onClick={() => {
                closeOverlay();
                props.handleTabChange({
                  id: "billing",
                  name: "Billing",
                });
              }}
            >
              Go to billing
            </Link>
          </Paragraph>
        )}
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={article === null ? handleArticleChange : editArticle}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            {article === null ? "Add article" : "Save " + article.name}
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
