import { gql } from "@apollo/client";

export const GET_BLOG_ARTICLES = gql`
  query {
    getBlogArticles {
      articleId
      name
      content
      summary
      date
      edited
      slug
      image
      archived
    }
  }
`;

export const GET_BLOG_ARTICLE_BY_SLUG = gql`
  query getBlogArticleBySlug($slug: String!) {
    getBlogArticleBySlug(slug: $slug) {
      articleId
      name
      content
      summary
      date
      edited
      slug
      image
      archived
    }
  }
`;