import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import config from "../../../config.json";
import { OverlayContext } from "../../../context/OverlayProvider";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/NewSiteModal.module.css";

export default function PublishOnWordpressModal(props) {

  const [loading, setLoading] = useState(false);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  async function publish() {
    if (!props.articles || !props.site) {
      console.log("Some required inputs are missing.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
          `/api/wordpress/publish`,
        { articles: props.articles, token: props.site.wordpressToken, url: props.site.wordpressUrl, user: props.site.wordpressUser }, // Sending the articles array as JSON
        {
          headers: {
            "Content-Type": "application/json", // Set the correct content type
            "accept-version": "1.0.0",
          },
        }
      );
      if (response && response.data) {
        setLoading(false);
        closeOverlay();
      }
    } catch (error) {
      console.error("Error publishing articles:", error);
    }
  }

  useEffect(() => {
    if (!isOverlayVisible) {
      setLoading(false);
    } else {
    }
  }, [isOverlayVisible, props.site]);

  if (loading) {
    return (
      <View pad="large" centerVertically centerHorizontally>
        <View pad="large" centerVertically centerHorizontally>
          <Oval
            height={"24"}
            width={"24"}
            color="var(--neutral-400)"
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="var(--neutral-800)"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </View>
      </View>
    );
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <TextInput
          value={
            "Export " +
            (props.articles.length > 1 ? "articles" : "article") +
            " to Wordpress"
          }
          className={styles.cardTitle}
        />
        <Spacer size="medium" />
        <Paragraph>
          Publish your drafts to your Wordpress site in a single click.
        </Paragraph>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            onClick={publish}
            spinner
            linkClassName={`${styles.fullWidthButton} ${styles.redButton}`}
            className={`${styles.fullWidthButton} ${styles.redButton}`}
            size="small"
          >
            Export
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
