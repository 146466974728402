import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PrimaryButton from "../buttons/PrimaryButton";
import Container from "../navigation/Container";
import Nav from "../navigation/Nav";
import H5 from "../text/heading/H5";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Login.module.css";
import { useApolloClient } from "@apollo/client";
import { UNSUBSCRIBE } from "../../graphql/mutations/Users";

export default function Unsubscribe() {

    const client = useApolloClient();

    const { email } = useParams();

    const [unsubscribed, setUnsubscribed] = useState(false);

    async function unsubscribe() {
        const response = await client.mutate({
            mutation: UNSUBSCRIBE,
            variables: {
                email,
            },
            fetchPolicy: "no-cache",
        });
        if (response && response.data && response.data.unsubscribe) {
            setUnsubscribed(true);
        }
    }

    useEffect(() => {
        document.title = "You've successfully unsubscribed.";
        if (!unsubscribed) {
            unsubscribe();
        }
    }, []);

    return (
        <Container className={styles.container}>
            <Nav render />
            <View className={styles.loginContainer}>
                <H5>You've successfully unsubscribed.</H5>
                <Spacer size="medium" />
                <Paragraph>Sorry to see you go.</Paragraph>
                <Spacer size="large" />
                {
                    <PrimaryButton to="/" size="medium">
                        Return home
                    </PrimaryButton>
                }
            </View>
        </Container>
    );
}