import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/H5.module.css";
import TextInput from "../../input/TextInput";
import { Oval } from "react-loader-spinner";
import View from "../../view/View";
import { Tooltip } from "@chakra-ui/react";
import Paragraph from "../paragraph/Paragraph";

export default function H5(props) {
  const [editing, setEditing] = useState(false);

  const [value, setValue] = useState(props.children);

  const [valueCache, setValueCache] = useState(props.children);

  const [width, setWidth] = useState(0);

  const [saving, setSaving] = useState(false);

  const span = useRef();

  useEffect(() => {
    if (span.current) {
      setWidth(span.current.offsetWidth + 18);
    }
  }, [value]);

  function dote() {
    setValueCache(value);
    setEditing(!editing);
  }

  function finishEditing() {
    if (value !== valueCache) {
      setSaving(true);
    }
    setEditing(false);
    setTimeout(() => {
      if (props.onEdit) {
        props.onEdit(value);
      }
      setSaving(false);
    }, 250);
  }

  useEffect(() => {
    if (editing === true) {
      if (props.onEditing) {
        props.onEditing();
      }
    } else if (editing === false) {
      if (props.onFinishedEditing) {
        props.onFinishedEditing();
      }
    }
  }, [editing]);

  if (editing) {
    return (
      <View>
        <span className={styles.hide} ref={span}>
          {value}
        </span>
        <TextInput
          style={{ width }}
          className={`${styles.input} ${props.inputClassName}`}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          autoFocus
          onBlur={finishEditing}
        />
        {saving && (
          <View className={props.loadingClassName}>
            <Oval
              height={"16"}
              width={"16"}
              color="var(--neutral-400)"
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--neutral-800)"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </View>
        )}
      </View>
    );
  } else {
    if (props.editable === true) {
      return (
        <Tooltip className={styles.tooltip} label={props.tooltip}>
          <h5
            onClick={() => {
              if (props.editable === true) {
                dote();
              }
            }}
            className={`${styles.defaults} ${styles.editText} ${props.className}`}
          >
            <View centerVertically>
              <span className={styles.span} ref={span}>
                {value}
              </span>
              {saving && (
                <View className={props.loadingClassName}>
                  <Oval
                    className={styles.smallSpinner}
                    height={"16"}
                    width={"16"}
                    color="var(--neutral-400)"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="var(--neutral-800)"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </View>
              )}
            </View>
            {!saving && (
              <Paragraph className={props.editClassName}>
                (click to edit)
              </Paragraph>
            )}
          </h5>
        </Tooltip>
      );
    } else {
      return (
        <h5
          className={`${styles.defaults} ${props.className}`}
        >
          <View>
            <span className={styles.span} ref={span}>
              {value}
            </span>
            {saving && (
              <Oval
                className={styles.smallSpinner}
                height={"16"}
                width={"16"}
                color="var(--neutral-400)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--neutral-800)"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            )}
          </View>
        </h5>
      );
    }
  }
}
