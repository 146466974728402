import React, { createContext, useEffect, useState } from "react";

export const ConfigContext = createContext();

export function ConfigProvider({ children }) {

  const [darkMode, setDarkMode] = useState(false);

  const [update, setUpdate] = useState(false);

  const [receivedResponse, setReceivedResponse] = useState(false);

  const [keywords, setKeywords] = useState([]);

  const [dashboardTab, setDashboardTab] = useState("Links");

  const [selectedIds, setSelectedIds] = useState([]);

  const [prefixLink, setPrefixLink] = useState("");

  const [appearanceSettings, setAppearanceSettings] = useState(null);

  const [articlesGenerated, setArticlesGenerated] = useState(0);

  const [articlesToGenerate, setArticlesToGenerate] = useState(0);

  const [cachedPlan, setCachedPlan] = useState(null);

  const configState = {
    newName: null
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('darkMode');
    } else {
      document.documentElement.classList.remove('darkMode');
    }
  }, [darkMode]);

  return (
    <ConfigContext.Provider value={{ cachedPlan, setCachedPlan, keywords, setKeywords, configState, articlesToGenerate, setArticlesToGenerate, articlesGenerated, setArticlesGenerated, receivedResponse, setReceivedResponse, appearanceSettings, setAppearanceSettings, prefixLink, setPrefixLink, selectedIds, setSelectedIds, darkMode, setDarkMode, update, setUpdate, dashboardTab, setDashboardTab }}>
      {children}
    </ConfigContext.Provider>
  );
}
