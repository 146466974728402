import { Tooltip } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import TextInput from "../../input/TextInput";
import View from "../../view/View";
import styles from "./styles/Paragraph.module.css";

export default function Paragraph(props) {

  const [editing, setEditing] = useState(false);

  const [value, setValue] = useState(props.children);

  const [valueCache, setValueCache] = useState(props.children);

  const [width, setWidth] = useState(0);

  const [saving, setSaving] = useState(false);

  const span = useRef();

  useEffect(() => {
    if (span.current) {
      setWidth(span.current.offsetWidth + 18);
    }
  }, [value]);

  function dote() {
    setValueCache(value);
    setEditing(!editing);
  }

  function finishEditing() {
    if (value !== valueCache) {
      setSaving(true);
    }
    setEditing(false);
    setTimeout(() => {
      if (props.onEdit) {
        props.onEdit(value);
      }
      setSaving(false);
    }, 250);
  }

  if (editing) {
    return (
      <View>
        <span style={props.spanStyle} className={styles.hide} ref={span}>
          {value}
        </span>
        <TextInput
          style={{ width }}
          className={styles.input}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          autoFocus
          onBlur={finishEditing}
        />
        {saving && (
          <Oval
            height={"16"}
            width={"16"}
            color="var(--neutral-400)"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="var(--neutral-800)"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        )}
      </View>
    );
  } else {
    if (props.editable) {
      return (
          <p
            onClick={props.editable && dote}
            style={props.style}
            className={`${styles.defaults} ${styles.editText} ${
              props.center && styles.center
            } ${props.size == "small" && styles.small} ${
              props.size == "big" && styles.big
            } ${props.className}`}
          >
            <View centerVertically>
            <Tooltip className={styles.tooltip} label={props.tooltip}>

              <span style={props.spanStyle} className={styles.span} ref={span}>
                {value}
              </span>
              </Tooltip>
              {saving && (
                <Oval
                  className={styles.smallSpinner}
                  height={"16"}
                  width={"16"}
                  color="var(--neutral-400)"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="var(--neutral-800)"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              )}
            </View>
          </p>
      );
    } else {
      return (
        <p
          onClick={props.editable && dote}
          style={props.style}
          className={`${styles.defaults} ${
            props.center && styles.center
          } ${props.size == "small" && styles.small} ${
            props.size == "big" && styles.big
          } ${props.className}`}
        >
          <View>
            <span style={props.spanStyle} className={styles.span} ref={span}>
              {value}
            </span>
            {saving && (
              <Oval
                className={styles.smallSpinner}
                height={"16"}
                width={"16"}
                color="var(--neutral-400)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--neutral-800)"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            )}
          </View>
        </p>
      );
    }
  }
}
