import React, { useContext } from 'react';
import styles from './styles/TagGrid.module.css'; // You can create a separate CSS file for styling
import View from '../view/View';
import Paragraph from '../text/paragraph/Paragraph';
import { ConfigContext } from '../../context/ConfigProvider';

const TagGrid = (props) => {

  const { darkMode, newName, setNewName } = useContext(ConfigContext);

  return (
    <View className={`${styles.tagGrid} ${props.className && props.className}`}>
      {props.tags.map((tag, index) => (
        <div key={index} className={darkMode ? styles.tagBubble : styles.lightTagBubble}>
          <Paragraph className={darkMode ? styles.tagText : styles.lightTagText}>{tag}</Paragraph>
        </div>
      ))}
    </View>
  );
};

export default TagGrid;