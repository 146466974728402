import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import View from "../../view/View";
import Spacer from "../../view/impl/Spacer";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import SecondaryButton from "../../buttons/SecondaryButton";
import H5 from "../../text/heading/H5";
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Switch,
} from "@chakra-ui/react";
import H4 from "../../text/heading/H4";
import { Check } from "react-feather";
import PrimaryButton from "../../buttons/PrimaryButton";
import Separator from "../../view/impl/Separator";
import styles from "./styles/Billing.module.css";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { StorageContext } from "../../../context/StorageProvider";
import Modal from "../../modals/Modal";
import SignInModal from "../../modals/impl/SignInModal";
import { OverlayContext } from "../../../context/OverlayProvider";
import { useStripe } from "@stripe/react-stripe-js";
import { Oval } from "react-loader-spinner";
import { GET_USER_BY_EMAIL } from "../../../graphql/queries/Users";
import { useApolloClient } from "@apollo/client";
import config from "../../../config.json";
import { BadgeCheck, CircleDollarSign, CreditCard, HeartHandshake } from "lucide-react";

const stripePromise = loadStripe(
  "pk_live_51L0YG8CR42wmYSKKxOzySTnW85rDEd7XsI78ygaotk3WhPdn3l9patA6XvKEvgsnYnN5EGilCVzRnTpeDRzyYoh400r1mYorFH"
);

export default function Billing(props) {
  const { userDetails, setUserDetails } = useContext(StorageContext);

  const { darkMode, cachedPlan, setCachedPlan } = useContext(ConfigContext);

  const [sliderValue, setSliderValue] = useState(0);

  const [loading, setLoading] = useState(true);

  const client = useApolloClient();

  const [loadingFree, setLoadingFree] = useState(false);

  const [loadingHobby, setLoadingHobby] = useState(false);

  const [loadingPro, setLoadingPro] = useState(false);

  const [loadingOndemand, setLoadingOndemand] = useState(false);

  const [recurring, setRecurring] = useState("monthly");

  const plans = [
    {
      id: "price_1NlGNZCR42wmYSKK2ciHraRX",
      name: "Free",
      desc: "Try Drafts at no cost",
      price: 0,
      features: [
        "2 free articles per month",
        "100+ languages supported",
        "Email support",
      ],
    },
    {
      id: "price_1Nj4dlCR42wmYSKKGQcNzTR7",
      name: "Hobby",
      desc: "Suitable for startups and blogs",
      recurring: "monthly",
      price: 29,
      features: [
        "50 articles per month",
        "100+ languages supported",
        "Prioritized email support",
      ],
    },
    {
      id: "price_1Nj4h3CR42wmYSKKKEis4c6T",
      name: "Pro",
      desc: "Now we're talking",
      recurring: "monthly",
      price: 79,
      features: [
        "150 articles per month",
        "100+ languages supported",
        "Prioritized email support",
      ],
    },
    {
      id: "price_1Nj4fICR42wmYSKKMEWGs7Gz",
      name: "Hobby",
      recurring: "annually",
      price: 290,
      features: [
        "50 articles per month",
        "100+ languages supported",
        "Prioritized email support",
      ],
    },
    {
      id: "price_1Nj4h3CR42wmYSKK7WDTrZRn",
      name: "Pro",
      recurring: "annually",
      price: 790,
      features: [
        "150 articles per month",
        "100+ languages supported",
        "Prioritized email support",
      ],
    },
    {
      id: "price_1Nj4jUCR42wmYSKKYA5oemPD",
      name: "On-demand",
      recurring: "onetime",
      price: 1.99,
    },
  ];

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  const [planCache, setPlanCache] = useState(null);

  async function updateBillingSettings() {
    setLoading(true);
    var response = await client
      .query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email: userDetails.email.toLowerCase(),
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
    if (response && response.data && response.data.getUserByEmail) {
      localStorage.setItem(
        "userDetails",
        JSON.stringify(response.data.getUserByEmail)
      );
      if (response.data.getUserByEmail.plan !== userDetails.plan) {
        setUserDetails(response.data.getUserByEmail);
      }
    }
    try {
      const subscriptionId = response.data.getUserByEmail.plan;
      if (subscriptionId && subscriptionId !== "") {
        response = await axios.get(
          (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
            "/api/subscription/" +
            subscriptionId
        );
        setPlanCache(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching subscription details:", error);
    }
  }

  useEffect(() => {
    if (userDetails && userDetails.email) {
      updateBillingSettings();
    } else {
      setLoading(false);
    }
  }, [userDetails]);

  const getBillingPortalURL = async () => {
    try {
      const response = await axios.post(
        (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
          "/api/create-billing-portal-session",
        {
          customerId: userDetails.customerId,
        }
      );

      const data = await response.data;
      return data.url;
    } catch (error) {
      console.error("Error getting billing portal URL:", error);
      return null;
    }
  };

  const handleCheckout = async (id) => {
    try {
      if (userDetails) {
        if (id === plans[0].id) {
          setLoadingFree(true);
        } else if (id === plans[1].id || id === plans[3].id) {
          setLoadingHobby(true);
        } else if (id === plans[2].id || id === plans[4].id) {
          setLoadingPro(true);
        } else if (id === plans[5].id) {
          setLoadingOndemand(true);
        }
        if (
          planCache &&
          planCache.subscription &&
          planCache.subscription.plan &&
          planCache.subscription.plan.id === id &&
          userDetails.customerId &&
          userDetails.customerId !== ""
        ) {
          // Open Stripe portal for managing the subscription
          const portalURL = await getBillingPortalURL();
          if (portalURL) {
            window.location.href = portalURL; // Redirect the user to the portal URL
          }
        } else {
          // Create a new checkout session
          const response = await axios.post(
            (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
              "/api/create-checkout-session",
            {
              email: userDetails.email,
              plan: id,
              amount: id === plans[5].id ? sliderValue + 1 : 1,
            }
          );
          const sessionId = response.data.sessionId;
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });

          if (error) {
            console.error("Error redirecting to checkout:", error);
          }
        }
      } else {
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <View
      className={styles.contentContainer2}
      vertical
      style={{
        width: props.landing
          ? "100%"
          : !props.showMenu
          ? `calc(100% - 75px)`
          : `calc(100% - 285px)`,
      }}
    >
      <View
        vertical
        centerVertically
        className={`${props.landing ? styles.landingView : styles.innerView}`}
      >
        <Spacer size="large" />
        <H5 className={styles.subtitle}>
          <span className={styles.strike}>Our price</span>{" "}
          <span className={styles.seconds}>your investment</span>
        </H5>
        <Spacer size="medium" />
        <Paragraph center size="big" className={styles.secondaryColor}>
          Only pay for what you need. No hidden fees. No surprises.
        </Paragraph>
        <Spacer size="large" />
        <Spacer size="medium" />
        <View style={{ columnGap: "16px" }} centerHorizontally>
          <Switch
            onChange={() =>
              setRecurring(recurring === "monthly" ? "annually" : "monthly")
            }
            defaultChecked={recurring === "annually"}
            size="lg"
            id="annualBilling"
          />
          <Paragraph size="large">
            <span style={{ fontWeight: "600", color: "var(--neutral-100)" }}>
              Annual billing
            </span>{" "}
            (2 months free)
          </Paragraph>
        </View>
        <Spacer size="large" />
        <View className={styles.pricingContainer}>
          {plans
            .filter((plan) => plan.recurring === recurring || !plan.recurring)
            .map((plan) => {
              return (
                <View
                  vertical
                  className={`${styles.pricingCell} ${
                    plan.name === "Hobby" && styles.scaleUp
                  }`}
                >
                  <View vertical className={styles.pricingCellTop}>
                    {plan.name === "Hobby" && (
                      <>
                        <H6 className={styles.tagTitle}>Recommended</H6>
                        <Spacer size="medium" />
                      </>
                    )}
                    <H5 className={styles.smallH5}>{plan.name}</H5>
                    <Spacer size="small" />
                    <Paragraph size="small">{plan.desc}</Paragraph>
                    <Spacer size="medium" />
                    <H4 className={styles.price}>
                      ${plan.price}
                      <span className={styles.perMonth}>
                        {!plan.recurring ? "" : "/"}{" "}
                        {!plan.recurring
                          ? ""
                          : plan.recurring === "annually"
                          ? "yr"
                          : "mo"}
                      </span>
                    </H4>
                  </View>
                  {!userDetails ? (
                    <PrimaryButton
                      to="/login"
                      className={styles.planButton}
                      size="medium"
                    >
                      Choose plan
                    </PrimaryButton>
                  ) : loading ||
                    (plan.id === plans[0].id && loadingFree) ||
                    ((plan.id === plans[1].id || plan.id === plans[3].id) &&
                      loadingHobby) ||
                    ((plan.id === plans[2].id || plan.id === plans[4].id) &&
                      loadingPro) ? (
                    <View
                      centerVertically
                      centerHorizontally
                      className={styles.pseudoButton}
                    >
                      <Oval
                        height={"24"}
                        width={"24"}
                        color="var(--neutral-400)"
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="var(--neutral-800)"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    </View>
                  ) : userDetails &&
                    planCache &&
                    planCache.subscription.plan.id === plan.id ? (
                    <SecondaryButton
                      onClick={() => handleCheckout(plan.id)}
                      className={styles.planButton}
                      size="medium"
                    >
                      Manage plan
                    </SecondaryButton>
                  ) : userDetails && planCache ? (
                    <PrimaryButton
                      onClick={() => handleCheckout(plan.id)}
                      className={styles.planButton}
                      size="medium"
                    >
                      Change plan
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      onClick={() => handleCheckout(plan.id)}
                      className={styles.planButton}
                      size="medium"
                    >
                      Choose plan
                    </PrimaryButton>
                  )}
                  <Spacer size="medium" />
                  <Spacer size="small" />
                  <Separator className={styles.wideSeparator} />
                  <View vertical className={styles.pricingCellTop}>
                    <H6 className={styles.smallTitle}>
                      The {plan.name} plan includes:
                    </H6>
                    <Spacer size="medium" />
                    <View vertical gap="small">
                      {plan.features.map((feature) => {
                        return (
                          <View className={styles.smallIconText}>
                            <Check
                              style={{ marginTop: "2px" }}
                              color="var(--primary)"
                              size="18px"
                            />
                            <Paragraph size="small">{feature}</Paragraph>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
              );
            })}
        </View>
        <Spacer size="large" />
        <View vertical className={styles.enterpriseContainer}>
          <Separator className={styles.wideSeparator} />
          <Spacer size="large" />
          <View
            vertical
            style={{ width: "100%", maxWidth: "1008px" }}
            className={styles.pricingCell}
          >
            <View vertical className={styles.pricingCellTop}>
              <H5 className={styles.smallH5}>On-demand articles</H5>
              <Spacer size="small" />
              <Paragraph size="small">
                Pay exactly for what you need at $1.99 per article
              </Paragraph>
              <Spacer size="medium" />
              <H4 className={styles.price}>
                ${(1.99 + 1.99 * sliderValue).toFixed(2)}
                <span className={styles.perMonth}>(total)</span>
              </H4>
            </View>
            <View className={styles.sliderContainer}>
              <Slider
                aria-label="slider-ex-1"
                defaultValue={sliderValue}
                onChange={(val) => setSliderValue(val)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </View>
            <Spacer size="large" />
            {loading || loadingOndemand ? (
              <View
                centerVertically
                centerHorizontally
                className={styles.pseudoButton}
              >
                <Oval
                  height={"24"}
                  width={"24"}
                  color="var(--neutral-400)"
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="var(--neutral-800)"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </View>
            ) : (
              <PrimaryButton
                onClick={() => handleCheckout(plans[5].id)}
                className={styles.planButton}
                size="medium"
              >
                {"Buy " +
                  (sliderValue + 1) +
                  " article" +
                  (sliderValue + 1 > 1 ? "s" : "")}
              </PrimaryButton>
            )}
            <Spacer size="medium" />
            <Spacer size="small" />
          </View>
        </View>
        <Spacer size="large" />
        <View vertical className={styles.enterpriseContainer}>
          <Separator className={styles.wideSeparator} />
          <Spacer size="large" />
          <View
            vertical
            style={{ width: "100%", maxWidth: "1008px" }}
            className={styles.pricingCell}
          >
            <View vertical className={styles.pricingCellTop}>
              <H5 className={styles.smallH5}>Enterprise</H5>
              <Spacer size="small" />
              <Paragraph size="small">
                Unlock limitless growth with advanced features and support
              </Paragraph>
              <Spacer size="medium" />
              <H4 className={styles.price}>
                Custom<span className={styles.perMonth}>/ mo</span>
              </H4>
            </View>
            <SecondaryButton
              onClick={() => {
                window.location.href =
                  "mailto:sales@draftsai.com?subject=Regarding Drafts";
              }}
              className={styles.planButton}
              size="medium"
            >
              Contact sales
            </SecondaryButton>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Separator className={styles.wideSeparator} />
            <View vertical className={styles.pricingCellTop}>
              <H6 className={styles.smallTitle}>
                The Enterprise plan includes:
              </H6>
              <Spacer size="medium" />
              <View className={styles.smallIconText}>
                <Check
                  style={{ marginTop: "4px" }}
                  color="var(--neutral-100)"
                  size="14px"
                />
                <Paragraph size="small">As many articles as you need</Paragraph>
              </View>
              <Spacer size="small" />
              <View className={styles.smallIconText}>
                <Check
                  style={{ marginTop: "4px" }}
                  color="var(--neutral-100)"
                  size="14px"
                />
                <Paragraph size="small">
                  VIP support & dedicated outcomes manager
                </Paragraph>
              </View>
              <Spacer size="small" />
              <View className={styles.smallIconText}>
                <Check
                  style={{ marginTop: "4px" }}
                  color="var(--neutral-100)"
                  size="14px"
                />
                <Paragraph size="small">Prioritized feature requests</Paragraph>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Spacer size="large" />
    </View>
  );
}
