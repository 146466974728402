import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { ArrowRight, Star } from "react-feather";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import H2 from "../text/heading/H2";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import Billing from "./drafts/Billing";
import styles from "./styles/CompanyLanding.module.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  Briefcase,
  Check,
  CheckCheck,
  CheckCircle2,
  HeartHandshake,
  Lightbulb,
  PenLine,
  ShieldQuestion,
  Sparkles,
  Users2,
  Webhook,
  Zap,
} from "lucide-react";
import H5 from "../text/heading/H5";
import ReactGA from "react-ga";
import H6 from "../text/heading/H6";
import Link from "../links/Link";
import CTA from "./drafts/CTA";
import SecondaryButton from "../buttons/SecondaryButton";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'

const stripePromise = loadStripe(
  "pk_live_51L0YG8CR42wmYSKKxOzySTnW85rDEd7XsI78ygaotk3WhPdn3l9patA6XvKEvgsnYnN5EGilCVzRnTpeDRzyYoh400r1mYorFH"
);

export default function CompanyLanding() {
  const [link, setLink] = useState("");

  const { setPrefixLink } = useContext(ConfigContext);

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const client = useApolloClient();

  const { darkMode } = useContext(ConfigContext);

  useEffect(() => {
    document.title =
      "Drafts AI – Write hundreds of SEO optimized articles in seconds";
  }, []);

  function setLinkInput(link) {
    setError(null);
    setLink(link);
  }

  return (
    <View vertical className={styles.background}>
      <View className={styles.bubbleWrapper}>
        <View className={styles.bubbleBackground}></View>
        <View className={styles.bubbleColorBg}></View>
        <View className={styles.bubbleGradient}></View>
      </View>
      <View className={styles.flare}></View>
      <View centerVertically centerHorizontally>
        <View className={styles.container}>
          <View vertical className={styles.wrapper}>
            <Spacer size="large" />
            <H2
              className={`${styles.header} ${darkMode && styles.headerDarkMode
                }`}
            >
              Subject to blog in seconds
            </H2>

            <Spacer size="small" />
            <Paragraph size="large" className={styles.message}>
              Upload your list of target keywords and receive hundreds of
              high-quality articles within seconds.
            </Paragraph>
            <Spacer size="small" />
            <Spacer size="medium" />
            <Spacer size="small" />
            <View gap="medium" className={styles.waitlistContainer}>
              <View
                style={{ marginTop: "-2.5px" }}
                centerVertically
                centerHorizontally
              >
                <View className={styles.ctaButtonContainer}>
                  <PrimaryButton to="/join" className={styles.ctaButton}>
                    Get started –{" "}
                    <span className={styles.boldSpan}>it's free</span>
                    <ArrowRight color="var(--neutral-1000)" size={18} />
                  </PrimaryButton>
                </View>
              </View>
            </View>
            <Spacer size="large" />
            <Paragraph
              center
              className={`${styles.primaryColor} ${styles.maxWidthPrimary}`}
            >
              Join{" "}
              <b className={styles.bPrimary}>
                hundreds of creators, indiehackers and startups{" "}
              </b>{" "}
              and start publishing blog articles today
            </Paragraph>
            <Spacer size="medium" />
            <Spacer size="large" />
            <View className={styles.appImageContainer}>
              <View className={styles.appImageGradient} />
              <View className={styles.appImageWrapper}>
                <img
                  src={
                    "./assets/images/app-" +
                    (darkMode ? "black" : "white") +
                    ".png"
                  }
                  className={styles.appImage}
                />
              </View>
            </View>
            <Spacer size="large" />
            <Spacer size="large" />
            <Spacer size="medium" />
            <View
              vertical
              centerVertically
              centerHorizontally
              className={styles.topContainer}
            >
              <View className={styles.integrationsBackground} />
              <H5 className={styles.subtitle}>
                From idea to your blog in seconds
              </H5>
              <Spacer size="medium" />
              <Paragraph center size="big" className={styles.secondaryColor}>
                Creating high quality SEO-friendly content in bulk has never
                been easier.
              </Paragraph>
            </View>
            <Spacer size="large" />
            <Spacer size="medium" />
            <View
              centerHorizontally
              className={styles.blockContainer}
              gap="large"
            >
              <View vertical className={styles.block}>
                <View className={styles.iconContainer}>
                  <Briefcase color="rgb(15 23 41)" size="28" />
                </View>
                <Spacer size="large" />
                <H5 className={styles.whiteSmallHeading}>For startups</H5>
                <Paragraph className={styles.whiteParagraph}>
                  Increase organic outreach
                </Paragraph>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>100+ languages supported</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Passes AI detection</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Bulk article generation</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Instant blog publishing</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Content customization</H6>
                </View>
                <Spacer size="large" />
                <Link to="/app/billing" className={styles.whiteLink}>
                  Learn more
                  <ArrowRight color="#fff" size={18} />
                </Link>
              </View>
              <View vertical className={styles.block}>
                <View className={styles.iconContainer}>
                  <Users2 color="rgb(15 23 41)" size="28" />
                </View>
                <Spacer size="large" />
                <H5 className={styles.whiteSmallHeading}>For creators</H5>
                <Paragraph className={styles.whiteParagraph}>
                  Grow your audience on autopilot
                </Paragraph>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>100+ languages supported</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Passes AI detection</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Copyright-free images</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Bulk article generation</H6>
                </View>
                <Spacer size="medium" />
                <View centerVertically>
                  <Check
                    className={styles.greenCheck}
                    size="22"
                    color="#fff"
                  />
                  <H6 className={styles.checkTitle}>Free to use</H6>
                </View>
                <Spacer size="large" />
                <Link to="/app/billing" className={styles.whiteLink}>
                  Learn more
                  <ArrowRight color="#fff" size={18} />
                </Link>
              </View>
            </View>
            <Spacer size="large" />
            <Spacer size="large" />
            <Spacer size="large" />
            <Spacer size="medium" />
            <View
              vertical
              centerVertically
              centerHorizontally
              className={styles.integrationsContainer}
            >
              <View className={styles.integrationsBackground} />
              <HeartHandshake
                color="var(--neutral-100)"
                className={styles.heartHandshake}
                size="48"
              />
              <Spacer size="large" />
              <H5 className={styles.subtitle}>
                Integrates seemlessly with leading blog providers
              </H5>
              <Spacer size="medium" />
              <Paragraph center size="big" className={styles.secondaryColor}>
                Publish your articles directly to your blog or CMS in seconds.
              </Paragraph>
              <Spacer size="large" />
              <Spacer size="medium" />
              <View gap="medium" vertical className={styles.blockContainer}>
                <View gap="medium" className={styles.blockContainer}>
                  <View className={styles.blockLight}>
                    <img
                      className={styles.gridIcon}
                      src="./assets/images/logos/webflow.png"
                    />
                    <Spacer size="small" />
                    <Spacer size="medium" />
                    <H6>Webflow (V1)</H6>
                    <Spacer size="small" />
                    <Paragraph>Webflow generates clean, semantic code that’s ready to publish or hand to developers.</Paragraph>
                  </View>
                  <View className={styles.blockLight}>
                    <img
                      className={styles.gridIcon}
                      src="./assets/images/logos/wordpress.png"
                    />
                    <Spacer size="small" />
                    <Spacer size="medium" />
                    <H6>Wordpress</H6>
                    <Spacer size="small" />
                    <Paragraph>In non-geek speak, WordPress is the easiest and most powerful blogging and website builder in existence today.</Paragraph>
                  </View>
                  <View className={styles.blockLight}>
                    <img
                      className={styles.gridIcon}
                      src="./assets/images/logos/shopify.png"
                    />
                    <Spacer size="small" />
                    <Spacer size="medium" />
                    <H6>Shopify</H6>
                    <Spacer size="small" />
                    <Paragraph>Shopify is a complete commerce platform that lets you start, grow, and manage a business.</Paragraph>
                  </View>
                </View>
                <View gap="medium" className={styles.blockContainer}>
                  <View className={styles.blockLight}>
                    <img
                      className={styles.gridIcon}
                      src={
                        "./assets/images/logos/" +
                        (darkMode ? "ghost_white" : "ghost") +
                        ".png"
                      }
                    />
                    <Spacer size="small" />
                    <Spacer size="medium" />
                    <H6>Ghost</H6>
                    <Spacer size="small" />
                    <Paragraph>Ghost is a powerful app for new-media creators to publish, share, and grow a business around their content.</Paragraph>
                  </View>
                  <View className={styles.blockLight}>
                    <Webhook
                      stroke="var(--neutral-100)"
                      className={styles.gridIcon}
                    />
                    <Spacer size="small" />
                    <Spacer size="medium" />
                    <H6>Webhook</H6>
                    <Spacer size="small" />
                    <Paragraph>If you have a custom blog, you can set up an endpoint to which we will send your article(s) when you export them.</Paragraph>
                  </View>
                </View>
              </View>
            </View>
            <Spacer size="medium" />
            <Spacer size="large" />
            <Spacer size="large" />
            <Spacer size="large" />
            <View
              vertical
              centerVertically
              centerHorizontally
              className={styles.integrationsContainer}
            >
              <View className={styles.integrationsBackground} />
              <ShieldQuestion
                color="var(--neutral-100)"
                className={styles.heartHandshake}
                size="48"
              />
              <Spacer size="large" />
              <H5 className={styles.subtitle}>
                Frequently asked questions
              </H5>
              <Spacer size="medium" />
              <Paragraph center size="big" className={styles.secondaryColor}>
                Have a different question? <Link shiny onClick={() => {
                  window.location.href =
                    "mailto:contact@draftsai.com?subject=Regarding draftsai.com";
                }}>Reach out</Link>.
              </Paragraph>
              <Spacer size="large" />
              <Spacer size="medium" />
              <View vertical>
                <Accordion allowMultiple allowToggle>
                  <AccordionItem className={styles.accordionItem}>
                    <h2 className={styles.accordionTitle}>
                      <AccordionButton className={styles.accordionButton}>
                        <Box as="span" flex='1' textAlign='left'>
                          Is Drafts AI free?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel className={styles.accordionPanel} pb={4}>
                      <Paragraph>We offer a free plan which allows you to create and publish AI-generated a small number of articles per month. To learn more about our different plans, you can visit billing by <Link to="/app/billing">clicking here</Link>.</Paragraph>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.accordionItem}>
                    <h2 className={styles.accordionTitle}>
                      <AccordionButton className={styles.accordionButton}>
                        <Box as="span" flex='1' textAlign='left'>
                          Will Google punish my blog or website for publishing AI-generated content?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel className={styles.accordionPanel} pb={4}>
                      <Paragraph>As long as you make sure the sole purpose of the content isn't to manipulate SERP and the content isn't considered spam, you're fine! Google doesn't punish AI generated content. It's not about who wrote it, it's about the information.</Paragraph>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </View>
            </View>
            <Spacer size="large" />
            <Spacer size="large" />
            <Spacer size="large" />
            <Spacer size="small" />
            <CTA />
          </View>
        </View>
      </View>
    </View>
  );
}
