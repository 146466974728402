import { useApolloClient } from "@apollo/client";
import { Select } from "@chakra-ui/react";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { ADD_REPORT, UPDATE_REPORT } from "../../../graphql/mutations/Reports";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import Link from "../../links/Link";
import RadioCellContainer from "../../radiocell/RadioCellContainer";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/NewSiteModal.module.css";
import Modal from "../Modal";
import LoadingModal from "./LoadingModal";
import { AlertContext } from "../../../context/AlertProvider";
import * as XLSX from 'xlsx';
import { UPDATE_CREDITS } from "../../../graphql/mutations/Users";
import TextArea from "../../input/TextArea";

export default function NewReportModal(props) {
  const {
    articlesToGenerate,
    setArticlesToGenerate,
    articlesGenerated,
    setArticlesGenerated,
    setUpdate,
    keywords,
    setKeywords
  } = useContext(ConfigContext);

  const { isOverlayVisible, closeOverlay, openOverlay } =
    useContext(OverlayContext);

  const client = useApolloClient();

  const { userDetails } = useContext(StorageContext);

  const [name, setName] = useState("");

  const [reportSize, setReportSize] = useState("");

  const [language, setLanguage] = useState("en");

  const [instruction, setInstruction] = useState("");

  const [reportType, setReportType] = useState(null);

  const [report, setReport] = useState(null);

  const [errors, setErrors] = useState([]);

  const [uploading, setUploading] = useState(false);

  const fileSelectionRef = useRef();

  const { showAlert } = useContext(AlertContext);

  const scrollRef = useRef();

  const [reportTypeData, setReportTypeData] = useState({
    questions: [
      {
        id: 1,
        questionType: 1,
        questionTitle: null,
        questionSubtitle: null,
        question: null,
        unique: true,
        preventUnselect: true,
        hideButton: true,
        options: [
          {
            id: 1,
            title: "Keywords",
            subtitle: "I would like to use keywords",
            icon: "key",
          },
          {
            id: 2,
            title: "Toplist",
            subtitle: "I would like to create toplists",
            icon: "list",
          },
        ],
      },
    ],
  });

  const [reportTypeData2, setReportTypeData2] = useState({
    questions: [
      {
        id: 1,
        questionType: 1,
        questionTitle: null,
        questionSubtitle: null,
        question: null,
        unique: true,
        preventUnselect: true,
        hideButton: true,
        options: [
          {
            id: 1,
            title: "Keywords",
            subtitle: "I would like to use keywords",
            icon: "key",
          },
          {
            id: 2,
            title: "Toplist",
            subtitle: "I would like to create toplists",
            icon: "list",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    if (!isOverlayVisible) {
      setReport(null);
      setName("");
      setKeywords([]);
      setReportType(null);
      setReportSize("");
      setInstruction("");
      setLanguage("en");
      setErrors([]);
    } else {
      if (props.report) {
        setReport(props.report);
        setName(props.report.name);
        setLanguage(props.report.language);
        setReportType(props.report.reportType);
        setKeywords(JSON.parse(props.report.keywords));
        setInstruction(props.report.instruction);
        console.log("2set keywords to", props.report.keywords);
      }
    }
  }, [isOverlayVisible, props.report]);

  function getErrorWithType(typeToCheck) {
    return errors.find((error) => error.type === typeToCheck);
  }

  function removeErrorWithType(typeToRemove) {
    const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
    setErrors(updatedErrors);
  }

  function handleReportChange() {
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "You need to enter a name.",
        },
      ]);
      prevent = true;
    }
    if (!props.report && (reportSize.length === 0 || reportSize <= 0)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 4,
          message: "You need to enter a valid report size.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    setTimeout(() => {
      addReport();
    }, 500);
  }

  function editReport() {
    let prevent = false;
    if (name.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "You need to enter a name.",
        },
      ]);
      prevent = true;
    }
    if (!props.report && (reportSize.length === 0 || reportSize <= 0)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 4,
          message: "You need to enter a valid report size.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    setTimeout(() => {
      updateReport();
    }, 500);
  }

  async function addReport() {
    if (!userDetails.credits || userDetails.credits < reportSize) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 10,
          message: "You don't have enough credits to add a new article.",
        },
      ]);
      setUpdate(true);
      return;
    }
    userDetails.credits -= reportSize;
    await client
      .mutate({
        mutation: UPDATE_CREDITS,
        variables: {
          email: userDetails.email,
          credits: userDetails.credits,
        },
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    const report = {
      reportId: -1,
      siteId: props.site.siteId,
      name,
      status: "Ready",
      language,
      reportType: reportType || 1,
      keywords: JSON.stringify(keywords),
      instruction,
      archived: false,
    };
    const response = await client
      .query({
        query: ADD_REPORT,
        variables: report,
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    setUpdate(true);
    if (response && response.data && response.data.addReport) {
      setArticlesGenerated(0);
      setArticlesToGenerate(reportSize);
      closeOverlay();
      setName("");
      setKeywords([]);
      setErrors([]);
      props.addReport(response.data.addReport);
      props.setReport(response.data.addReport);
      openOverlay(
        <Modal>
          <LoadingModal />
        </Modal>
      );
      await props.getArticles(response.data.addReport, reportSize);
      setArticlesGenerated(reportSize);
      setTimeout(() => {
        props.setIsExploding(true);
        closeOverlay();
        showAlert(reportSize + " articles drafted successfully.", "success");
      }, 1000);
    }
  }

  function isURL(str) {
    const urlPattern = /^(?:https?:\/\/)?(?:www\.)?[^\s.]+\.\S{2,}$/i;
    return urlPattern.test(str);
  }

  async function updateReport() {
    const response = await client
      .query({
        query: UPDATE_REPORT,
        variables: {
          reportId: report.reportId,
          name,
          reportType,
          keywords: JSON.stringify(keywords),
          language,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    setUpdate(true);
    if (response && response.data && response.data.updateReport) {
      closeOverlay();
      setName("");
      setKeywords([]);
      setReportType(null);
      setErrors([]);
      props.updateReport(response.data.updateReport);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        const newKeywords = sheetData.map((row) => row['Keyword']);
        setKeywords((prevKeywords) => [...prevKeywords, ...newKeywords]);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  function onSelectCallback(section, type) {
    setReportType(section);
  }

  function KeywordList(props) {

    const [value, setValue] = useState("");

    function addKeyword(keyword) {
      if (keywords && keywords !== null && keywords.length > 0) {
        setKeywords((prevKeywords) => [...prevKeywords, keyword]);
      } else {
        setKeywords([keyword]);
      }
    }

    function removeKeyword(keyword) {
      setKeywords((prevKeywords) => prevKeywords.filter((k) => k !== keyword));
    }

    function editKeyword(index, newValue) {
      setKeywords((prevKeywords) => {
        const updatedKeywords = [...prevKeywords];
        updatedKeywords[index] = newValue;
        return updatedKeywords;
      });
    }

    return (
      <View vertical>
        <Spacer size="medium" />
        <Spacer size="small" />
        <Paragraph>or, enter them manually:</Paragraph>
        <Spacer size="medium" />
        <View vertical gap="medium">
          {keywords != null && keywords.map((keyword, index) => {
            return (
              <View centerVertically key={index}>
                <TextInput
                  value={keyword}
                  onChange={(e) => editKeyword(index, e.target.value)}
                  style={{ width: "250px" }}
                  size="small"
                  placeholder="Enter a keyword"
                />
                <Spacer size="medium" />
                <Link size="small" onClick={() => removeKeyword(keyword)}>
                  Remove
                </Link>
              </View>
            );
          })}
          <TextInput
            onChange={(e) => setValue(e.target.value)}
            value={value}
            style={{ width: "250px" }}
            size="small"
            placeholder="Enter a keyword"
          />
        </View>
        <Spacer size="medium" />
        <PrimaryButton
          onClick={() => {
            if (value) {
              addKeyword(value);
              setValue("");
            }
          }}
          size="small"
        >
          Add keyword
        </PrimaryButton>
      </View>
    );
  }

  return (
    <View vertical className={styles.main}>
      <View ref={scrollRef} className={styles.paddedContainer} vertical>
        <TextInput
          value={report === null ? "Create report" : "Edit " + report.name}
          className={styles.cardTitle}
        />
        <Spacer size="medium" />
        <Paragraph>
          Write SERP optimized articles for keywords or top list product review
          articles for specified links.
        </Paragraph>
        <Spacer size="large" />
        <H6>
          Subject
          <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          What subject are you creating a report on?
        </Paragraph>
        <Spacer size="medium" />
        <TextInput
          size="small"
          value={name}
          onChange={(e) => {
            removeErrorWithType(2);
            setName(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            getErrorWithType(2) && styles.inputError
          }`}
          placeholder="E.g. 'Tesla'"
        />
        {getErrorWithType(2) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(2).message}
          </Paragraph>
        )}
        <Spacer size="large" />
        <H6>
          Language
          <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          Select the language in which you want the report written in
        </Paragraph>
        <Spacer size="medium" />
        <Select
          color="var(--neutral-100)"
          className={styles.select}
          value={language}
          placeholder="🇺🇸 English"
          onChange={(event) => setLanguage(event.target.value)}
        >
          <option value="es">🇪🇸 Spanish</option>
          <option value="fr">🇫🇷 French</option>
          <option value="de">🇩🇪 German</option>
          <option value="it">🇮🇹 Italian</option>
          <option value="pt">🇵🇹 Portuguese</option>
          <option value="zh">🇨🇳 Chinese (Simplified)</option>
          <option value="ja">🇯🇵 Japanese</option>
          <option value="ko">🇰🇷 Korean</option>
          <option value="ar">🇸🇦 Arabic</option>
          <option value="ru">🇷🇺 Russian</option>
          <option value="hi">🇮🇳 Hindi</option>
          <option value="tr">🇹🇷 Turkish</option>
          <option value="nl">🇳🇱 Dutch</option>
          <option value="sv">🇸🇪 Swedish</option>
          <option value="fi">🇫🇮 Finnish</option>
          <option value="pl">🇵🇱 Polish</option>
          <option value="uk">🇺🇦 Ukrainian</option>
          <option value="no">🇳🇴 Norwegian</option>
          <option value="da">🇩🇰 Danish</option>
          <option value="el">🇬🇷 Greek</option>
          <option value="cs">🇨🇿 Czech</option>
          <option value="hu">🇭🇺 Hungarian</option>
          <option value="ro">🇷🇴 Romanian</option>
          <option value="he">🇮🇱 Hebrew</option>
          <option value="id">🇮🇩 Indonesian</option>
          <option value="ms">🇲🇾 Malay</option>
          <option value="th">🇹🇭 Thai</option>
          <option value="vi">🇻🇳 Vietnamese</option>
          <option value="tl">🇵🇭 Tagalog</option>
          <option value="pt-br">🇧🇷 Portuguese (Brazilian)</option>
          <option value="es-mx">🇲🇽 Spanish (Mexican)</option>
          <option value="fr-ca">🇨🇦 French (Canadian)</option>
          <option value="en-au">🇦🇺 English (Australian)</option>
          <option value="en-in">🇮🇳 English (Indian)</option>
          <option value="en-uk">🇬🇧 English (UK)</option>
          <option value="en-ca">🇨🇦 English (Canadian)</option>
          <option value="en-za">🇿🇦 English (South African)</option>
          <option value="en-nz">🇳🇿 English (New Zealand)</option>
          <option value="en-ie">🇮🇪 English (Irish)</option>
          <option value="en-sg">🇸🇬 English (Singaporean)</option>
          <option value="af">🇿🇦 Afrikaans</option>
          <option value="am">🇪🇹 Amharic</option>
          <option value="az">🇦🇿 Azerbaijani</option>
          <option value="be">🇧🇾 Belarusian</option>
          <option value="bg">🇧🇬 Bulgarian</option>
          <option value="bn">🇧🇩 Bengali</option>
          <option value="bs">🇧🇦 Bosnian</option>
          <option value="ca">🇨🇦 Catalan</option>
          <option value="ceb">🇵🇭 Cebuano</option>
          <option value="cy">🇬🇧 Welsh</option>
          <option value="eo">🌍 Esperanto</option>
          <option value="et">🇪🇪 Estonian</option>
          <option value="eu">🇪🇸 Basque</option>
          <option value="fa">🇮🇷 Persian</option>
          <option value="fj">🇫🇯 Fijian</option>
          <option value="ga">🇮🇪 Irish</option>
          <option value="gd">🏴󠁧󠁢󠁳󠁣󠁴󠁿 Scottish Gaelic</option>
          <option value="gl">🇪🇸 Galician</option>
          <option value="gu">🇮🇳 Gujarati</option>
          <option value="ha">🇳🇬 Hausa</option>
          <option value="haw">🌺 Hawaiian</option>
          <option value="ht">🇭🇹 Haitian Creole</option>
          <option value="hy">🇦🇲 Armenian</option>
          <option value="ig">🇳🇬 Igbo</option>
          <option value="is">🇮🇸 Icelandic</option>
          <option value="jv">🇮🇩 Javanese</option>
          <option value="ka">🇬🇪 Georgian</option>
          <option value="kk">🇰🇿 Kazakh</option>
          <option value="km">🇰🇭 Khmer</option>
          <option value="ku">🇮🇶 Kurdish</option>
          <option value="ky">🇰🇬 Kyrgyz</option>
          <option value="lb">🇱🇺 Luxembourgish</option>
          <option value="lt">🇱🇹 Lithuanian</option>
          <option value="lv">🇱🇻 Latvian</option>
          <option value="mg">🇲🇬 Malagasy</option>
          <option value="mi">🇳🇿 Maori</option>
          <option value="mk">🇲🇰 Macedonian</option>
          <option value="ml">🇮🇳 Malayalam</option>
          <option value="mn">🇲🇳 Mongolian</option>
          <option value="mr">🇮🇳 Marathi</option>
          <option value="my">🇲🇲 Burmese</option>
        </Select>
        <Spacer size="large" />
        <H6>
          Report Size
          <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          How many articles would you like to draft?
        </Paragraph>
        <Spacer size="medium" />
        <TextInput
          min="1"
          max="2500"
          size="small"
          type="number"
          value={reportSize}
          onChange={(e) => {
            removeErrorWithType(4);
            setReportSize(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            props.report && styles.disabledInput
          } ${getErrorWithType(4) && styles.inputError} ${styles.fullWidth} `}
          placeholder="E.g. '10'"
        />
        {getErrorWithType(4) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(4).message}
          </Paragraph>
        )}
        <Spacer size="large" />
        <H6>
          Instruction (optional)
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          Instruct our AI to include or exclude certain information.
        </Paragraph>
        <Spacer size="medium" />
        <TextArea
          size="small"
          type="text"
          value={instruction}
          onChange={(e) => {
            removeErrorWithType(14);
            setInstruction(e.target.value);
          }}
          className={`${styles.fullWidth} ${
            props.report && styles.disabledInput
          } ${getErrorWithType(14) && styles.inputError} ${styles.fullWidth} `}
          placeholder={"E.g. 'Include information about the history of the topic'"}
        />
        {getErrorWithType(14) && (
          <Paragraph
            style={{ marginTop: "8px", color: "var(--red)" }}
            size="small"
          >
            {getErrorWithType(14).message}
          </Paragraph>
        )}
        {/*<Spacer size="large" />
        <H6>
          Report Type
          <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
        </H6>
        <Spacer size="small" />
        <Paragraph size="small">
          Select the type of report you would like to create
        </Paragraph>
        <Spacer size="medium" />
        {reportType !== null && (
          <RadioCellContainer
            selected={reportType}
            onSelectCallback={onSelectCallback}
            rows={3}
            type="background"
            data={reportTypeData}
          />
        )}
        {reportType === null && (
          <RadioCellContainer
            selected={1}
            onSelectCallback={onSelectCallback}
            rows={3}
            type="background2"
            data={reportTypeData2}
          />
        )}*/}
        {(reportType === null || reportType === 1) && (
          <>
            <Spacer size="large" />
            <H6>
              Keywords
              <span style={{ color: "var(--red)", marginLeft: "5px" }}>*</span>
            </H6>
            <Spacer size="small" />
            <Paragraph size="small">
              To download an example file, <Link to="https://docs.google.com/spreadsheets/d/1_LUUPY2sunLzMFSsu0EbleWVr_L4UmL-u4-2oyXZcn4/edit?usp=sharing" target="_blank">click here</Link>.
            </Paragraph>
            <Spacer size="medium" />
            <View
              style={{ pointerEvents: "none" }}
              centerVertically
              centerHorizontally
              vertical
              className={styles.dashed}
            >
              <input
                onChange={handleFileChange}
                ref={fileSelectionRef}
                className={styles.absoluteInput}
                type="file"
                accept=".xlsx,.xls"
              />
              {uploading ? (
                <View gap="small" vertical centerVertically centerHorizontally>
                  <Oval
                    height={24}
                    width={24}
                    color="var(--neutral-400)"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="var(--neutral-800)"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </View>
              ) : (
                <View vertical centerVertically centerHorizontally>
                  <TableCellsIcon
                    className={styles.spreadsheetIcon}
                    size="55"
                    color="var(--neutral-500)"
                  />
                  <Spacer size="medium" />
                  <Spacer size="small" />
                  <View
                    style={{ pointerEvents: "none" }}
                    centerHorizontally
                    centerVertically
                    gap="medium"
                  >
                    <View
                      vertical
                      centerVertically
                      centerHorizontally
                      className={styles.relativeContainer}
                    >
                      <H6 className={styles.smallTitle}>
                        Import from spreadsheet
                      </H6>
                    </View>
                  </View>
                </View>
              )}
            </View>
            <KeywordList />
          </>
        )}
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
      {getErrorWithType(10) && (
          <Paragraph
            style={{ minWidth: '400px', width: "100%", color: "var(--red)" }}
            size="small"
          >
            You don't have enough credits for this report.{" "}
            <Link
              size="small"
              onClick={() => {
                closeOverlay();
                props.handleTabChange({
                  id: "billing",
                  name: "Billing",
                });
              }}
            >
              Go to billing
            </Link>
          </Paragraph>
        )}
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={report === null ? handleReportChange : editReport}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            {report === null ? "Create report" : "Save " + report.name}
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
