import React, { useContext } from "react";
import Link from "../links/Link";
import H5 from "../text/heading/H5";
import View from "../view/View";
import styles from "./styles/Logo.module.css";
import { Feather } from "lucide-react";
import { ConfigContext } from "../../context/ConfigProvider";

export default function Logo(props) {

  const { darkMode } = useContext(ConfigContext);

  return (
    <View
      style={props.style}
      className={`${styles.logoContainer} ${
        props.size === "small" && styles.smallLogoContainer
      } ${props.className}`}
    >
      <Link className={styles.link} to="/">
        <View centerHorizontally className={styles.logo}>
          <img
            className={`${styles.logo} ${
              props.size === "small" && styles.smallIcon
            }`}
            src={
              "https://draftsai.com/assets/images/" + (props.inverted === true && !props.forceInvert || (darkMode && !props.forceInvert) ? "logo_white" : "logo") + ".png"
            }
          />
        </View>
      </Link>
    </View>
  );
}
