import React from "react";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import styles from "./styles/BlogPopupModal.module.css";
import H5 from "../../text/heading/H5";
import PrimaryButton from "../../buttons/PrimaryButton";
import Spacer from "../../view/impl/Spacer";
import Button from "../../buttons/Button";
import { ArrowLeft, ArrowRight } from "react-feather";

export default function BlogPopupModal(props) {
  return (
    <View vertical className={styles.blueContainer}>
      <View className={styles.paddedContainer} vertical>
        <H5 className={styles.heading}>
          Write and publish hundreds of SEPR optimized articles in <span className={styles.strike}>months</span> <span className={styles.seconds}>seconds</span> with <span className={styles.seconds}>Drafts AI</span>
        </H5>
        <Paragraph center size="big" className={styles.messageText}>
        Upload your list of target keywords and receive hundreds of high-quality articles within seconds.
        </Paragraph>
        <Spacer size="small" />
        <Spacer size="medium" />
        <View centerVertically centerHorizontally>
        <PrimaryButton onClick={() => {
            window.location.href = "/join";
            }} size="small" className={styles.whiteButton}>Get started</PrimaryButton>
        <Button onClick={() => {
            window.location.href = "/app";
            }} className={styles.whiteTextButton} size="small">Live demo <ArrowRight size="15" /></Button>
        </View>
        <Spacer size="small" />
        <Spacer size="medium" />
        <View centerVertically centerHorizontally>
        <Paragraph className={styles.whiteMessage} center size="small">(click anywhere to dismiss this popup)</Paragraph>
        </View>
      </View>
    </View>
  );
}
