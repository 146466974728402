import { useApolloClient } from "@apollo/client";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";
import { EmailContext } from "../../context/EmailProvider";
import { StorageContext } from "../../context/StorageProvider";
import { SOFT_DELETE } from "../../graphql/mutations/Users";
import {
  AUTH_USER,
  GET_USER_BY_EMAIL
} from "../../graphql/queries/Users";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import Container from "../navigation/Container";
import H5 from "../text/heading/H5";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Separator from "../view/impl/Separator";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Login.module.css";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login(props) {
  const { setUserDetails } = useContext(StorageContext);

  const { sendEmail } = useContext(EmailContext);

  const client = useApolloClient();

  const [errors, setErrors] = useState([]);

  function getErrorWithType(typeToCheck) {
    return errors.find((error) => error.type === typeToCheck);
  }

  function removeErrorWithType(typeToRemove) {
    const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
    setErrors(updatedErrors);
  }

  const { update, setUpdate } = useContext(ConfigContext);

  const [emailInput, setEmailInput] = useState("");

  const [capturedEmail, setCapturedEmail] = useState("");

  const [passwordInput, setPasswordInput] = useState("");

  const [withPassword, setWithPassword] = useState(false);

  const [linkSent, setLinkSent] = useState(false);

  const [passwordLinkSent, setPasswordLinkSent] = useState(false);

  const navigate = useNavigate();

  const { darkMode } = useContext(ConfigContext);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    document.title = "Sign in to your Drafts account – No password needed";
  }, []);

  async function setPassword() {
    await sendEmail({
      from: "noreply@draftsai.com",
      fromName: "Drafts AI (no-reply)",
      to: capturedEmail,
      subject: "Update your Drafts AI password",
      template: "set-password",
      templateParams: {
        hash: "sha256",
        sessionEmail: capturedEmail // Passing this will tie the hash to the email.
      },
    });
    removeErrorWithType(3);
    setPasswordLinkSent(true);
  }

  async function signIn() {
    setPasswordLinkSent(false);
    setErrors([]);
    let prevent = false;
    if (emailInput.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "Please enter your email address.",
        },
      ]);
      prevent = true;
    }
    if (!validateEmail(emailInput)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "Please enter a valid email address.",
        },
      ]);
      prevent = true;
    }
    if (withPassword && passwordInput.length === 0) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "Please enter your password.",
        },
      ]);
      prevent = true;
    }
    if (withPassword && passwordInput.length < 8) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "Please enter a password of at least 8 characters.",
        },
      ]);
      prevent = true;
    }
    if (withPassword && passwordInput.length > 48) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 3,
          message: "Passwords do not exceed 48 characters.",
        },
      ]);
      prevent = true;
    }
    if (prevent) {
      setUpdate(true);
      return;
    }
    const response = await client
      .query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email: emailInput.toLowerCase(),
        },
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
        setUpdate(true);
        return errors;
      });

    if (response == null || response.data == null) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "We couldn't find your account.",
        },
      ]);
      setUpdate(true);
      return null;
    }
    const user = response.data.getUserByEmail;
    if (user === null) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "We couldn't find your account.",
        },
      ]);
      setUpdate(true);
      return null;
    }
    if (user.archived) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: (
            <View vertical gap="small">
              <Paragraph className={styles.errorText} size="small">
                Your account has been archived and will be permanently deleted
                within 30 days from the day you archived it.
              </Paragraph>
              <Button
                size="small"
                className={styles.noPadButton}
                onClick={() => restoreAccount(user.email)}
              >
                Click here to restore your account
              </Button>
            </View>
          ),
        },
      ]);
      setUpdate(true);
      return null;
    }
    if (!withPassword) {
      const emailResponse = await sendEmail({
        from: "noreply@draftsai.com",
        fromName: "Drafts AI (no-reply)",
        to: user.email,
        subject: "Login to Drafts AI",
        template: "login",
        templateParams: {
          name: user.name,
          hash: "sha256",
          sessionEmail: user.email, // Passing this will tie the hash to the email.
        },
      });
      setLinkSent(true);
      setUpdate(true);
      return emailResponse;
    } else {
      const response = await client
        .query({
          query: AUTH_USER,
          variables: {
            email: emailInput.toLowerCase(),
            password: passwordInput
          },
        })
        .catch((error) => {
          setCapturedEmail(emailInput);
          setErrors((prevErrors) => [
            ...prevErrors,
            {
              type: 3,
              message: (error.message === 'no_password' ? <span>There is no password tied to this email.<br></br>To set a password, <Link className={styles.redLink} onClick={setPassword}>click here</Link>.</span> : "The password you have entered is incorrect."),
            },
          ]);
          setUpdate(true);
          return errors;
        });
      if (!response || response === null || !response.data || response.data === null || !response.data.authUser || response.data.authUser === null) {
        return;
      }
      const user = response.data.authUser;
      if (user.archived) {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 2,
            message: (
              <View vertical gap="small">
                <Paragraph className={styles.errorText} size="small">
                  Your account has been archived and will be permanently deleted
                  within 30 days from the day you archived it.
                </Paragraph>
                <Button
                  size="small"
                  className={styles.noPadButton}
                  onClick={() => restoreAccount(user.email)}
                >
                  Click here to restore your account
                </Button>
              </View>
            ),
          },
        ]);
        setUpdate(true);
        return null;
      }
      setUserDetails(user);
      navigate("/app");
    }
  }

  async function restoreAccount(email) {
    let response = await client
      .mutate({
        mutation: SOFT_DELETE,
        variables: {
          email: email,
          archived: false,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 1,
            message: error.message,
          },
        ]);
      });
    if (response && response.data && response.data.updateUser) {
      setErrors([]);
      await sendEmail({
        from: "noreply@draftsai.com",
        fromName: "Drafts AI (no-reply)",
        to: response.data.updateUser.email,
        subject: "Login to Drafts AI",
        template: "login",
        templateParams: {
          name: response.data.updateUser.name,
          hash: "sha256",
          sessionEmail: response.data.updateUser.email, // Passing this will tie the hash to the email.
        },
      });
      setLinkSent(true);
      setUpdate(true);
    }
  }

  const fetchUserEmail = async (accessToken) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const { email } = response.data;
      return email;

      // Now you have the user's email, you can fetch the user details from your database.
      // Your code to fetch user details goes here...
    } catch (error) {
      console.error("Failed to fetch user email:", error);
    }
  };

  async function signInWithGoogle(accessToken) {
    const response = await client
      .query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email: await fetchUserEmail(accessToken),
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setErrors((prevErrors) => [
          ...prevErrors,
          {
            type: 2,
            message: "We couldn't find your account.",
          },
        ]);
      });
    if (response && response.data && response.data.getUserByEmail) {
      const user = response.data.getUserByEmail;
      if (user !== null) {
        if (user.archived) {
          setErrors((prevErrors) => [
            ...prevErrors,
            {
              type: 2,
              message: (
                <View vertical gap="small">
                  <Paragraph className={styles.errorText} size="small">
                    Your account has been archived and will be permanently deleted
                    within 30 days from the day you archived it.
                  </Paragraph>
                  <Button
                    size="small"
                    className={styles.noPadButton}
                    onClick={() => restoreAccount(user.email)}
                  >
                    Click here to restore your account
                  </Button>
                </View>
              ),
            },
          ]);
        } else {
          setUserDetails(user);
          navigate("/app");
        }
      } else {
        navigate("/join");
      }
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      signInWithGoogle(response.access_token);
    },
    onError: (errorResponse) => {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          type: 2,
          message: "Something went wrong, please try again.",
        },
      ]);
      setUpdate(true);
    },
    onFailure: () => {
      setUpdate(true);
    },
  });

  useEffect(() => {
    if (linkSent === true) {
      setTimeout(() => {
        setLinkSent(false);
      }, 5000)
    }
  }, [linkSent])

  useEffect(() => {
    if (passwordLinkSent === true) {
      setTimeout(() => {
        setPasswordLinkSent(false);
      }, 5000)
    }
  }, [passwordLinkSent])

  return (
    <Container className={`${styles.container} ${darkMode && styles.darkContainer}`}>
      <Helmet>
        <link rel="canonical" href="https://draftsai.com/login" />
      </Helmet>
      <View className={styles.loginContainer}>
        <View className={styles.loginWrapper}>
          <H5 className={styles.title}>{"Welcome back 👋"}</H5>
          <Spacer size="medium" />
          <Paragraph size="small">
            Don't have an account? <Link shiny to="/join">Sign up</Link>
          </Paragraph>
          <Spacer size="large" />
          <div className={styles.form}>
            <H6 className={styles.inputTitle}>Email</H6>
            <Spacer size="medium" />
            <TextInput
              size="medium"
              value={emailInput}
              onChange={(e) => {
                setPasswordLinkSent(false);
                removeErrorWithType(2);
                setEmailInput(e.target.value);
              }}
              className={`${styles.input} ${getErrorWithType(2) && styles.inputError
                }`}
              placeholder="Your work-email"
            />
            {getErrorWithType(2) && (
              <Paragraph
                style={{ marginTop: "8px", color: "var(--red)" }}
                size="small"
              >
                {getErrorWithType(2).message}
              </Paragraph>
            )}
            <Spacer size="large" />
            {
              withPassword ? (
                <>
                  <H6 className={styles.inputTitle}>Password</H6>
                  <Spacer size="medium" />
                  <TextInput
                    type="password"
                    size="medium"
                    value={passwordInput}
                    onChange={(e) => {
                      setPasswordLinkSent(false);
                      removeErrorWithType(3);
                      setPasswordInput(e.target.value);
                    }}
                    className={`${styles.input} ${getErrorWithType(3) && !passwordLinkSent && styles.inputError
                      }`}
                    placeholder="Your password"
                  />
                  {passwordLinkSent ? (
                    <Paragraph
                      style={{ marginTop: "30px", color: "var(--neutral-100)" }}
                      size="small"
                    >
                      A magic link has been sent to your email.
                    </Paragraph>
                  ) : null}
                  {getErrorWithType(3) ? <Paragraph
                    style={{ marginTop: "8px", color: "var(--red)" }}
                    size="small"
                  >
                    {getErrorWithType(3).message}
                  </Paragraph> : null}
                  <Spacer size="large" />
                </>
              ) : null
            }
            {
              /*
              <ReCAPTCHA
              sitekey="6Le3K2YoAAAAAE8XtrCnUqxxDWH46rcQk3-zIIRX"
            />
              */
            }
            <div>
              {
                linkSent ? (
                  <PrimaryButton
                    disabled={!withPassword}
                    size="medium"
                    spinner
                    onClick={signIn}
                    className={styles.button}
                  >
                    {withPassword ? "Sign in" : <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>A magic link has been sent
                      link</span>}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    size="medium"
                    spinner
                    onClick={signIn}
                    className={styles.button}
                  >
                    {withPassword ? "Sign in" : <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Send me a magic
                      link</span>}
                  </PrimaryButton>
                )
              }
              <Spacer size="medium" />
              <Button
                size="medium"
                onClick={() => {
                  setErrors([]);
                  setWithPassword(!withPassword)
                }}
                className={styles.button}
              >
                {withPassword ? "Sign in using email link" : "Sign in using password"}
              </Button>
              <Spacer size="medium" />
              <View className={styles.separatorContainer}>
                <Separator className={styles.separator}></Separator>
                <Paragraph size="small">or</Paragraph>
                <Separator className={styles.separator}></Separator>
              </View>
              <Spacer size="medium" />
              <View vertical gap="small">
                <SecondaryButton
                  disabled={true}
                  size="medium"
                  onClick={googleLogin}
                  className={styles.button}
                >
                  <img
                    className={styles.googleIcon}
                    src={"https://draftsai.com/assets/images/google.png"}
                  />
                  Sign in with Google
                </SecondaryButton>
              </View>
            </div>
          </div>
        </View>
      </View>
    </Container>
  );
}
