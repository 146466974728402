import { gql } from "@apollo/client";

export const ADD_ARTICLE = gql`
  mutation AddArticle(
    $articleId: Int!
    $reportId: Int!
    $name: String!
    $content: String!
    $instruction: String!
    $archived: Boolean!
  ) {
    addArticle(
      input: {
        articleId: $articleId
        reportId: $reportId
        name: $name
        content: $content
        instruction: $instruction
        archived: $archived
      }
    ) {
      articleId
      reportId
      name
      content
      instruction
      archived
    }
  }
`;

export const ADD_ARTICLES = gql`
  mutation AddArticles($articles: [ArticleInput!]!) {
    addArticles(inputs: $articles) {
      articleId
      reportId
      name
      content
      instruction
      archived
    }
  }
`;

export const ARCHIVE_ARTICLE = gql`
  mutation ArchiveArticle($articleId: Int!) {
    archiveArticle(input: { articleId: $articleId }) {
      articleId
      reportId
      name
      content
      instruction
      archived
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle($articleId: Int!, $name: String!, $content: String!) {
    updateArticle(input: { articleId: $articleId, name: $name, content: $content }) {
      articleId
      reportId
      name
      content
      instruction
      archived
    }
  }
`;