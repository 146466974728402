import { gql } from "@apollo/client";

export const GET_SITES_BY_OWNER = gql`
  query getSitesByOwner($owner: String!) {
    getSitesByOwner(owner: $owner) {
      siteId
      owner
      name
      uri
      integrationType
      webflowToken
      wordpressUrl
      wordpressUser
      wordpressToken
      shopifyUrl
      shopifyToken
      ghostUrl
      ghostToken
      webhookUrl
      webhookToken
    }
  }
`;