import React, { useContext, useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import View from "../view/View";
import styles from "./styles/MobileMenu.module.css";
import navStyles from "./styles/Nav.module.css";
import { useLocation } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";
import H5 from "../text/heading/H5";
import Logo from "./Logo";
import Spacer from "../view/impl/Spacer";
import { StorageContext } from "../../context/StorageProvider";

export default function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const { darkMode } = useContext(ConfigContext);

  const { userDetails } = useContext(StorageContext);

  useEffect(() => {
    const lines = document.querySelectorAll(`.${styles.line}`);
    const menu = document.querySelectorAll(`.${styles.mobileMenu}`)[0];
    if (menuOpen) {
      lines.forEach((line) => line.classList.add(styles.animateNavLine));
      menu.classList.add(styles.openMobileNavMenu);
    } else {
      lines.forEach((line) => line.classList.remove(styles.animateNavLine));
      menu.classList.remove(styles.openMobileNavMenu);
    }
  }, [menuOpen]);

  const location = useLocation();

  return (
    <View className={styles.mobileButtonContainer}>
      <Button
        onClick={() => setMenuOpen(!menuOpen)}
        className={styles.mobileButton}
      >
        <View className={styles.line}></View>
        <View className={styles.line}></View>
      </Button>
      <View className={styles.mobileMenu}>
        <View className={styles.mobileMenuWrapper}>
          <View className={`${navStyles.fixedLogo}`}>
            <Logo inverted />
          </View>
          <Button
            onClick={() => setMenuOpen(!menuOpen)}
            className={`${styles.mobileButton} ${styles.fixedMobileButton}`}
          >
            <View className={styles.line}></View>
            <View className={styles.line}></View>
          </Button>
          {location.pathname === "/user" ? (
            <View className={styles.mobileNavButtonContainer}>
              <PrimaryButton
                size="small"
                onClick={() => setMenuOpen(false)}
                bright
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/login"
              >
                Sign up for free
              </PrimaryButton>
              <View centerHorizontally centerVertically>
                <PrimaryButton
                  size="small"
                  onClick={() => setMenuOpen(false)}
                  linkClassName={styles.maxWidthButton}
                  className={styles.ctaButton}
                  to="/"
                >
                  What's drafts?
                </PrimaryButton>
                <PrimaryButton
                  size="small"
                  onClick={() => setMenuOpen(false)}
                  linkClassName={styles.maxWidthButton}
                  className={styles.ctaButton}
                  to="/for-business"
                >
                  Log In
                </PrimaryButton>
              </View>
            </View>
          ) : (
            <View className={styles.mobileNavButtonContainer}>
              <Button
                size="small"
                onClick={() => setMenuOpen(false)}
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/app"
              >
                {userDetails === null ? "Live Demo" : "Dashboard"}
              </Button>
              {
                userDetails === null ? (
                  <Button
                size="small"
                onClick={() => setMenuOpen(false)}
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/login"
              >
                Sign In
              </Button>
                ) : (
                  <Button
                size="small"
                onClick={() => setMenuOpen(false)}
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/app/billing"
              >
                Billing
              </Button>
                )
              }
              {
                userDetails === null ? (
                  <Button
                size="small"
                onClick={() => setMenuOpen(false)}
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/join"
              >
                Get Started
              </Button>
                ) : (
                  <Button
                size="small"
                onClick={() => setMenuOpen(false)}
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/app/tutorials"
              >
                Tutorials
              </Button>
                )
              }
              <Button
                size="small"
                onClick={() => {
                  window.location.href =
                    "mailto:contact@draftsai.com?subject=Regarding draftsai.com";
                }}
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/join"
              >
                Contact Us
              </Button>
            </View>
          )}
                        <Spacer size="medium" />

        </View>
      </View>
    </View>
  );
}
