import { gql } from "@apollo/client";

export const ADD_PROSPECTS = gql`
  mutation AddProspects($prospects: [ProspectInput!]!) {
    addProspects(inputs: $prospects) {
      prospectId
      name
      email
      prospectedBy
      optedOut
    }
  }
`;

export const UNSUBSCRIBE = gql`
  mutation Unsubscribe($email: String!) {
    unsubscribe(input: { email: $email }) {
      email
    }
  }
`;

export const SET_PROSPECT_STAGE = gql`
  mutation SetProspectStage($prospectId: Int!, $stage: Int!) {
    setProspectStage(input: { prospectId: $prospectId, stage: $stage }) {
      email
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser(
    $name: String!
    $email: String!
    $type: Int!
    $verification: Int!
    $link: String!
    $appearanceSettings: String!
    $password: String!
  ) {
    addUser(
      input: {
        name: $name
        email: $email
        type: $type
        verification: $verification
        link: $link
        appearanceSettings: $appearanceSettings
        password: $password
      }
    ) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($email: String!, $hashed: String!, $hash: String!) {
    updateUser(input: { email: $email, hashed: $hashed, hash: $hash }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdateUser($email: String!, $password: String!) {
    updateUser(input: { email: $email, password: $password }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_CREDITS = gql`
  mutation UpdateUser($email: String!, $credits: Int!) {
    updateUser(input: { email: $email, credits: $credits}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation UpdateUser($email: String!, $name: String!) {
    updateUser(input: { email: $email, name: $name }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_BIO = gql`
  mutation UpdateUser($email: String!, $bio: String!) {
    updateUser(input: { email: $email, bio: $bio }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_PICTURE = gql`
  mutation UpdatePicture($email: String!, $picture: String!) {
    updateUser(input: { email: $email, picture: $picture }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_HEADER_IMAGE = gql`
  mutation UpdatePicture($email: String!, $headerImage: String!) {
    updateUser(input: { email: $email, headerImage: $headerImage }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($email: String!, $name: String!, $link: String!, $bio: String!, $location: String!, $picture: String!, $githubToken: String!) {
    updateUser(input: { email: $email, name: $name, link: $link, bio: $bio, location: $location, picture: $picture, githubToken: $githubToken}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const PUBLISH_SECTIONS = gql`
  mutation UpdateUser($email: String!, $published: String!) {
    updateUser(input: { email: $email, published: $published}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const VERIFY = gql`
  mutation UpdateUser($email: String!, $verification: Int!) {
    updateUser(input: { email: $email, verification: $verification}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const SET_USER_PRIVACY = gql`
  mutation UpdateUser($email: String!, $private: Boolean!) {
    updateUser(input: { email: $email, private: $private}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const SOFT_DELETE = gql`
  mutation UpdateUser($email: String!, $archived: Boolean!) {
    updateUser(input: { email: $email, archived: $archived}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const SOFT_DELETE_PROSPECT = gql`
  mutation UpdateProspect($email: String!, $archived: Boolean!) {
    updateProspect(input: { email: $email, archived: $archived}) {
      email
    }
  }
`;

export const UPDATE_PROSPECT = gql`
  mutation UpdateProspect($email: String!, $name: String!) {
    updateProspect(input: { email: $email, name: $name}) {
      email
    }
  }
`;

export const UPDATE_APPEARANCE_SETTINGS = gql`
  mutation UpdateUser($email: String!, $appearanceSettings: String!) {
    updateUser(input: { email: $email, appearanceSettings: $appearanceSettings}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const PUBLISH_APPEARANCE = gql`
  mutation UpdateUser($email: String!, $publishedAppearance: String!) {
    updateUser(input: { email: $email, publishedAppearance: $publishedAppearance}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;

export const SAVE_CONVERSATIONS = gql`
  mutation UpdateUser($email: String!, $conversations: String!) {
    updateUser(input: { email: $email, conversations: $conversations}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      githubToken
      headerImage
      plan
      planStart
      customerId
      credits
      suggestedMessages
      conversations
      currentChat
      optedOut
    }
  }
`;
