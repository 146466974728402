import { useApolloClient } from "@apollo/client";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { SectionContext } from "../../../context/SectionProvider";
import { StorageContext } from "../../../context/StorageProvider";
import PrimaryButton from "../../buttons/PrimaryButton";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/ConfirmDeletion.module.css";

export default function DeleteArticleModal(props) {
    
  const { userDetails, setUserDetails } = useContext(StorageContext);

  const { sectionData } = useContext(SectionContext);

  const { closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  function confirm() {
    props.deleteArticle();
    setUpdate(true);
    closeOverlay();
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Permanently delete {props.article.name}</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          If you want to permanently and irreversibly delete this article, click
          the button below.
        </Paragraph>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            onClick={confirm}
            spinner
            linkClassName={`${styles.fullWidthButton} ${styles.redButton}`}
            className={`${styles.fullWidthButton} ${styles.redButton}`}
            size="small"
          >
            Confirm
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
