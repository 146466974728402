import React, { useContext, useEffect, useState } from "react";
import H6 from "../text/heading/H6";
import H5 from "../text/heading/H5";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import CopyButton from "../pages/drafts/CopyButton";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  useAccordionContext,
} from "@chakra-ui/react";
import {
  Archive,
  Check,
  ChevronLeft,
  ChevronRight,
  Clipboard,
  Copy,
  Download,
  Edit2,
  MoreVertical,
  Trash,
} from "react-feather";
import { Oval } from "react-loader-spinner";
import { StorageContext } from "../../context/StorageProvider";
import Button from "../buttons/Button";
import styles from "./styles/Table.module.css";
import { ConfigContext } from "../../context/ConfigProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./ckeditor-styles.css"; // Import your custom styles
import { AlertContext } from "../../context/AlertProvider";
import TextInput from "../input/TextInput";
import { useApolloClient } from "@apollo/client";
import { UPDATE_ARTICLE } from "../../graphql/mutations/Articles";
import JSZip from "jszip";
import { Webhook } from "lucide-react";

export default function Table(props) {
  const itemsPerPage = 10; // Number of items to display per page

  const { setUpdate, configState } = useContext(ConfigContext);

  const { userDetails } = useContext(StorageContext);

  const data = props.data;

  const [fields, setFields] = useState(props.fields);

  const [page, setPage] = useState(1); // Initialize with page 1

  const startIndex = (page - 1) * itemsPerPage;

  const { darkMode, newName, setNewName } = useContext(ConfigContext);

  // Add a useEffect to update startIndex when props.data changes
  useEffect(() => {
    if (props.data) {
      // Calculate the maximum number of pages based on the updated data length
      const maxPage = Math.ceil(props.data.length / itemsPerPage);

      // Ensure that the current page is within valid bounds
      const newPage = (prevPage) => Math.min(prevPage, maxPage);
      setPage(props.data.length === 0 ? 1 : newPage < 1 ? 1 : newPage);
    }
  }, [props.data, itemsPerPage, page]);

  const [editing, setEditing] = useState(null);

  const [open, setOpen] = useState(null);

  const { showAlert } = useContext(AlertContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const editorConfig = {
    editorStyle: "body { font-size: 16px; background-color: #f5f5f5; }", // Your custom styles
    // ...other configuration options
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        // Add more heading levels if needed
      ],
    },
  };

  async function saveArticle(article) {
    if (configState.newName !== null && configState.newName.length > 0) {
      article.name = configState.newName;
    }
    await client
      .mutate({
        mutation: UPDATE_ARTICLE,
        variables: {
          articleId: article.articleId,
          name: article.name,
          content: article.content,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    setOpen(article);
    setEditing(null);
  }

  function TableContent(props) {
    const item = props.item;
    const index = props.index;
    const site = props.site;
    if (props.accordion) {
      return (
        <View key={index} className={styles.accordionListItem}>
          <AccordionItem className={styles.accordionItem}>
            <View>
              {fields.map((field) => (
                <View key={field.data} className={styles.somethingElse}>
                  {field.name === "Name" ? (
                    editing === item ? (
                      <H5
                        editable
                        tooltip="Edit article name"
                        loadingClassName={styles.loadingSmall}
                        editClassName={styles.editClass}
                        onEdit={(value) => {
                          configState.newName = value;
                        }}
                        inputClassName={`${styles.smallTitle} ${styles.smallMargDown}`}
                        className={`${styles.smallTitle} ${
                          editing
                            ? styles.pointerEvents
                            : styles.noPointerEvents
                        }`}
                      >
                        {configState.newName !== null
                          ? configState.newName
                          : item.name}
                      </H5>
                    ) : (
                      <H5
                        tooltip="Edit article name"
                        loadingClassName={styles.loadingSmall}
                        editClassName={styles.editClass}
                        onEdit={() => {}}
                        inputClassName={`${styles.smallTitle} ${styles.smallMargDown}`}
                        className={`${styles.smallTitle} ${
                          editing
                            ? styles.pointerEvents
                            : styles.noPointerEvents
                        }`}
                      >
                        {item.name}
                      </H5>
                    )
                  ) : (
                    <Paragraph size="small">{item[field.data]}</Paragraph>
                  )}
                </View>
              ))}
              <AccordionButton className={styles.accordionButton}>
                <View
                  style={{
                    alignItems: "center",
                    marginRight: "10px",
                    width: "20px",
                  }}
                  className={styles.rightContainer}
                >
                  <AccordionIcon className={styles.accordionIcon} />
                </View>
              </AccordionButton>
            </View>
            <AccordionPanel className={styles.accordionPanel}>
              {editing === item ? (
                <View style={{ marginTop: "16px", marginBottom: "-16px" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={item.content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      item.content = data;
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </View>
              ) : (
                <div
                  className={styles.markup}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
          {editing === item && (
            <PrimaryButton
              onClick={() => {
                saveArticle(item);
              }}
              style={{
                marginTop: "0px",
                marginBottom: "-10px",
                marginRight: "16px",
                marginLeft: "0px",
              }}
              size="mini"
            >
              Save
            </PrimaryButton>
          )}
          <CopyButton
            copy={item.content}
            style={{
              maxWidth: "30px",
              minWidth: "30px",
              minHeight: "34px",
              maxHeight: "34px",
              marginRight: "2px",
              marginLeft: "-6px",
              padding: "0px 3px",
            }}
            className={styles.editListItem}
            tooltip="Copy to clipboard"
            size="mini"
          >
            <Clipboard size="16" />
          </CopyButton>
          <Menu>
            <MenuButton>
              <Button
                style={{ maxWidth: "18px", padding: "0px !important" }}
                className={styles.editListItem}
                tooltip="More"
                size="mini"
              >
                <MoreVertical size="16" />
              </Button>
            </MenuButton>
            <MenuList className={styles.dropMenu}>
              <MenuGroup className={styles.dropMenuGroup}>
                <Button
                  onClick={() => {
                    const textToWrite = item.content;
                    const fileName = item.name + ".txt";

                    const element = document.createElement("a");
                    const file = new Blob([textToWrite], {
                      type: "text/plain",
                    });
                    element.href = URL.createObjectURL(file);
                    element.download = fileName;
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                  }}
                  className={styles.listButton}
                >
                  <Download size="16px" /> Download article
                </Button>
                {site && site.webflowToken && (
                  <Button
                    onClick={() => {
                      props.openPublishOnWebflowModal(site, [item]);
                    }}
                    className={styles.listButton}
                  >
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src="https://cdn.icon-icons.com/icons2/2699/PNG/512/webflow_logo_icon_169218.png"
                    />
                    Export to Webflow
                  </Button>
                )}
                {site && site.shopifyToken && (
                  <Button
                    onClick={() => {
                      props.openPublishOnShopifyModal(site, [item]);
                    }}
                    className={styles.listButton}
                  >
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src="https://cdn-icons-png.flaticon.com/512/2504/2504814.png"
                    />
                    Export to Shopify
                  </Button>
                )}
                {site && site.ghostToken && (
                  <Button
                    onClick={() => {
                      props.openPublishOnGhostModal(site, [item]);
                    }}
                    className={styles.listButton}
                  >
                    <img
                      style={{ width: "16px", height: "16px" }}
                      className={darkMode && styles.inverted}
                      src="https://ghost.org/images/logos/ghost-logo-orb.png"
                    />
                    Export to Ghost
                  </Button>
                )}
                {site && site.webhookToken && (
                  <Button
                    onClick={() => {
                      props.openPublishWithWebhookModal(site, [item]);
                    }}
                    className={styles.webhookButton}
                  >
                    <Webhook size="16px" className={styles.webhookIcon} />
                    Export with webhook
                  </Button>
                )}
                {site && site.wordpressToken && (
                  <Button
                    onClick={() => {
                      props.openPublishOnWordpressModal(site, [item]);
                    }}
                    className={styles.listButton}
                  >
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png"
                    />
                    Export to Wordpress
                  </Button>
                )}
                <Button
                  onClick={() => {
                    if (editing === item) {
                      saveArticle(item);
                    } else {
                      configState.newName = item.name;
                      setEditing(item);
                    }
                  }}
                  className={styles.listButton}
                >
                  {editing !== item && <Edit2 size="16px" />}{" "}
                  {editing === item ? "Save article" : "Edit article"}
                </Button>
                <Button
                  onClick={() => {
                    props.openDeleteArticleModal(item);
                  }}
                  style={{
                    color: "var(--red)",
                  }}
                  className={styles.listButton}
                >
                  <Trash size="16px" /> Delete article
                </Button>
              </MenuGroup>
              <Spacer size="small" />
            </MenuList>
          </Menu>
        </View>
      );
    } else {
      return (
        <View key={index} className={styles.listItem}>
          <Button
            onClick={() => props.onClickHandler(item)}
            className={styles.listItemButton}
          ></Button>
          {fields.map((field) => (
            <View key={field.data} className={styles.listHeaderItem}>
              {field.name === "Name" ? (
                <H6 className={styles.smallTitle}>{item[field.data]}</H6>
              ) : (
                <Paragraph size="small">{item[field.data] === true ? "Yes" : item[field.data]}</Paragraph>
              )}
            </View>
          ))}
          {props.menu(item) ? (
            <View className={styles.smallListHeaderItem}>
              {props.menu(item)}
            </View>
          ) : null}
        </View>
      );
    }
  }

  return (
    <>
      <View vertical className={styles.list}>
        {data !== null && data.length > 0 && (
          <View className={styles.listHeaderContainer}>
            <View className={styles.listHeader}>
              {fields.map((field, index) => {
                return (
                  <View key={index} className={styles.listHeaderItem}>
                    <H6 className={styles.smallTitle}>{field.name}</H6>
                  </View>
                );
              })}
            </View>
          </View>
        )}
        <View vertical className={styles.listContainer}>
          {open !== null ? (
            <Accordion
              defaultIndex={
                open !== null && data !== null && data.indexOf(open)
              }
              allowMultiple
            >
              {data === null && userDetails !== null ? (
                <View
                  centerVertically
                  centerHorizontally
                  style={{ height: "100%" }}
                  className={styles.innerView}
                >
                  <Oval
                    className={styles.largeSpinner}
                    height={"24"}
                    width={"24"}
                    color="var(--neutral-400)"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="var(--neutral-800)"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </View>
              ) : data === null || data.length === 0 ? (
                <View className={styles.emptyListContainer}>
                  <Spacer size="large" />
                  <Spacer size="small" />
                  <Archive size={20} color="var(--neutral-100)" />
                  <Spacer size="medium" />
                  <Spacer size="small" />
                  <H6>{props.emptyDataTitle}</H6>
                  <Spacer size="small" />
                  <Paragraph size="small" center>
                    {props.emptyDataMessage}
                  </Paragraph>
                  <Spacer size="small" />
                  <Spacer size="large" />
                </View>
              ) : (
                data
                  .slice(startIndex, startIndex + itemsPerPage)
                  .map((item, index) => {
                    if (index >= itemsPerPage) {
                      return null;
                    }
                    return (
                      <TableContent
                        openPublishOnWebflowModal={
                          props.openPublishOnWebflowModal
                        }
                        openPublishOnWordpressModal={
                          props.openPublishOnWordpressModal
                        }
                        openPublishOnShopifyModal={
                          props.openPublishOnShopifyModal
                        }
                        openPublishOnGhostModal={props.openPublishOnGhostModal}
                        openPublishWithWebhookModal={
                          props.openPublishWithWebhookModal
                        }
                        site={props.site}
                        item={item}
                        index={index}
                        menu={props.menu}
                        onClickHandler={props.onClickHandler}
                        accordion={props.accordion}
                        openDeleteArticleModal={props.openDeleteArticleModal}
                      />
                    );
                  })
              )}
            </Accordion>
          ) : (
            <Accordion allowMultiple>
              {data === null && userDetails !== null ? (
                <View
                  centerVertically
                  centerHorizontally
                  style={{ height: "100%" }}
                  className={styles.innerView}
                >
                  <Oval
                    className={styles.largeSpinner}
                    height={"24"}
                    width={"24"}
                    color="var(--neutral-400)"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="var(--neutral-800)"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </View>
              ) : data === null || data.length === 0 ? (
                <View className={styles.emptyListContainer}>
                  <Spacer size="large" />
                  <Spacer size="small" />
                  <Archive size={20} color="var(--neutral-100)" />
                  <Spacer size="medium" />
                  <Spacer size="small" />
                  <H6>{props.emptyDataTitle}</H6>
                  <Spacer size="small" />
                  <Paragraph size="small" center>
                    {props.emptyDataMessage}
                  </Paragraph>
                  <Spacer size="small" />
                  <Spacer size="large" />
                </View>
              ) : (
                data
                  .slice(startIndex, startIndex + itemsPerPage)
                  .map((item, index) => {
                    if (index >= itemsPerPage) {
                      return null;
                    }
                    return (
                      <TableContent
                        openPublishOnWebflowModal={
                          props.openPublishOnWebflowModal
                        }
                        openPublishOnWordpressModal={
                          props.openPublishOnWordpressModal
                        }
                        openPublishOnShopifyModal={
                          props.openPublishOnShopifyModal
                        }
                        openPublishOnGhostModal={props.openPublishOnGhostModal}
                        openPublishWithWebhookModal={
                          props.openPublishWithWebhookModal
                        }
                        site={props.site}
                        item={item}
                        index={index}
                        menu={props.menu}
                        onClickHandler={props.onClickHandler}
                        accordion={props.accordion}
                        openDeleteArticleModal={props.openDeleteArticleModal}
                      />
                    );
                  })
              )}
            </Accordion>
          )}
        </View>
        <View gap="medium"></View>
      </View>
      <Spacer size="large" />
      {data !== null && data.length > 0 && (
        <View>
          <View className={styles.rightContainer}>
            <H6 className={styles.smallTitle}>
              Page {page} of {Math.ceil(data.length / itemsPerPage)}
            </H6>
            <Spacer size="large" />

            {/* First Page Button */}
            <Button
              disabled={page <= 1}
              className={`${styles.paginationButton} ${
                page <= 1 && styles.disabledPaginationButton
              }`}
              onClick={() => {
                if (page <= 1) {
                  return;
                }
                setPage(1);
              }}
            >
              <ChevronLeft color="var(--neutral-100)" size="18px" />
              <ChevronLeft
                style={{ marginLeft: "-12px" }}
                color="var(--neutral-100)"
                size="18px"
              />
            </Button>
            <Spacer size="small" />

            {/* Next Page Button */}
            <Button
              disabled={page <= 1}
              className={`${styles.paginationButton} ${
                page <= 1 && styles.disabledPaginationButton
              }`}
              onClick={() => {
                if (page <= 1) {
                  return;
                }
                setPage(page - 1);
              }}
            >
              <ChevronLeft color="var(--neutral-100)" size="18px" />
            </Button>
            <Spacer size="small" />

            {/* Previous Page Button */}
            <Button
              disabled={page >= Math.ceil(data.length / itemsPerPage)}
              className={`${styles.paginationButton} ${
                page >= Math.ceil(data.length / itemsPerPage) &&
                styles.disabledPaginationButton
              }`}
              onClick={() => {
                if (page >= Math.ceil(data.length / itemsPerPage)) {
                  return;
                }
                setPage(page + 1);
              }}
            >
              <ChevronRight color="var(--neutral-100)" size="18px" />
            </Button>
            <Spacer size="small" />

            {/* Last Page Button */}
            <Button
              disabled={page >= Math.ceil(data.length / itemsPerPage)}
              className={`${styles.paginationButton} ${
                page >= Math.ceil(data.length / itemsPerPage) &&
                styles.disabledPaginationButton
              }`}
              onClick={() => {
                if (page >= Math.ceil(data.length / itemsPerPage)) {
                  return;
                }
                setPage(Math.ceil(data.length / itemsPerPage));
              }}
            >
              <ChevronRight color="var(--neutral-100)" size="18px" />
              <ChevronRight
                style={{ marginLeft: "-12px" }}
                color="var(--neutral-100)"
                size="18px"
              />
            </Button>
            <Spacer size="small" />
          </View>
        </View>
      )}
    </>
  );
}
