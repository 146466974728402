import React, { useContext } from "react";
import styles from "./styles/CookieBanner.module.css";
import View from "../view/View";
import H6 from "../text/heading/H6";
import Spacer from "../view/impl/Spacer";
import Paragraph from "../text/paragraph/Paragraph";
import PrimaryButton from "../buttons/PrimaryButton";
import Button from "../buttons/Button";
import { StorageContext } from "../../context/StorageProvider";
import { ExternalLink } from "react-feather";
import CookiePreferencesModal from "../modals/impl/CookiePreferencesModal";
import Modal from "../modals/Modal";
import { OverlayContext } from "../../context/OverlayProvider";

export default function CookieBanner(props) {

  const { acceptedCookies, setAcceptedCookies, setHideCookieBanner } = useContext(StorageContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  function acceptAllCookies() {
    if (acceptedCookies) {
      let previousSettings = acceptedCookies;
      previousSettings.trackingCookies = true;
      previousSettings.closed = true;
      setAcceptedCookies(previousSettings);
      localStorage.setItem("acceptedCookies", JSON.stringify(previousSettings));
    } else {
      let newSettings = {
        trackingCookies: true,
        closed: true
      };
      setAcceptedCookies(newSettings);
      localStorage.setItem("acceptedCookies", JSON.stringify(newSettings));
    }
    setHideCookieBanner(true);
  }

  const openCookiePreferencesModal = () => {
    openOverlay(
      <Modal title="Cookie Preferences">
        <CookiePreferencesModal />
      </Modal>
    );
  };

  return (
    <View className={styles.cookieContainer}>
      <View className={styles.cookieCell} vertical>
        <H6 className={styles.cookieHeader}>Before you proceed,</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.cookieMessage}>
          This site uses cookies and/or similar technologies to measure and
          improve your experience.
        </Paragraph>
        <Spacer size="medium" />
        <View centerVertically gap="large" className={styles.collapse}>
          <View centerVertically gap="large">
            <Button
              onClick={openCookiePreferencesModal}
              className={styles.noPadButton}
            >
              Cookie Preferences
            </Button>
          </View>
          <View className={styles.rightContainer}>
            <PrimaryButton
              onClick={acceptAllCookies}
              className={styles.acceptAll}
              size="small"
            >
              Accept All
            </PrimaryButton>
          </View>
        </View>
      </View>
    </View>
  );
}
