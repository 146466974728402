import React, { useContext } from "react";
import { StorageContext } from "../../../context/StorageProvider";
import Spacer from "../../view/impl/Spacer";
import H6 from "../../text/heading/H6";

export default function WelcomeMessage(props) {
  const { userDetails } = useContext(StorageContext);

  if (!userDetails || userDetails === null) {
    return undefined;
  }

  return (
    <>
      <Spacer size="small" />
      <H6>Welcome back {userDetails.name} 👋</H6>
      <Spacer size="small" />
      <Spacer size="medium" />
    </>
  );
}
