import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PrimaryButton from "../buttons/PrimaryButton";
import TextInput from "../input/TextInput";
import Container from "../navigation/Container";
import H5 from "../text/heading/H5";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Login.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { GET_USER_BY_HASH } from "../../graphql/queries/Users";
import { useApolloClient } from "@apollo/client";
import { ConfigContext } from "../../context/ConfigProvider";
import { UPDATE_PASSWORD, UPDATE_USER } from "../../graphql/mutations/Users";
import { StorageContext } from "../../context/StorageProvider";

export default function SetPassword(props) {

    const [errors, setErrors] = useState([]);

    const { hash } = useParams();

    const [error, setError] = useState(null);

    const client = useApolloClient();

    const { setUpdate } = useContext(ConfigContext);

    function getErrorWithType(typeToCheck) {
        return errors.find((error) => error.type === typeToCheck);
    }

    function removeErrorWithType(typeToRemove) {
        const updatedErrors = errors.filter((error) => error.type !== typeToRemove);
        setErrors(updatedErrors);
    }

    const [passwordInput, setPasswordInput] = useState("");

    const [userCache, setUserCache] = useState(null);

    const { setUserDetails } = useContext(StorageContext);

    const navigate = useNavigate();

    async function getUser() {
        const response = await client
            .query({
                query: GET_USER_BY_HASH,
                variables: {
                    hash,
                },
            })
            .catch((error) => {
                setErrors((prevErrors) => [
                    ...prevErrors,
                    {
                        type: 1,
                        message: error.message,
                    },
                ]);
                setUpdate(true);
                return error;
            });
        if (response.data) {
            const user = response.data.getUserByHash;
            if (user !== null) {
                await client
                    .mutate({
                        mutation: UPDATE_USER,
                        variables: {
                            email: user.email,
                            hashed: "",
                            hash: "",
                        },
                    })
                    .catch((error) => {
                        console.error("Error updating user:", error);
                    });
                setUserCache(user);
            }
        }
    }

    useEffect(() => {
        document.title = "Set a password for your Drafts AI account";
    }, []);

    useEffect(() => {
        getUser();
    }, [hash]);

    async function setPassword() {
        setErrors([]);
        let prevent = false;
        if (passwordInput.length === 0) {
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    type: 2,
                    message: "Please enter your password.",
                },
            ]);
            prevent = true;
        }
        if (passwordInput.length < 8) {
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    type: 2,
                    message: "Your password needs to be at least 8 characters.",
                },
            ]);
            prevent = true;
        }
        if (passwordInput.length > 48) {
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    type: 2,
                    message: "Your password may not exceed 48 characters.",
                },
            ]);
            prevent = true;
        }
        if (userCache === null) {
            setErrors((prevErrors) => [
                ...prevErrors,
                {
                    type: 2,
                    message: "Your hash is invalid.",
                },
            ]);
            prevent = true;
        }
        if (prevent) {
            setUpdate(true);
            return;
        }
        const response = await client
            .mutate({
                mutation: UPDATE_PASSWORD,
                variables: {
                    email: userCache.email,
                    password: passwordInput
                },
            })
            .catch((error) => {
                console.error("Error updating password:", error);
            });
        if (response && response.data && response.data.updateUser) {
            setUserDetails(response.data.updateUser);
            navigate("/app");
        }
    }

    return (
        <Container className={styles.container}>
            <Helmet>
                <link rel="canonical" href="https://draftsai.com/login" />
            </Helmet>
            <View className={styles.loginContainer}>
                <View className={styles.loginWrapper}>
                    <H5 className={styles.title}>New password</H5>
                    <Spacer size="large" />
                    <div className={styles.form}>
                        <H6 className={styles.inputTitle}>Password</H6>
                        <Spacer size="medium" />
                        <TextInput
                            type="password"
                            size="medium"
                            value={passwordInput}
                            onChange={(e) => {
                                removeErrorWithType(2);
                                setPasswordInput(e.target.value);
                            }}
                            className={`${styles.input} ${getErrorWithType(2) && styles.inputError
                                }`}
                            placeholder="Your new password"
                        />
                        {getErrorWithType(2) && (
                            <Paragraph
                                style={{ marginTop: "8px", color: "var(--red)" }}
                                size="small"
                            >
                                {getErrorWithType(2).message}
                            </Paragraph>
                        )}
                        <div>
                            <Spacer size="large" />
                            <PrimaryButton
                                size="medium"
                                spinner
                                onClick={setPassword}
                                className={styles.button}
                            >
                                Set password
                            </PrimaryButton>
                        </div>
                    </div>
                </View>
            </View>
        </Container>
    );
}
