import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ArrowRight
} from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";
import { StorageContext } from "../../context/StorageProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import View from "../view/View";
import Logo from "./Logo";
import MobileMenu from "./MobileMenu";
import styles from "./styles/Nav.module.css";
import Button from "../buttons/Button";

export default function Nav(props) {
  const location = useLocation();

  const { darkMode, dashboardTab, setDashboardTab } = useContext(ConfigContext);

  const containerRef = useRef(null);

  const { userDetails, setUserDetails } = useContext(StorageContext);

  const [showRightFader, setShowRightFader] = useState(true);
  const [showLeftFader, setShowLeftFader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const maxScrollWidth = container.scrollWidth - container.clientWidth;

      const onScroll = () => {
        const scrollLeft = container.scrollLeft;
        if (scrollLeft === 0) {
          setShowLeftFader(false);
        } else {
          setShowLeftFader(true);
        }
        if (scrollLeft >= maxScrollWidth) {
          setShowRightFader(false);
        } else {
          setShowRightFader(true);
        }
      };

      container.removeEventListener("scroll", onScroll);
      container.addEventListener("scroll", onScroll, { passive: true });

      return () => {
        container.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  if (location.pathname === "/user") {
    return (
      <header className={styles.container}>
        <View className={styles.buttonContainer}>
          <View className={styles.desktopButtonContainer}>
            <PrimaryButton
              size="medium"
              className={styles.mainNavButton}
              to="/join"
            >
              Create your own drafts
            </PrimaryButton>
            <SecondaryButton
              size="medium"
              className={`${styles.mainNavButton} ${styles.hide670}`}
              to="/login"
            >
              Log In
            </SecondaryButton>
          </View>
          <View className={styles.mobileButtonContainer}>
            <MobileMenu />
          </View>
        </View>
      </header>
    );
  }

  function setTab(tab) {
    setDashboardTab(tab);
  }

  function signOut() {
    setUserDetails(null);
    navigate("/app");
  }

  if (
    props.render ||
    location.pathname === "/" ||
    location.pathname.includes("/brand") ||
    location.pathname.includes("/privacy-policy") ||
    location.pathname.includes("/cookie-policy") ||
    location.pathname.includes("/terms-of-service") ||
    location.pathname.includes("/login") ||
    location.pathname.includes("/set-password") ||
    location.pathname.includes("/signup") ||
    location.pathname.includes("/join")
  ) {
    return (
      <View className={styles.wrapper}>
        <View vertical>
          <View className={styles.container}>
            <Logo className={styles.logo} />
            <View className={styles.buttonContainer}>
              <View gap="small" className={styles.centerContainer}>
                <SecondaryButton to="/app/billing" className={styles.clearSecondaryButton} size="medium">Pricing</SecondaryButton>
                <SecondaryButton to="/blog" className={styles.clearSecondaryButton} size="medium">Blog</SecondaryButton>
                <SecondaryButton to="https://calendly.com/julius-egq/introductory-call" className={styles.clearSecondaryButton} size="medium">Book demo</SecondaryButton>
              </View>
              <View className={styles.desktopButtonContainer}>
                {!userDetails ? (
                  <>
                    <SecondaryButton size="medium" to="/login" style={{ marginRight: "-4px" }}>
                      Sign in
                    </SecondaryButton>
                    <PrimaryButton
                      size="medium"
                      className={`${styles.iconButton} ${styles.hide670}`}
                      to="/join"
                    >
                      Get started
                      <ArrowRight size="18" />
                    </PrimaryButton>
                  </>
                ) : (
                  <SecondaryButton
                    size="medium"
                    className={`${styles.iconButton}`}
                    to="/app"
                  >
                    Dashboard
                    <ArrowRight size="18" />
                  </SecondaryButton>
                )}
              </View>
              <View className={styles.mobileButtonContainer}>
                <MobileMenu />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
