import { Plus } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { StorageContext } from "../../context/StorageProvider";
import SecondaryButton from "../buttons/SecondaryButton";
import Link from "../links/Link";
import Container from "../navigation/Container";
import Nav from "../navigation/Nav";
import SearchBar from "../search/SearchBar";
import H4 from "../text/heading/H4";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Blog.module.css";
import { GET_BLOG_ARTICLES } from "../../graphql/queries/BlogArticles";
import { useApolloClient } from "@apollo/client";
import { Oval } from "react-loader-spinner";
import Separator from "../view/impl/Separator";
import Articles from "../blog/Articles";
import { Helmet } from "react-helmet";

export default function Blog(props) {
  const { userDetails } = useContext(StorageContext);

  const client = useApolloClient();

  const [data, setData] = useState(null);

  async function fetchBlogArticles() {
    const response = await client
      .query({
        query: GET_BLOG_ARTICLES,
      })
      .catch((error) => {
        console.error("Error fetching blog articles", error);
      });
    if (response && response.data) {
      setData(response.data.getBlogArticles);
    }
  }

  useEffect(() => {
    if (data === null) {
      fetchBlogArticles();
    }
  }, []);

  const formatDate = (unixTimestampMillis) => {
    const d = new Date(unixTimestampMillis);

    const day = d.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();

    // Function to add the "st," "nd," "rd," or "th" suffix to the day
    const getDayWithSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    return `${getDayWithSuffix(day)} ${month}, ${year}`;
  };

  function openArticle(blogArticle) {
    window.location.href = `/blog/${blogArticle.slug}`;
  }

  return (
    <Container className={styles.container}>
      <Helmet>
        <link rel="canonical" href="https://draftsai.com/blog" />
      </Helmet>
      <View className={styles.header}>
        <Spacer size="medium" />
        <H4 className={styles.centerText}>Drafts AI's blog</H4>
        <Paragraph center size="big">
          Read how we approach generative AI here at Drafts AI.
        </Paragraph>
      </View>
      <Nav render />
      <View className={styles.articleContainer}>
        {userDetails && userDetails.type === 3 && (
          <>
            <View className={styles.rightContainer}>
              <Link
                onClick={() => {
                  window.location.href = "/blog/new";
                }}
                size="small"
                className={styles.smallIconButton}
              >
                <Plus size="16px" /> New blog article
              </Link>
            </View>
            <Spacer size="medium" />
          </>
        )}
        {data === null && (
          <View pad="large" centerVertically centerHorizontally>
            <View pad="large" centerVertically centerHorizontally>
              <Oval
                height={"16"}
                width={"16"}
                color="var(--neutral-400)"
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--neutral-800)"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </View>
          </View>
        )}
        {data !== null &&
        <Articles public onCellClick={openArticle} cellClassName={styles.articleCell} containerClassName={styles.articleContainer2} data={data}/>
          /*data.map((blogArticle) => {
            return (
              <View
                onClick={() => openArticle(blogArticle)}
                className={styles.articleCell}
              >
                <H6>{blogArticle.name}</H6>
                <Paragraph className={styles.summary}>
                  {blogArticle.summary}
                </Paragraph>
                <View
                  className={styles.infoContainer}
                  gap="small"
                  centerVertically
                >
                  <Link>Blog</Link>
                  <Paragraph>·</Paragraph>
                  <Paragraph>
                    Published on{" "}
                    {blogArticle.date
                      ? formatDate(parseInt(blogArticle.date))
                      : "N/A"}
                  </Paragraph>
                </View>
                <Separator className={styles.separator} />
              </View>
            );
          })*/}
      </View>
    </Container>
  );
}
