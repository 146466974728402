import { useApolloClient } from "@apollo/client";
import { Mail, Upload } from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { ConfigContext } from "../../../context/ConfigProvider";
import { EmailContext } from "../../../context/EmailProvider";
import { StorageContext } from "../../../context/StorageProvider";
import {
  ADD_PROSPECTS,
  SET_PROSPECT_STAGE,
} from "../../../graphql/mutations/Users";
import {
  GET_PROSPECTS_BY_PROSPECTOR,
  GET_USERS,
} from "../../../graphql/queries/Users";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import Table from "../../table/Table";
import H5 from "../../text/heading/H5";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Spacer from "../../view/impl/Spacer";
import styles from "./styles/Users.module.css";
import Modal from "../../modals/Modal";
import SendUserMail from "../../modals/impl/SendUserEmail";
import { OverlayContext } from "../../../context/OverlayProvider";

export default function Users(props) {
  const client = useApolloClient();

  const fileSelectionRef = useRef();

  const { userDetails } = useContext(StorageContext);

  const [users, setUsers] = useState(null);

  const [error, setError] = useState(null);

  const { sendEmail } = useContext(EmailContext);

  const { update, setUpdate } = useContext(ConfigContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
  useContext(OverlayContext);

  async function getUsers() {
    const response = await client
      .query({
        query: GET_USERS,
        variables: {},
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getUsers) {
      setUsers(response.data.getUsers);
    }
  }

  useEffect(() => {
    if (users === null) {
      getUsers();
    }
  }, [users]);

  const openSendUserEmail = () => {
    openOverlay(
      <Modal title="Email">
        <SendUserMail users={users} />
      </Modal>
    );
  };

  return (
    <View
      className={styles.contentContainer2}
      vertical
      style={{
        width: !props.showMenu ? `calc(100% - 75px)` : `calc(100% - 285px)`,
      }}
    >
      <View vertical className={styles.innerView}>
        <View vertical>
          <Spacer size="medium" />
          <View className={styles.pageHeader}>
            <View vertical className={styles.pageHeaderLeftContainer}>
              {users === null ? (
                undefined
              ) : (
                <H5 className={styles.smallH5}>Users ({users.length})</H5>
              )}
              <Spacer size="small" />
              <Paragraph>These are all of our users</Paragraph>
            </View>
            <View
              gap="small"
              className={styles.pageHeaderRightContainer}
            >
              <PrimaryButton onClick={() => {
                openSendUserEmail();
              }} size="small" className={styles.smallIconButton}>
                <Mail size="16px" /> Email users
              </PrimaryButton>
            </View>
          </View>
          <Spacer size="medium" />
          <Table
            emptyDataTitle={"No users found."}
            emptyDataMessage={"Your users will appear here."}
            data={users}
            fields={[
              {
                name: "Name",
                data: "name",
              },
              {
                name: "Email",
                data: "email",
              },
              {
                name: "Plan",
                data: "plan",
              },
              {
                name: "Opted Out",
                data: "optedOut",
              },
            ]}
            onClickHandler={(item) => {}}
            menu={(item) => {
              return undefined;
            }}
          />
        </View>
      </View>
    </View>
  );
}
