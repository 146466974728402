import { gql } from "@apollo/client";

export const ADD_BLOG_ARTICLE = gql`
  mutation AddBlogArticle(
    $articleId: Int!
    $name: String!
    $content: String!
    $summary: String!
    $image: String!
    $archived: Boolean!
  ) {
    addBlogArticle(
      input: {
        articleId: $articleId
        name: $name
        content: $content
        summary: $summary
        image: $image
        archived: $archived
      }
    ) {
      articleId
      name
      content
      summary
      date
      edited
      slug
      image
      archived
    }
  }
`;

export const ARCHIVE_BLOG_ARTICLE = gql`
  mutation archiveBlogArticle($articleId: Int!) {
    archiveBlogArticle(input: { articleId: $articleId }) {
      articleId
    }
  }
`;