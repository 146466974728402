import { Spinner } from "@chakra-ui/react";
import React, { useContext } from "react";
import View from "../../view/View";
import Paragraph from "../../text/paragraph/Paragraph";
import Spacer from "../../view/impl/Spacer";
import H6 from "../../text/heading/H6";
import { ConfigContext } from "../../../context/ConfigProvider";
import { Oval } from "react-loader-spinner";

export default function LoadingModal(props) {
  const {
    articlesToGenerate,
    articlesGenerated,
    setArticlesGenerated,
    setUpdate,
  } = useContext(ConfigContext);

  return (
    <View pad="large" centerVertically centerHorizontally>
      <View vertical pad="large" centerVertically centerHorizontally>
        <Oval
          height={"24"}
          width={"24"}
          color="var(--neutral-400)"
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="var(--neutral-800)"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
        <Spacer size="large" />
        <Spacer size="medium" />
        <Paragraph>We're drafting your articles.</Paragraph>
        <Spacer size="medium" />
        <H6>
          {articlesGenerated} out of {articlesToGenerate} articles drafted
        </H6>
      </View>
    </View>
  );
}
