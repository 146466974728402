import React, { useEffect, useRef, useState } from "react";
import View from "../view/View";
import styles from "./styles/Articles.module.css";
import Paragraph from "../text/paragraph/Paragraph";
import H6 from "../text/heading/H6";
import { ChevronLeft, ChevronRight, Clock } from "lucide-react";
import Button from "../buttons/Button";
import Link from "../links/Link";
import Separator from "../view/impl/Separator";
import Spacer from "../view/impl/Spacer";

const Articles = React.forwardRef((props, ref) => {
  const articleCellRef = useRef(null);
  const [maxNameLength, setMaxNameLength] = useState(100);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const articlesPerPage = 10;

  useEffect(() => {
    function updateMaxNameLength() {
      if (articleCellRef.current) {
        const cellWidth = articleCellRef.current.offsetWidth;
        setMaxNameLength(cellWidth / 11);
      }
    }

    // Initial update
    updateMaxNameLength();

    // Add event listener for window resize
    window.addEventListener("resize", updateMaxNameLength);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateMaxNameLength);
    };
  }, []);

  if (!props.data || props.data === null || props.data.length === 0) {
    return null;
  }

  function extractImageUrl(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const firstImg = doc.querySelector("img");
    return firstImg ? firstImg.getAttribute("src") : null;
  }

  // Calculate the range of articles to display for the current page
  const indexOfLastArticle = page * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const displayedArticles = props.data.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const totalPages = Math.ceil(props.data.length / articlesPerPage);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const formatDate = (unixTimestampMillis) => {
    const d = new Date(unixTimestampMillis);

    const day = d.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();

    // Function to add the "st," "nd," "rd," or "th" suffix to the day
    const getDayWithSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    return `${getDayWithSuffix(day)} ${month}, ${year}`;
  };

  return (
    <>
      <div
        className={`${styles.blogContainer} ${
          props.containerClassName && props.containerClassName
        }`}
      >
        {displayedArticles.map((article) => {
          return (
            <div
              onClick={() => props.onCellClick(article)}
              className={`${styles.blogArticle} ${
                props.cellClassName && props.cellClassName
              }`}
              key={article.id}
              ref={articleCellRef}
            >
              <H6 className={styles.heading}>{article.name}</H6>
              <Paragraph className={styles.summary}>
                {article.summary}
              </Paragraph>
              <View
                className={styles.infoContainer}
                gap="small"
                centerVertically
              >
                <Link>Blog</Link>
                <Paragraph>·</Paragraph>
                <Paragraph>
                  {props.public ? "Published" : "Created"} on{" "}
                  {article.date ? formatDate(parseInt(article.date)) : "N/A"}
                </Paragraph>
              </View>
              <Separator className={styles.separator} />
            </div>
          );
        })}
      </div>
      <Spacer size="large" />
      <View className={styles.rightContainer}>
        <H6 className={styles.smallTitle}>
          Page {page} of {Math.ceil(props.data.length / itemsPerPage)}
        </H6>
        <Spacer size="large" />
        <Button
          disabled={page <= 1}
          className={`${styles.paginationButton} ${
            page <= 1 && styles.disabledPaginationButton
          }`}
          onClick={() => {
            if (page <= 1) {
              return;
            }
            setPage(1);
          }}
        >
          <ChevronLeft color="var(--neutral-100)" size="18px" />
          <ChevronLeft
            style={{ marginLeft: "-12px" }}
            color="var(--neutral-100)"
            size="18px"
          />
        </Button>
        <Spacer size="small" />
        <Button
          disabled={page <= 1}
          className={`${styles.paginationButton} ${
            page <= 1 && styles.disabledPaginationButton
          }`}
          onClick={() => {
            if (page <= 1) {
              return;
            }
            setPage(page - 1);
          }}
        >
          <ChevronLeft color="var(--neutral-100)" size="18px" />
        </Button>
        <Spacer size="small" />
        <Button
          disabled={page >= Math.ceil(props.data.length / itemsPerPage)}
          className={`${styles.paginationButton} ${
            page >= Math.ceil(props.data.length / itemsPerPage) &&
            styles.disabledPaginationButton
          }`}
          onClick={() => {
            if (page >= Math.ceil(props.data.length / itemsPerPage)) {
              return;
            }
            setPage(page + 1);
          }}
        >
          <ChevronRight color="var(--neutral-100)" size="18px" />
        </Button>
        <Spacer size="small" />
        <Button
          disabled={page >= Math.ceil(props.data.length / itemsPerPage)}
          className={`${styles.paginationButton} ${
            page >= Math.ceil(props.data.length / itemsPerPage) &&
            styles.disabledPaginationButton
          }`}
          onClick={() => {
            if (page >= Math.ceil(props.data.length / itemsPerPage)) {
              return;
            }
            setPage(Math.ceil(props.data.length / itemsPerPage));
          }}
        >
          <ChevronRight color="var(--neutral-100)" size="18px" />
          <ChevronRight
            style={{ marginLeft: "-12px" }}
            color="var(--neutral-100)"
            size="18px"
          />
        </Button>
        <Spacer size="small" />
      </View>
    </>
  );
});

export default Articles;
