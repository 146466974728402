import { useApolloClient } from "@apollo/client";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import {
  AcademicCapIcon,
  ArrowRightOnRectangleIcon,
  BellIcon,
  BoltIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  LifebuoyIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ChevronRight,
  Columns,
  Command,
  CornerUpLeft,
  Download,
  Edit2,
  Globe,
  MoreVertical,
  Plus,
  Trash,
  Trash2,
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../context/AlertProvider";
import { ConfigContext } from "../../context/ConfigProvider";
import { OverlayContext } from "../../context/OverlayProvider";
import { SiteContext } from "../../context/SiteProvider";
import { StorageContext } from "../../context/StorageProvider";
import {
  ADD_ARTICLES,
  ARCHIVE_ARTICLE,
} from "../../graphql/mutations/Articles";
import { ARCHIVE_REPORT } from "../../graphql/mutations/Reports";
import { ARCHIVE_SITE } from "../../graphql/mutations/Sites";
import {
  SET_USER_PRIVACY,
  SOFT_DELETE,
  UPDATE_BIO,
  UPDATE_HEADER_IMAGE,
  UPDATE_NAME,
} from "../../graphql/mutations/Users";
import { GET_ARTICLES_BY_REPORTID } from "../../graphql/queries/Articles";
import { GET_REPORTS_BY_SITEID } from "../../graphql/queries/Reports";
import { GET_SITES_BY_OWNER } from "../../graphql/queries/Sites";
import { GET_STATS, GET_USER_COUNT } from "../../graphql/queries/Users";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import Modal from "../modals/Modal";
import ConfirmDeletion from "../modals/impl/ConfirmDeletion";
import ConfettiExplosion from "react-confetti-explosion";
import CookiePreferencesModal from "../modals/impl/CookiePreferencesModal";
import DeleteArticleModal from "../modals/impl/DeleteArticleModal";
import DeleteReportModal from "../modals/impl/DeleteReportModal";
import DeleteSiteModal from "../modals/impl/DeleteSiteModal";
import NewArticleModal from "../modals/impl/NewArticleModal";
import NewReportModal from "../modals/impl/NewReportModal";
import NewSiteModal from "../modals/impl/NewSiteModal";
import SignInModal from "../modals/impl/SignInModal";
import Container from "../navigation/Container";
import Logo from "../navigation/Logo";
import Table from "../table/Table";
import Articles from "../blog/Articles";
import H5 from "../text/heading/H5";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import ProfilePicture from "../view/impl/ProfilePicture";
import Separator from "../view/impl/Separator";
import Spacer from "../view/impl/Spacer";
import Billing from "./drafts/Billing";
import styles from "./styles/App.module.css";
import JSZip from "jszip";
import PublishOnWebflowModal from "../modals/impl/PublishOnWebflowModal";
import PublishWithWebhookModal from "../modals/impl/PublishWithWebhookModal";
import Tutorials from "./drafts/Tutorials";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import config from "../../config.json";
import PublishOnWordpressModal from "../modals/impl/PublishOnWordpressModal";
import H3 from "../text/heading/H3";
import { Oval } from "react-loader-spinner";
import PublishOnShopifyModal from "../modals/impl/PublishOnShopifyModal";
import PublishOnGhostModal from "../modals/impl/PublishOnGhostModal";
import Link from "../links/Link";
import {
  Backpack,
  BackpackIcon,
  Home,
  Key,
  LayoutList,
  ListOrdered,
  Mail,
  Mic,
  Newspaper,
  PlusCircle,
  Presentation,
  Projector,
  User,
  Users2,
  Webhook,
} from "lucide-react";
import Stats from "../stats/Stats";
import Prospects from "./drafts/Prospects";
import WelcomeMessage from "./drafts/WelcomeMessage";
import Users from "./drafts/Users";
import { EmailContext } from "../../context/EmailProvider";

const stripePromise = loadStripe(
  "pk_live_51L0YG8CR42wmYSKKxOzySTnW85rDEd7XsI78ygaotk3WhPdn3l9patA6XvKEvgsnYnN5EGilCVzRnTpeDRzyYoh400r1mYorFH"
);

export default function App() {
  const client = useApolloClient();

  const { userDetails, setUserDetails } = useContext(StorageContext);

  const { darkMode, newName, setNewName } = useContext(ConfigContext);

  const { showAlert } = useContext(AlertContext);

  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(true);

  const [error, setError] = useState(null);

  const messagesRef = useRef();

  const [site, setSite] = useState(null);

  const [report, setReport] = useState(null);

  const [stats, setStats] = useState(null);

  const [usercount, setUsercount] = useState(null);

  const [isExploding, setIsExploding] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  async function getStats() {
    const response = await client
      .query({
        query: GET_STATS,
        variables: {
          owner: userDetails.email,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getStatsByOwner) {
      setStats(response.data.getStatsByOwner);
    }
  }

  async function getUserCount() {
    const response = await client
      .query({
        query: GET_USER_COUNT,
        variables: {},
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getUserCount) {
      setUsercount(response.data.getUserCount);
    }
  }

  const [selectedTab, setSelectedTab] = useState({
    id: "sites",
    name: "Sites",
  });

  useEffect(() => {
    // Get the part of the URL after "/app/"
    const valueAfterApp = window.location.pathname.split("/app/")[1];

    // Set the selectedTab using the extracted value
    if (
      valueAfterApp &&
      (valueAfterApp === "billing" ||
        valueAfterApp === "sites" ||
        valueAfterApp === "tutorials" ||
        valueAfterApp === "profile" ||
        valueAfterApp === "settings")
    ) {
      setSelectedTab({
        id: valueAfterApp,
        name: valueAfterApp.charAt(0).toUpperCase() + valueAfterApp.slice(1),
      });
    }
  }, []);

  const [selectedSettingsTab, setSelectedSettingsTab] = useState({
    id: "account",
    name: "Account Settings",
    desc: "Change your account settings.",
  });

  const { sites, setSites } = useContext(SiteContext);

  const { reports, setReports, articles, setArticles } =
    useContext(SiteContext);

  const downloadZipFile = async (files) => {
    const zip = new JSZip();
    const fileCounts = {};
    files.forEach((file) => {
      let fileName = file.name;
      const fileContent = file.content;
      if (!fileCounts[fileName]) {
        fileCounts[fileName] = 1;
      } else {
        fileCounts[fileName]++;
        fileName = `${fileName}_${fileCounts[fileName]}`;
      }
      zip.file(fileName, fileContent);
    });
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(zipBlob);
    element.download = report.name + ".zip";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  function addSite(site) {
    setSites((prevSites) => [...(prevSites || []), site]);
    showAlert("New site added successfully.", "success");
  }

  function addArticle(article) {
    setArticles((prevArticles) => [...(prevArticles || []), article]);
    showAlert("New article added successfully.", "success");
  }

  function updateSite(siteToUpdate) {
    const updatedSites = sites.map((site) => {
      if (site.siteId === siteToUpdate.siteId) {
        return {
          ...site,
          name: siteToUpdate.name,
          uri: siteToUpdate.uri,
          integrationType: siteToUpdate.integrationType,
          wordpressUrl: siteToUpdate.wordpressUrl,
          wordpressUser: siteToUpdate.wordpressUser,
          wordpressToken: siteToUpdate.wordpressToken,
          webflowToken: siteToUpdate.webflowToken,
          shopifyUrl: siteToUpdate.shopifyUrl,
          shopifyToken: siteToUpdate.shopifyToken,
          ghostUrl: siteToUpdate.ghostUrl,
          ghostToken: siteToUpdate.ghostToken,
        };
      }
      return site;
    });

    setSites(updatedSites);
  }

  function updateArticle(articleToUpdate) {
    const updatedArticles = articles.map((article) => {
      if (article.articleId === articleToUpdate.articleId) {
        return {
          ...article,
          name: articleToUpdate.name,
          content: articleToUpdate.content,
        };
      }
      return article;
    });

    setArticles(updatedArticles);
  }

  function addReport(report) {
    setReports((prevReports) => [...(prevReports || []), report]);
    showAlert(
      "We're creating your Drafts. This should take less than 60 seconds.",
      "info"
    );
  }

  function updateReport(reportToUpdate) {
    const updatedReports = reports.map((report) => {
      if (report.reportId === reportToUpdate.reportId) {
        return {
          ...report,
          name: reportToUpdate.name,
          reportType: reportToUpdate.reportType,
          keywords: reportToUpdate.keywords,
          language: reportToUpdate.language,
        };
      }
      return report;
    });

    setReports(updatedReports);
  }

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  const [loading, setLoading] = useState(false);

  async function getSites() {
    setLoading(true);
    const response = await client
      .query({
        query: GET_SITES_BY_OWNER,
        variables: {
          owner: userDetails.email,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getSitesByOwner) {
      setTimeout(() => {
        setSites(response.data.getSitesByOwner);
        setLoading(false);
      }, 500);
    }
  }

  async function getReports(site) {
    setLoading(true);
    const response = await client
      .query({
        query: GET_REPORTS_BY_SITEID,
        variables: {
          siteId: site.siteId,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getReportsBySiteId) {
      setTimeout(() => {
        console.log(
          "response.data.getReportsBySiteId:",
          response.data.getReportsBySiteId
        );
        setReports(response.data.getReportsBySiteId);
        setLoading(false);
      }, 500);
    }
  }

  async function getArticles(report, reportSize) {
    setLoading(true);
    if (reportSize && reportSize > 0) {
      const articlesToAdd = [];
      for (let i = 0; i < reportSize; i++) {
        articlesToAdd.push({
          articleId: -1,
          reportId: report.reportId,
          name: "testing 123",
          content: "testing 123",
          instruction: "",
          archived: false,
        });
      }
      await client.mutate({
        mutation: ADD_ARTICLES,
        variables: {
          articles: articlesToAdd,
        },
        fetchPolicy: "no-cache",
      });
    }
    const response = await client
      .query({
        query: GET_ARTICLES_BY_REPORTID,
        variables: {
          reportId: report.reportId,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getArticlesByReportId) {
      setTimeout(() => {
        console.log(
          "response.data.getArticlesByReportId:",
          response.data.getArticlesByReportId
        );
        setArticles(response.data.getArticlesByReportId);
        setLoading(false);
      }, 500);
    }
  }

  function openPublishWithWebhookModal(site, articles) {
    openOverlay(
      <Modal title="Export">
        <PublishWithWebhookModal site={site} articles={articles} />
      </Modal>
    );
  }

  function openPublishOnWebflowModal(site, articles) {
    openOverlay(
      <Modal title="Export">
        <PublishOnWebflowModal site={site} articles={articles} />
      </Modal>
    );
  }

  function openPublishOnWordpressModal(site, articles) {
    openOverlay(
      <Modal title="Export">
        <PublishOnWordpressModal site={site} articles={articles} />
      </Modal>
    );
  }

  function openPublishOnShopifyModal(site, articles) {
    openOverlay(
      <Modal title="Export">
        <PublishOnShopifyModal site={site} articles={articles} />
      </Modal>
    );
  }

  function openPublishOnGhostModal(site, articles) {
    openOverlay(
      <Modal title="Export">
        <PublishOnGhostModal site={site} articles={articles} />
      </Modal>
    );
  }

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);

    // Update the URL to match the new tab
    navigate(`/app/${newTab.id}`);
  };

  function signOut() {
    handleTabChange({
      id: "sites",
      name: "Sites",
    });
    setUserDetails(null);
    setArticles(null);
    setReports(null);
    setSites(null);
    setReport(null);
    setSite(null);
    navigate("/");
  }

  function openNewArticleModal(report, articleType) {
    openOverlay(
      <Modal title="New Article">
        <NewArticleModal
          handleTabChange={handleTabChange}
          report={report}
          articleType={articleType}
          addArticle={addArticle}
        />
      </Modal>
    );
  }

  function openNewReportModal(site) {
    openOverlay(
      <Modal title="New Report">
        <NewReportModal
          setIsExploding={setIsExploding}
          handleTabChange={handleTabChange}
          setReport={setReport}
          getArticles={getArticles}
          site={site}
          addReport={addReport}
        />
      </Modal>
    );
  }

  function openNewSiteModal() {
    openOverlay(
      <Modal title="Add Site">
        <NewSiteModal addSite={addSite} />
      </Modal>
    );
  }

  function openEditSiteModal(site) {
    openOverlay(
      <Modal title="Edit Site">
        <NewSiteModal site={site} updateSite={updateSite} />
      </Modal>
    );
  }

  function openEditReportModal(report) {
    openOverlay(
      <Modal title="Edit Report">
        <NewReportModal
          site={site}
          report={report}
          updateReport={updateReport}
        />
      </Modal>
    );
  }

  async function deleteSite(site) {
    const response = await client
      .mutate({
        mutation: ARCHIVE_SITE,
        variables: {
          siteId: site.siteId,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.archiveSite) {
      setSites(sites.filter((s) => s.siteId !== site.siteId));
      showAlert("Site deleted successfully", "success");
    }
  }

  async function deleteReport(report) {
    const response = await client
      .mutate({
        mutation: ARCHIVE_REPORT,
        variables: {
          reportId: report.reportId,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.archiveReport) {
      setReports(reports.filter((s) => s.reportId !== report.reportId));
      showAlert("Report deleted successfully", "success");
    }
  }

  async function deleteArticle(article) {
    const response = await client
      .mutate({
        mutation: ARCHIVE_ARTICLE,
        variables: {
          articleId: article.articleId,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.archiveArticle) {
      setArticles(articles.filter((s) => s.articleId !== article.articleId));
      showAlert("Article deleted successfully", "success");
    }
  }

  function openDeleteSiteModal(site) {
    openOverlay(
      <Modal title={"Delete " + site.name}>
        <DeleteSiteModal site={site} deleteSite={() => deleteSite(site)} />
      </Modal>
    );
  }

  function openDeleteReportModal(report) {
    openOverlay(
      <Modal title={"Delete " + report.name}>
        <DeleteReportModal
          report={report}
          deleteReport={() => deleteReport(report)}
        />
      </Modal>
    );
  }

  function openDeleteArticleModal(article) {
    openOverlay(
      <Modal title={"Delete " + article.name}>
        <DeleteArticleModal
          article={article}
          deleteArticle={() => deleteArticle(article)}
        />
      </Modal>
    );
  }

  const openConfirmDeleteUserModal = () => {
    openOverlay(
      <Modal title="Delete Account">
        <ConfirmDeletion />
      </Modal>
    );
  };

  const openCookiePreferencesModal = () => {
    openOverlay(
      <Modal title="Cookie Preferences">
        <CookiePreferencesModal />
      </Modal>
    );
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload the image to the server
      let formData = new FormData();
      if (userDetails && userDetails.headerImage) {
        formData.append("filename", userDetails.headerImage.split("/").pop());
        await axios.delete(
          (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
          `/api/removeImage/${formData.get("filename")}`
        );
      }
      formData = new FormData();
      formData.append("profilePicture", file);
      axios
        .post(
          (config.PROD ? config.PROD_API_URL : config.DEV_API_URL) +
          "/api/uploadProfilePicture",
          formData
        )
        .then((response) => {
          client
            .mutate({
              mutation: UPDATE_HEADER_IMAGE,
              variables: {
                email: userDetails.email,
                headerImage:
                  (config.PROD ? config.PROD_API_URL : config.DEV_API_URL) +
                  response.data.url,
              },
            })
            .then((response) => {
              if (response && response.data && response.data.updateUser) {
                setUserDetails(response.data.updateUser);
              }
            })
            .catch((error) => {
              console.error("Error updating user:", error);
            });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  async function onEditName(value) {
    const response = await client
      .mutate({
        mutation: UPDATE_NAME,
        variables: {
          email: userDetails.email,
          name: value,
        },
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    if (response && response.data && response.data.updateUser) {
      setUserDetails(response.data.updateUser);
    }
  }

  async function onEditBio(value) {
    const response = await client
      .mutate({
        mutation: UPDATE_BIO,
        variables: {
          email: userDetails.email,
          bio: value,
        },
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    if (response && response.data && response.data.updateUser) {
      setUserDetails(response.data.updateUser);
    }
  }

  async function updateProfilePrivacy(privacy) {
    const response = await client
      .mutate({
        mutation: SET_USER_PRIVACY,
        variables: {
          email: userDetails.email,
          private: privacy,
        },
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    if (response && response.data && response.data.updateUser) {
      setUserDetails(response.data.updateUser);
    }
  }

  async function deleteUser() {
    let response = await client
      .mutate({
        mutation: SOFT_DELETE,
        variables: {
          email: userDetails.email,
          archived: true,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.updateUser) {
      closeOverlay();
      setUserDetails(null);
      navigate("/");
    }
  }

  const [imageUrl, setImageUrl] = useState(null);

  const { update, setUpdate } = useContext(ConfigContext);

  function Settings() {
    switch (selectedSettingsTab.id) {
      case "account":
        return (
          <View vertical>
            <View vertical>
              <Spacer size="medium" />
              <Spacer size="small" />
              <H6>Delete account</H6>
              <Spacer size="medium" />
              <Paragraph size="small">
                Your account will be permanently and irreversibly deleted within
                30 days after you confirm the deletion of your account.
              </Paragraph>
              <Spacer size="medium" />
              <Spacer size="small" />
              <SecondaryButton
                onClick={openConfirmDeleteUserModal}
                linkClassName={`${styles.fullWidthButton} ${styles.iconButton}`}
                className={`${styles.fullWidthButton} ${styles.iconButton}`}
                size="medium"
              >
                <Trash2 size="16" />
                Delete account
              </SecondaryButton>
            </View>
          </View>
        );

      case "security":
        return (
          <View vertical>
            <View vertical>
              <Spacer size="medium" />
              <Spacer size="small" />
              <H6>Update password</H6>
              <Spacer size="medium" />
              <Paragraph size="small">
                A magic link will be sent to your email to verify your new password.
              </Paragraph>
              <Spacer size="medium" />
              <Spacer size="small" />
              <SecondaryButton
                disabled={passwordLinkSent}
                onClick={changePassword}
                linkClassName={`${styles.fullWidthButton} ${styles.iconButton}`}
                className={`${styles.fullWidthButton} ${styles.iconButton}`}
                size="medium"
              >
                {passwordLinkSent ? "Magic link sent" : "Send magic link"}
              </SecondaryButton>
            </View>
          </View>
        );

      case "billing":
        return (
          <View vertical>
            <View vertical>
              <Spacer size="medium" />
              <Spacer size="small" />
              <H6>Current plan</H6>
              <Spacer size="medium" />
              <Paragraph size="small">
                You can always manage your plan using Stripe.
              </Paragraph>
              <Spacer size="medium" />
              <Spacer size="small" />
              <SecondaryButton
                onClick={() =>
                  handleTabChange({
                    id: "billing",
                    name: "Billing",
                  })
                }
                linkClassName={`${styles.fullWidthButton} ${styles.iconButton}`}
                className={`${styles.fullWidthButton} ${styles.iconButton}`}
                size="medium"
              >
                Manage plan
              </SecondaryButton>
              <Spacer size="medium" />
              <Spacer size="medium" />
            </View>
            <Separator className={styles.wideSeparator} />
            <View vertical>
              <Spacer size="large" />
              <H6>Article credits</H6>
              <Spacer size="medium" />
              <Paragraph size="small">
                If you run out of article credits, you can always upgrade your
                plan.
              </Paragraph>
              <Spacer size="medium" />
              <Spacer size="small" />
              <Paragraph className={styles.credits}>{parseInt(userDetails.credits || 0).toLocaleString()}</Paragraph>
            </View>
          </View>
        );

      case "privacy":
        return (
          <View vertical>
            <View vertical>
              <Spacer size="medium" />
              <Spacer size="small" />
              <H6>Data preferences</H6>
              <Spacer size="medium" />
              <Paragraph size="small">
                drafts gives you the ability to control your data.
              </Paragraph>
              <Spacer size="medium" />
              <Spacer size="small" />
              <SecondaryButton
                onClick={openCookiePreferencesModal}
                linkClassName={`${styles.fullWidthButton} ${styles.iconButton}`}
                className={`${styles.fullWidthButton} ${styles.iconButton}`}
                size="medium"
              >
                Preferences
              </SecondaryButton>
            </View>
          </View>
        );

      case "enterprise":
        return (
          <View vertical>
            <View vertical>
              <Spacer size="medium" />
              <Spacer size="small" />
              <H6>OpenAI API key</H6>
              <Spacer size="medium" />
              <Paragraph size="small">BYOK (bring your own key)</Paragraph>
              <Spacer size="medium" />
              <Spacer size="small" />
              <SecondaryButton
                onClick={openCookiePreferencesModal}
                linkClassName={`${styles.fullWidthButton} ${styles.iconButton}`}
                className={`${styles.fullWidthButton} ${styles.iconButton}`}
                size="medium"
              >
                Preferences
              </SecondaryButton>
            </View>
          </View>
        );
    }
  }

  useEffect(() => {
    if (userDetails) {
      getStats();
    }
  }, [reports, articles, sites]);

  function SitesTab() {
    useEffect(() => {
      if (userDetails && stats === null) {
        getStats();
      }
      if (
        userDetails &&
        userDetails.email === "julius@nineteenmediagroup.com" &&
        usercount === null
      ) {
        getUserCount();
      }
    }, []);

    useEffect(() => {
      if (userDetails && stats === null && sites === null) {
        getSites();
      }
    }, [stats]);

    if (report !== null) {
      return (
        <View
          className={styles.contentContainer2}
          vertical
          style={{
            width: !showMenu ? `calc(100% - 75px)` : `calc(100% - 285px)`,
          }}
        >
          <View vertical className={styles.innerView}>
            <WelcomeMessage />
            <View
              centerVertically
              centerHorizontally
              className={`${styles.banner} ${!userDetails && styles.hidden}`}
            >
              {stats === null ? (
                <Oval
                  height={"24"}
                  width={"24"}
                  color="var(--neutral-400)"
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="var(--neutral-800)"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              ) : (
                <Stats stats={stats} />
              )}
            </View>
            <Spacer size="large" />
            <View centerVertically gap="small">
              <Link
                size="small"
                onClick={() => {
                  setReports(null);
                  setSite(null);
                  setArticles(null);
                  setReport(null);
                }}
              >
                Sites
              </Link>
              <ChevronRight
                style={{ color: "var(--neutral-500)" }}
                size="16px"
              />
              <Link
                size="small"
                onClick={() => {
                  setArticles(null);
                  setReport(null);
                }}
              >
                {site.name}
              </Link>
              <ChevronRight
                style={{ color: "var(--neutral-500)" }}
                size="16px"
              />
              <Paragraph size="small">{report.name}</Paragraph>
            </View>
            <Spacer size="large" />
            <H5 className={styles.smallH5}>Draft templates</H5>
            <Spacer size="small" />
            <Paragraph>Pick one of our templates</Paragraph>
            <Spacer size="medium" />
            <View className={styles.draftTypeContainer}>
              <View className={styles.draftTypeCell}>
                <View
                  onClick={() => openNewArticleModal(report, "editorial_essay")}
                  className={styles.draftTypeCellOverlay}
                >
                  <PlusCircle size="24px" />
                </View>
                <LayoutList size="40px" />
                <H6 className={styles.draftTypeCellTitle} size="small">
                  Editorial Essay
                </H6>
              </View>
              <View className={styles.draftTypeCell}>
                <View
                  onClick={() => openNewArticleModal(report, "academic_essay")}
                  className={styles.draftTypeCellOverlay}
                >
                  <PlusCircle size="24px" />
                </View>
                <BackpackIcon size="40px" />
                <H6 className={styles.draftTypeCellTitle} size="small">
                  Academic Essay
                </H6>
              </View>
              <View className={styles.draftTypeCell}>
                <View
                  onClick={() => openNewArticleModal(report, "listicle")}
                  className={styles.draftTypeCellOverlay}
                >
                  <PlusCircle size="24px" />
                </View>
                <ListOrdered size="40px" />
                <H6 className={styles.draftTypeCellTitle} size="small">
                  Listicle
                </H6>
              </View>
              <View className={styles.draftTypeCell}>
                <View
                  onClick={() => openNewArticleModal(report, "press_release")}
                  className={styles.draftTypeCellOverlay}
                >
                  <PlusCircle size="24px" />
                </View>
                <Projector size="40px" />
                <H6 className={styles.draftTypeCellTitle} size="small">
                  Press Release
                </H6>
              </View>
              <View className={styles.draftTypeCell}>
                <View
                  onClick={() => openNewArticleModal(report, "news_article")}
                  className={styles.draftTypeCellOverlay}
                >
                  <PlusCircle size="24px" />
                </View>
                <Newspaper size="40px" />
                <H6 className={styles.draftTypeCellTitle} size="small">
                  News Article
                </H6>
              </View>
              <View className={styles.draftTypeCell}>
                <View
                  onClick={() => openNewArticleModal(report, "opinion_piece")}
                  className={styles.draftTypeCellOverlay}
                >
                  <PlusCircle size="24px" />
                </View>
                <Mic size="40px" />
                <H6 className={styles.draftTypeCellTitle} size="small">
                  Opinion Piece
                </H6>
              </View>
            </View>
            <Spacer size="small" />
            <Spacer size="large" />
            <View className={styles.pageHeader}>
              <View vertical className={styles.pageHeaderLeftContainer}>
                <H5 className={styles.smallH5}>Articles for {report.name}</H5>
                <Spacer size="small" />
                <Paragraph>
                  Voila. Here are the articles we drafted for you.
                </Paragraph>
              </View>
              <View gap="small" className={styles.pageHeaderRightContainer}>
                {site && articles && (
                  <SecondaryButton
                    size="small"
                    className={styles.smallIconButton}
                    onClick={() => {
                      downloadZipFile(articles);
                    }}
                  >
                    <Download size="16px" /> Download as .zip
                  </SecondaryButton>
                )}
                {site && site.shopifyToken && articles && (
                  <>
                    <SecondaryButton
                      size="small"
                      className={`${styles.smallIconButton} ${styles.shopifyButton}`}
                      onClick={() => {
                        openPublishOnShopifyModal(site, articles);
                      }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "4px",
                        }}
                        src="https://cdn-icons-png.flaticon.com/512/2504/2504814.png"
                      />{" "}
                      Export
                    </SecondaryButton>
                  </>
                )}
                {site && site.ghostToken && articles && (
                  <>
                    <SecondaryButton
                      size="small"
                      className={`${styles.smallIconButton} ${styles.ghostButton}`}
                      onClick={() => {
                        openPublishOnGhostModal(site, articles);
                      }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "4px",
                        }}
                        className={darkMode && styles.inverted}
                        src="https://ghost.org/images/logos/ghost-logo-orb.png"
                      />{" "}
                      Export
                    </SecondaryButton>
                  </>
                )}
                {site && site.wordpressToken && articles && (
                  <>
                    <SecondaryButton
                      size="small"
                      className={`${styles.smallIconButton} ${styles.wordpressButton}`}
                      onClick={() => {
                        openPublishOnWordpressModal(site, articles);
                      }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "4px",
                        }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png"
                      />{" "}
                      Export
                    </SecondaryButton>
                  </>
                )}
                {site && site.webflowToken && articles && (
                  <>
                    <PrimaryButton
                      size="small"
                      className={`${styles.smallIconButton} ${styles.webflowButton}`}
                      onClick={() => {
                        openPublishOnWebflowModal(site, articles);
                      }}
                    >
                      <img
                        style={{
                          width: "32px",
                          height: "32px",
                          marginLeft: "-6px",
                          marginRight: "-6px",
                        }}
                        src="https://cdn.icon-icons.com/icons2/2699/PNG/512/webflow_logo_icon_169218.png"
                      />{" "}
                      Export
                    </PrimaryButton>
                  </>
                )}
                {site && site.webhookToken && articles && (
                  <>
                    <SecondaryButton
                      size="small"
                      className={`${styles.smallIconButton} ${styles.webhookButton}`}
                      onClick={() => {
                        openPublishWithWebhookModal(site, articles);
                      }}
                    >
                      <Webhook size="32" /> Export
                    </SecondaryButton>
                  </>
                )}
              </View>
            </View>
            <Spacer size="medium" />
            {false && <Articles data={articles} />}
            {true && (
              <Table
                openPublishOnWebflowModal={openPublishOnWebflowModal}
                openPublishWithWebhookModal={openPublishWithWebhookModal}
                openPublishOnWordpressModal={openPublishOnWordpressModal}
                openPublishOnShopifyModal={openPublishOnShopifyModal}
                openPublishOnGhostModal={openPublishOnGhostModal}
                site={site}
                accordion
                openDeleteArticleModal={openDeleteArticleModal}
                emptyDataTitle={"No articles found."}
                emptyDataMessage={"Your articles will appear here."}
                data={articles}
                fields={[
                  {
                    name: "Name",
                    data: "name",
                  },
                ]}
                onClickHandler={(item) => {
                  /*setReport(item);
                getArticles(item);*/
                }}
              />
            )}
          </View>
        </View>
      );
    }

    if (site !== null) {
      return (
        <View
          className={styles.contentContainer2}
          vertical
          style={{
            width: !showMenu ? `calc(100% - 75px)` : `calc(100% - 285px)`,
          }}
        >
          <View vertical className={styles.innerView}>
            <WelcomeMessage />
            <View
              centerVertically
              centerHorizontally
              className={`${styles.banner} ${!userDetails && styles.hidden}`}
            >
              {stats === null ? (
                <Oval
                  height={"24"}
                  width={"24"}
                  color="var(--neutral-400)"
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="var(--neutral-800)"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              ) : (
                <Stats stats={stats} />
              )}
            </View>
            <Spacer size="large" />
            <View centerVertically gap="small">
              <Link
                size="small"
                onClick={() => {
                  setReports(null);
                  setSite(null);
                  setArticles(null);
                  setReport(null);
                }}
              >
                Sites
              </Link>
              <ChevronRight
                style={{ color: "var(--neutral-500)" }}
                size="16px"
              />
              <Paragraph size="small">{site.name}</Paragraph>
            </View>
            <View vertical>
              <Spacer size="large" />
              <View className={styles.pageHeader}>
                <View vertical className={styles.pageHeaderLeftContainer}>
                  <H5 className={styles.smallH5}>
                    Article reports for {site.name}
                  </H5>
                  <Spacer size="small" />
                  <Paragraph>Each report is a collection of articles</Paragraph>
                </View>
                <View className={styles.pageHeaderRightContainer}>
                  <SecondaryButton
                    size="small"
                    className={styles.smallIconButton}
                    onClick={() => openNewReportModal(site)}
                  >
                    <Plus size="16px" /> New report
                  </SecondaryButton>
                </View>
              </View>
            </View>
            <Spacer size="medium" />
            <Table
              emptyDataTitle={"No reports found."}
              emptyDataMessage={"Your reports will appear here."}
              data={reports}
              fields={[
                {
                  name: "Name",
                  data: "name",
                },
                {
                  name: "Status",
                  data: "status",
                },
              ]}
              onClickHandler={(item) => {
                setReport(item);
                getArticles(item);
              }}
              menu={(item) => {
                return (
                  <Menu>
                    <MenuButton>
                      <Button
                        style={{ maxWidth: "18px", padding: "0px !important" }}
                        className={styles.editListItem}
                        tooltip="More"
                        size="mini"
                      >
                        <MoreVertical size="16" />
                      </Button>
                    </MenuButton>
                    <MenuList className={styles.dropMenu}>
                      <MenuGroup className={styles.dropMenuGroup}>
                        <Button
                          onClick={() => {
                            openEditReportModal(item);
                          }}
                          className={styles.listButton}
                        >
                          <Edit2 size="14px" /> Edit report
                        </Button>
                        <Button
                          onClick={() => {
                            openDeleteReportModal(item);
                          }}
                          style={{
                            color: "var(--red)",
                          }}
                          className={styles.listButton}
                        >
                          <Trash size="14px" /> Delete report
                        </Button>
                      </MenuGroup>
                      <Spacer size="small" />
                    </MenuList>
                  </Menu>
                );
              }}
            />
          </View>
        </View>
      );
    }
    return (
      <View
        className={styles.contentContainer2}
        vertical
        style={{
          width: !showMenu ? `calc(100% - 75px)` : `calc(100% - 285px)`,
        }}
      >
        <View vertical className={styles.innerView}>
          <WelcomeMessage />
          <View
            centerVertically
            centerHorizontally
            className={`${styles.banner} ${!userDetails && styles.hidden}`}
          >
            {stats === null ? (
              <Oval
                height={"24"}
                width={"24"}
                color="var(--neutral-400)"
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--neutral-800)"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            ) : (
              <Stats stats={stats} />
            )}
          </View>
          {!userDetails ? <Spacer size="medium" /> : <Spacer size="large" />}
          <View className={styles.pageHeader}>
            <View vertical className={styles.pageHeaderLeftContainer}>
              <H5 className={styles.smallH5}>Sites</H5>
              <Spacer size="small" />
              <Paragraph>Manage multiple sites from one account.</Paragraph>
            </View>
            <View className={styles.pageHeaderRightContainer}>
              {
                userDetails === null ? (
                  <SecondaryButton
                    className={styles.smallIconButton}
                    to="/login"
                    size="small"
                  >
                    <Plus size="16px" /> New site
                  </SecondaryButton>
                ) : (
                  <SecondaryButton
                    className={styles.smallIconButton}
                    onClick={openNewSiteModal}
                    size="small"
                  >
                    <Plus size="16px" /> New site
                  </SecondaryButton>
                )
              }
            </View>
          </View>
          <Spacer size="medium" />
          <Table
            emptyDataTitle={"No sites found."}
            emptyDataMessage={"Your sites will appear here."}
            data={sites}
            fields={[
              {
                name: "Name",
                data: "name",
              },
              {
                name: "Domain",
                data: "uri",
              },
            ]}
            onClickHandler={(item) => {
              setSite(item);
              getReports(item);
            }}
            menu={(item) => {
              return (
                <Menu>
                  <MenuButton>
                    <Button
                      style={{ maxWidth: "18px", padding: "0px !important" }}
                      className={styles.editListItem}
                      tooltip="More"
                      size="mini"
                    >
                      <MoreVertical size="16" />
                    </Button>
                  </MenuButton>
                  <MenuList className={styles.dropMenu}>
                    <MenuGroup className={styles.dropMenuGroup}>
                      <Button
                        onClick={() => {
                          openEditSiteModal(item);
                        }}
                        className={styles.listButton}
                      >
                        <Edit2 size="14px" /> Edit site
                      </Button>
                      <Button
                        onClick={() => {
                          openDeleteSiteModal(item);
                        }}
                        style={{
                          color: "var(--red)",
                        }}
                        className={styles.listButton}
                      >
                        <Trash size="14px" /> Delete site
                      </Button>
                    </MenuGroup>
                    <Spacer size="small" />
                  </MenuList>
                </Menu>
              );
            }}
          />
        </View>
      </View>
    );
  }

  const { sendEmail } = useContext(EmailContext);

  const [passwordLinkSent, setPasswordLinkSent] = useState(false);

  async function changePassword() {
    setPasswordLinkSent(true);
    await sendEmail({
      from: "noreply@draftsai.com",
      fromName: "Drafts AI (no-reply)",
      to: userDetails.email,
      subject: "Update your Drafts AI password",
      template: "set-password",
      templateParams: {
        hash: "sha256",
        sessionEmail: userDetails.email // Passing this will tie the hash to the email.
      },
    });
  }

  useEffect(() => {
    if (passwordLinkSent === true) {
      setTimeout(() => {
        setPasswordLinkSent(false);
      }, 5000)
    }
  }, [passwordLinkSent])

  const [editingName, setEditingName] = useState(false);

  function CurrentTab() {
    switch (selectedTab.id) {
      case "sites":
        return <SitesTab />;

      case "outbound":
        return <Prospects showMenu={showMenu} />;

      case "users":
        return <Users showMenu={showMenu} />;

      case "billing":
        return (
          <>
          <Spacer size="large" />
          <Spacer size="large" />
          <Elements stripe={stripePromise}>
            <Billing showMenu={showMenu} />
          </Elements></>
        );

      case "tutorials":
        return <Tutorials showMenu={showMenu} />;

      case "settings":
        return (
          <View
            style={{ width: !showMenu && `calc(100% - 75px)` }}
            className={styles.settingsContainer}
          >
            <View vertical className={styles.settingsNav}>
              <Button
                size="small"
                linkClassName={styles.fullWidth}
                onClick={() =>
                  setSelectedSettingsTab({
                    id: "account",
                    name: "Account Settings",
                    desc: "Change your account settings.",
                  })
                }
                className={`${styles.fullWidth} ${styles.largeTabButton} ${selectedSettingsTab.id === "account"
                  ? styles.selectedTabButton
                  : ""
                  }`}
              >
                Account
              </Button>
              <Button
                size="small"
                linkClassName={styles.fullWidth}
                onClick={() =>
                  setSelectedSettingsTab({
                    id: "security",
                    name: "Security Settings",
                    desc: "Change your security settings.",
                  })
                }
                className={`${styles.fullWidth} ${styles.largeTabButton} ${selectedSettingsTab.id === "security"
                  ? styles.selectedTabButton
                  : ""
                  }`}
              >
                Security
              </Button>
              <Button
                size="small"
                linkClassName={styles.fullWidth}
                onClick={() =>
                  setSelectedSettingsTab({
                    id: "billing",
                    name: "Billing Settings",
                    desc: "Change your billing settings.",
                  })
                }
                className={`${styles.fullWidth} ${styles.largeTabButton} ${selectedSettingsTab.id === "billing"
                  ? styles.selectedTabButton
                  : ""
                  }`}
              >
                Billing
              </Button>
              <Button
                size="small"
                linkClassName={styles.fullWidth}
                onClick={() =>
                  setSelectedSettingsTab({
                    id: "privacy",
                    name: "Privacy Settings",
                    desc: "Change your privacy settings.",
                  })
                }
                className={`${styles.fullWidth} ${styles.largeTabButton} ${selectedSettingsTab.id === "privacy"
                  ? styles.selectedTabButton
                  : ""
                  }`}
              >
                Privacy
              </Button>
              {userDetails &&
                (userDetails.type === 4 || userDetails.type === 3) && (
                  <Button
                    size="small"
                    linkClassName={styles.fullWidth}
                    onClick={() =>
                      setSelectedSettingsTab({
                        id: "enterprise",
                        name: "Enterprise Settings",
                        desc: "Change your Enterprise settings.",
                      })
                    }
                    className={`${styles.fullWidth} ${styles.largeTabButton} ${selectedSettingsTab.id === "enterprise"
                      ? styles.selectedTabButton
                      : ""
                      }`}
                  >
                    Enterprise
                  </Button>
                )}
            </View>

            <View
              vertical
              style={{
                width: !showMenu ? "calc(100% - 75px)" : "calc(100% - 570px)",
              }}
              className={styles.settingsContent}
            >
              <View vertical>
                <H5 className={styles.settingsTitle}>
                  {selectedSettingsTab.name}
                </H5>
                <Spacer size="medium" />
                <Paragraph size="small">{selectedSettingsTab.desc}</Paragraph>
              </View>
              <Spacer size="medium" />
              <Separator className={styles.wideSeparator} />
              <Settings />
            </View>
          </View>
        );
      case "profile":
        return (
          <View
            vertical
            style={{ width: !showMenu && `calc(100% - 75px)` }}
            className={`${styles.content2} ${styles.profileContent}`}
          >
            <View className={styles.headerImage}></View>
            <View className={styles.profileBlock}>
              <View gap="large" vertical>
                {userDetails && (
                  <ProfilePicture
                    bordered
                    tooltip="Press to change profile picture"
                    name={userDetails.name}
                    url={userDetails.picture}
                  />
                )}
                <View vertical>
                  <View centerVertically>
                    <H5
                      tooltip="Change name"
                      inputClassName={styles.editInput}
                      editClassName={styles.editName}
                      editable
                      onEdit={onEditName}
                      className={styles.userTitle}
                    >
                      {userDetails.name}
                    </H5>
                  </View>
                  <View className={styles.handleContainer} centerVertically>
                    <Paragraph className={styles.handle}>
                      @{userDetails.link}
                    </Paragraph>
                  </View>
                  <Spacer size="medium" />
                  <Paragraph tooltip="Edit bio" editable onEdit={onEditBio}>
                    {userDetails.bio || "No bio available."}
                  </Paragraph>
                </View>
              </View>
            </View>
          </View>
        );
    }
  }

  function CommandIcon(props) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

    if (props.inverted) {
      return (
        <div>
          {isMac ? (
            <Command
              size="10"
              color={
                selectedTab.id === props.tab
                  ? "var(--neutral-800)"
                  : "var(--neutral-800)"
              }
            />
          ) : (
            <Paragraph
              style={{
                color: "var(--neutral-800)",
                fontSize: "10px",
                lineHeight: "15px",
                marginRight: "2px",
              }}
            >
              CTRL
            </Paragraph>
          )}
        </div>
      );
    }

    return (
      <div>
        {isMac ? (
          <Command
            size="10"
            color={
              selectedTab.id === props.tab
                ? "var(--neutral-100)"
                : "var(--neutral-500)"
            }
          />
        ) : (
          <Paragraph
            style={{ fontSize: "10px", lineHeight: "15px", marginRight: "2px" }}
          >
            CTRL
          </Paragraph>
        )}
      </div>
    );
  }

  return (
    <Container className={styles.container}>
      <View style={{ width: !showMenu && 75 }} className={styles.menu}>
        <View className={styles.logoContainer}>
          <View className={styles.logoWrapper}>
            {showMenu && <Logo style={{ marginTop: "5px" }} size="small" />}
            <Spacer size="large" />

            <View className={styles.rightContainer}>
              <Button
                onClick={toggleMenu}
                tooltip={showMenu ? "Collapse menu" : "Show menu"}
                className={styles.squareButton}
                size="small"
              >
                {showMenu ? (
                  <Columns size="18" color="var(--neutral-500)" />
                ) : (
                  <Columns size="18" color="var(--neutral-500)" />
                )}
              </Button>
            </View>
          </View>
          <Separator className={styles.separator} />
        </View>
        <Spacer size="small" />
        <View className={styles.buttonContainer}>
          <Button
            tooltip={!showMenu && "Sites"}
            onClick={() =>
              handleTabChange({
                id: "sites",
                name: "Sites",
              })
            }
            linkClassName={styles.tabLink}
            className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
              } ${selectedTab.id === "sites" ? styles.selectedTabButton : ""}`}
          >
            <Globe size={16} className={styles.tabIcon} />
            Sites
          </Button>
        </View>
        <Spacer size="small" />
        <Separator className={`${styles.separator} ${styles.semiSeparator}`} />
        <View className={styles.titleContainer}>
          <Paragraph
            className={`${styles.title} ${!showMenu && styles.fixedTitle}`}
          >
            Resources
          </Paragraph>
        </View>
        <View className={styles.buttonContainer}>
          <Button
            tooltip={!showMenu && "Tutorials"}
            onClick={() =>
              handleTabChange({
                id: "tutorials",
                name: "Tutorials",
              })
            }
            linkClassName={styles.tabLink}
            className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
              } ${selectedTab.id === "tutorials" ? styles.selectedTabButton : ""
              }`}
          >
            <AcademicCapIcon size={16} className={styles.tabIcon} />
            Tutorials
          </Button>
          <Button
            disabled
            tooltip={!showMenu && "API"}
            onClick={() =>
              handleTabChange({
                id: "api",
                name: "API",
              })
            }
            linkClassName={styles.tabLink}
            className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
              } ${selectedTab.id === "api" ? styles.selectedTabButton : ""} ${styles.disabledTabButton
              }`}
          >
            <CodeBracketIcon size={16} className={styles.tabIcon} />
            API <span className={styles.tag}>Soon</span>
          </Button>
        </View>
        <Spacer size="small" />
        <Separator className={`${styles.separator} ${styles.semiSeparator}`} />
        <View className={styles.titleContainer}>
          <Paragraph
            className={`${styles.title} ${!showMenu && styles.fixedTitle}`}
          >
            Help Center
          </Paragraph>
        </View>
        <View className={styles.buttonContainer}>
          <Button
            tooltip={!showMenu && "Billing"}
            onClick={() =>
              handleTabChange({
                id: "billing",
                name: "Billing",
              })
            }
            linkClassName={styles.tabLink}
            className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
              } ${selectedTab.id === "billing" ? styles.selectedTabButton : ""}`}
          >
            <CreditCardIcon size={16} className={styles.tabIcon} />
            Billing
          </Button>
          <Button
            tooltip={!showMenu && "Support"}
            onClick={() => {
              window.location.href =
                "mailto:contact@draftsai.com?subject=Regarding Drafts";
            }}
            linkClassName={styles.tabLink}
            className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
              } ${selectedTab.id === "help-center" ? styles.selectedTabButton : ""
              }`}
          >
            <LifebuoyIcon size={16} className={styles.tabIcon} />
            Support
          </Button>
        </View>
        {userDetails && userDetails.type === 3 && (
          <>
            <Spacer size="small" />
            <Separator
              className={`${styles.separator} ${styles.semiSeparator}`}
            />
            <View className={styles.titleContainer}>
              <Paragraph
                className={`${styles.title} ${!showMenu && styles.fixedTitle}`}
              >
                Admin
              </Paragraph>
            </View>
            <View className={styles.buttonContainer}>
              <Button
                tooltip={!showMenu && "Users"}
                onClick={() =>
                  handleTabChange({
                    id: "users",
                    name: "Users",
                  })
                }
                linkClassName={styles.tabLink}
                className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
                  } ${selectedTab.id === "users" ? styles.selectedTabButton : ""
                  }`}
              >
                <Users2 size={16} className={styles.tabIcon} />
                Users
              </Button>
              <Button
                tooltip={!showMenu && "Outbound"}
                onClick={() =>
                  handleTabChange({
                    id: "outbound",
                    name: "Outbound",
                  })
                }
                linkClassName={styles.tabLink}
                className={`${styles.tabButton} ${!showMenu && styles.fixedTabButton
                  } ${selectedTab.id === "outbound" ? styles.selectedTabButton : ""
                  }`}
              >
                <Mail size={16} className={styles.tabIcon} />
                Outbound
              </Button>
            </View>
          </>
        )}
        <Spacer size="large" />
      </View>
      <View
        vertical
        style={{ left: !showMenu && 75 }}
        className={styles.wrapper}
      >
        <View
          style={{ width: !showMenu && `calc(100% - 75px)` }}
          className={styles.titleWrapper}
        >
          <H6 className={styles.pageTitle}>{selectedTab.name}</H6>
          <View centerVertically className={styles.rightContainer}>
            {userDetails !== null && (
              <Tooltip label="sdf" className={styles.userTooltip}>
                <Menu zIndex="dropdown">
                  <MenuButton>
                    <Button className={styles.bellButton} size="mini">
                      <BellIcon size={18} className={styles.bellIcon} />
                    </Button>
                  </MenuButton>
                  <MenuList className={styles.dropMenu}>
                    <MenuGroup className={styles.dropMenuGroup}>
                      <View className={styles.notificationsContainer}>
                        <BellIcon size={22} className={styles.largeBellIcon} />
                        <Spacer size="medium" />
                        <H6>Notifications</H6>
                        <Spacer size="small" />
                        <Paragraph className={styles.smallParagraph}>
                          You haven't received any notifications.
                        </Paragraph>
                      </View>
                    </MenuGroup>
                    <Spacer size="small" />
                  </MenuList>
                </Menu>
              </Tooltip>
            )}
            <Button
              onClick={() => {
                window.location.href =
                  "mailto:contact@draftsai.com?subject=Regarding Drafts";
              }}
              className={styles.bellButton}
              size="mini"
            >
              <QuestionMarkCircleIcon size={18} className={styles.bellIcon} />
            </Button>
            {userDetails !== null && (
              <Menu zIndex="dropdown">
                <MenuButton className={styles.profileButton}>
                  <ProfilePicture
                    size="small"
                    name={userDetails.name}
                    url={userDetails.picture}
                  />
                </MenuButton>
                <MenuList className={styles.dropMenu}>
                  <MenuGroup className={styles.dropMenuGroup}>
                    <MenuItem
                      onClick={() =>
                        handleTabChange({
                          id: "profile",
                          name: "Profile",
                        })
                      }
                      className={styles.dropMenuItem}
                    >
                      <UserIcon size={16} className={styles.menuIcon} /> Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleTabChange({
                          id: "billing",
                          name: "billing",
                        })
                      }
                      className={styles.dropMenuItem}
                    >
                      <BoltIcon size={16} className={styles.menuIcon} /> Upgrade
                    </MenuItem>
                  </MenuGroup>
                  <Spacer size="small" />
                  <Separator className={styles.dropMenuDivider} />
                  <Spacer size="small" />
                  <MenuGroup className={styles.dropMenuGroup}>
                    <MenuItem
                      onClick={() =>
                        handleTabChange({
                          id: "settings",
                          name: "Settings",
                        })
                      }
                      className={styles.dropMenuItem}
                    >
                      <Cog6ToothIcon size={16} className={styles.menuIcon} />{" "}
                      Settings
                    </MenuItem>
                    <MenuItem onClick={signOut} className={styles.dropMenuItem}>
                      <ArrowRightOnRectangleIcon
                        size={16}
                        className={styles.menuIcon}
                      />{" "}
                      Sign Out
                    </MenuItem>
                  </MenuGroup>
                  <Spacer size="small" />
                </MenuList>
              </Menu>
            )}
            {userDetails === null && <Spacer size="small" />}
            {userDetails === null && (
              <PrimaryButton to="/login" size="mini">
                Sign In
              </PrimaryButton>
            )}
          </View>
        </View>
        <CurrentTab />
      </View>
    </Container>
  );
}
