import { gql } from "@apollo/client";

export const GET_ARTICLES_BY_REPORTID = gql`
  query getArticlesByReportId($reportId: Int!) {
    getArticlesByReportId(reportId: $reportId) {
      articleId
      reportId
      name
      content
      date
      summary
      instruction
      archived
    }
  }
`;