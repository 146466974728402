import React, { useContext } from "react";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/RadioCell.module.css";
import {
  Briefcase,
  Check,
  Code,
  Key,
  Layers,
  List,
  Smartphone,
  Tool,
} from "react-feather";
import Button from "../buttons/Button";
import { ConfigContext } from "../../context/ConfigProvider";
import { Webhook } from "lucide-react";

export default function RadioCell(props) {
  const { darkMode } = useContext(ConfigContext);

  function Icon({ icon }) {
    switch (icon) {
      case "code":
        return <Code color="var(--neutral-400)" size="75" />;

      case "briefcase":
        return <Briefcase color="var(--neutral-400)" size="75" />;

      case "wrench":
        return <Tool color="var(--neutral-400)" size="75" />;

      case "smartphone":
        return <Smartphone color="var(--neutral-400)" size="75" />;

      case "layers":
        return <Layers color="var(--neutral-400)" size="75" />;

      case "list":
        return <List color="var(--neutral-400)" size="75" />;

      case "key":
        return <Key color="var(--neutral-400)" size="75" />;

      case "webhook":
        return <Webhook color="var(--neutral-400)" size="75" />;
    }
  }
  return (
    <Button onClick={props.onClick} className={`${styles.buttonWrapper}`}>
      <View
        gap="small"
        vertical
        centerVertically
        className={`${styles.defaults} ${props.selected && styles.selected}`}
      >
        <View
          className={`${styles.checkbox} ${
            props.selected && styles.filledCheckbox
          }`}
        >
          {props.selected && <Check color="var(--neutral-1000)" size="16" />}
        </View>
        {props.icon && <Icon icon={props.icon} />}
        {props.image && (
          <img
            className={`${styles.image} ${
              props.title === "Ghost" && darkMode && styles.inverted
            }`}
            src={props.image}
          />
        )}
        <Spacer size="small" />
        <View className={styles.swampCore}>
          <H6>{props.title || "-"}</H6>
          <Spacer size="small" />
          <Paragraph className={styles.paragraph} size="small">
            {props.subtitle || "-"}
          </Paragraph>
        </View>
      </View>
    </Button>
  );
}
