import React, { useContext } from "react";
import styles from "./styles/Link.module.css";
import { Link as RouterLink } from 'react-router-dom';
import { ConfigContext } from "../../context/ConfigProvider";

export default function Link(props) {

  const { darkMode } = useContext(ConfigContext);

  if (props.shiny) {
    return (
      <div className={styles.shineContainer}>
        <RouterLink onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onClick={props.onClick} style={props.style} target={props.target} disabled={props.disabled} to={props.to} className={`${styles.defaults} ${props.className} ${props.underlined && styles.underlined} ${props.size == 'small' && styles.small} ${props.shiny && styles.shineLink} ${props.size == 'big' && styles.big} ${darkMode && styles.white}`}>
          {props.children}
        </RouterLink>
      </div>
    );
  } else {
    return (
      <RouterLink onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onClick={props.onClick} style={props.style} target={props.target} disabled={props.disabled} to={props.to} className={`${styles.defaults} ${props.className} ${props.underlined && styles.underlined} ${props.size == 'small' && styles.small} ${props.shiny && styles.shineLink} ${props.size == 'big' && styles.big} ${darkMode && styles.white}`}>
        {props.children}
      </RouterLink>
    );
  }
}

