import React, { useContext, useState } from "react";
import styles from "./styles/ProfilePicture.module.css";
import H2 from "../../text/heading/H2";
import View from "../View";
import { Upload } from "react-feather";
import axios from "axios";
import { StorageContext } from "../../../context/StorageProvider";
import { useApolloClient } from "@apollo/client";
import { UPDATE_PICTURE, UPDATE_USER } from "../../../graphql/mutations/Users";
import config from "../../../config.json";

export default function ProfilePicture({
  bordered,
  edit,
  tooltip,
  containerClassName,
  className,
  onClick,
  size,
  name,
  url,
}) {
  const [selectedImage, setSelectedImage] = useState(null);

  const client = useApolloClient();

  const { userDetails, setUserDetails } = useContext(StorageContext);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload the image to the server
      let formData = new FormData();
      if (userDetails && userDetails.picture) {
        formData.append("filename", userDetails.picture.split("/").pop());
        await axios.delete(
          (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
            `/api/removeImage/${formData.get("filename")}`
        );
      }
      formData = new FormData();
      formData.append("profilePicture", file);
      await axios
        .post(
          (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
            "/api/uploadProfilePicture",
          formData
        )
        .then((response) => {
          client
            .mutate({
              mutation: UPDATE_PICTURE,
              variables: {
                email: userDetails.email,
                picture:
                  (config.PROD ? config.PROD_REST_API_URL : config.DEV_REST_API_URL) +
                  response.data.url,
              },
            })
            .then((response) => {
              if (response && response.data && response.data.updateUser) {
                setUserDetails(response.data.updateUser);
              }
            })
            .catch((error) => {
              console.error("Error updating user:", error);
            });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  const getInitials = (name) => {
    const initials = name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
    return initials;
  };

  return (
    <button
      tooltip={tooltip}
      onClick={onClick}
      className={`${styles.defaults} ${className} ${
        size === "small" && styles.small
      }`}
    >
      {edit && (
        <View
          centerVertically
          centerHorizontally
          className={`${styles.hiddenOverlay} ${containerClassName}`}
        >
          <input
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleImageChange}
            tooltip={tooltip}
            onClick={onClick}
            className={`${styles.input}`}
          />
          <Upload className={styles.uploadIcon} size="20" color="#fff" />
        </View>
      )}
      {url ? (
        <img
          src={url}
          alt={name}
          className={`${styles.viewDefaults} ${containerClassName} ${
            bordered && styles.bordered
          } ${size === "small" && styles.smallView}`}
        />
      ) : (
        <View
          className={`${styles.viewDefaults} ${containerClassName} ${
            bordered && styles.bordered
          } ${size === "small" && styles.smallView}`}
        >
          <H2
            className={`${styles.initials} ${
              size === "small" && styles.smallText
            }`}
          >
            {getInitials(name)}
          </H2>
        </View>
      )}
    </button>
  );
}
