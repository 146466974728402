import React, { useContext } from "react";
import styles from "./styles/TextArea.module.css";
import { ConfigContext } from "../../context/ConfigProvider";

const TextArea = React.forwardRef((props, ref) => {

  const { darkMode } = useContext(ConfigContext);

  return (
    <textarea
      ref={ref}
      value={props.value}
      type={props.type || "text"}
      placeholder={props.placeholder}
      className={`${darkMode ? styles.darkDefaults : styles.defaults} ${props.className} ${
        props.size == "big"
          ? styles.big
          : props.size == "small"
          ? styles.small
          : ""
      }`}
      onChange={props.onChange}
    />
  );
});

export default TextArea;