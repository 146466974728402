import { ChakraProvider } from "@chakra-ui/react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import CookieBanner from "./components/cookies/CookieBanner";
import Footer from "./components/navigation/Footer";
import Nav from "./components/navigation/Nav";
import App from "./components/pages/App";
import Auth from "./components/pages/Auth";
import CompanyLanding from "./components/pages/CompanyLanding";
import Join from "./components/pages/Join";
import NotFound from "./components/pages/NotFound";
import Login from "./components/pages/Login";
import Policy from "./components/pages/Policy";
import StyleGuide from "./components/pages/StyleGuide";
import config from "./config.json";
import { AlertProvider } from "./context/AlertProvider";
import { ConfigContext } from "./context/ConfigProvider";
import { EmailProvider } from "./context/EmailProvider";
import { OverlayProvider } from "./context/OverlayProvider";
import { RadioCellProvider } from "./context/RadioCellProvider";
import { SectionProvider } from "./context/SectionProvider";
import { SiteProdiver } from "./context/SiteProvider";
import { StorageContext } from "./context/StorageProvider";
import Blog from "./components/pages/Blog";
import NewBlogArticle from "./components/pages/NewBlogArticle";
import BlogArticle from "./components/pages/BlogArticle";
import Unsubscribe from "./components/pages/Unsubscribe";
import SetPassword from "./components/pages/SetPassword";

export default function Main() {
  const { setDarkMode } = useContext(ConfigContext);

  const emotionCache = createCache({
    key: "emotion-css-cache",
    prepend: true, // ensures styles are prepended to the <head>, instead of appended
  });

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    // Check the initial value
    setDarkMode(darkModeMediaQuery.matches);

    // Update the value when the preference changes
    const handleChange = (event) => {
      setDarkMode(event.matches);
    };
    darkModeMediaQuery.addEventListener("change", handleChange);

    // Clean up the event listener when the component unmounts
    return () => {
      darkModeMediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <Router>
      <CacheProvider value={emotionCache}>
        <GoogleOAuthProvider clientId="241875541802-73lu2v9c9m521mjpe4jebt3mrmul2ghc.apps.googleusercontent.com">
          <ChakraProvider>
            <EmailProvider>
              <AlertProvider>
                <SectionProvider>
                  <SiteProdiver>
                    <RadioCellProvider>
                      <OverlayProvider>
                        <AppContent />
                      </OverlayProvider>
                    </RadioCellProvider>
                  </SiteProdiver>
                </SectionProvider>
              </AlertProvider>
            </EmailProvider>
          </ChakraProvider>
        </GoogleOAuthProvider>
      </CacheProvider>
    </Router>
  );
}

function AppContent() {
  const { acceptedCookies, hideCookieBanner } = useContext(StorageContext);

  const { darkMode } = useContext(ConfigContext);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (darkMode) {
      link.href =
        (config.PROD ? config.PROD_URL : config.DEV_URL) +
        "/assets/images/icon_white.png";
    }
  }, []);

  return (
    <div className="body">
      <Nav />
      {!acceptedCookies || acceptedCookies.closed === false ? (
        <CookieBanner />
      ) : null}
      <Routes>
        <Route exact path="/" element={<CompanyLanding />} />
        <Route exact path="/for-business" element={<CompanyLanding />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/new" element={<NewBlogArticle />} />
        <Route path="/blog/:slug" element={<BlogArticle />} />
        <Route path="/brand" element={<StyleGuide />} />
        <Route path="/app" element={<App />} />
        <Route path="/app/*" element={<App />} />
        <Route path="/auth/:hash" element={<Auth />} />
        <Route path="/login" element={<Login />} />
        <Route path="/set-password/:hash" element={<SetPassword />} />
        <Route path="/privacy-policy" element={<Policy policy="privacy" />} />
        <Route path="/cookie-policy" element={<Policy policy="cookie" />} />
        <Route path="/terms-of-service" element={<Policy policy="tos" />} />
        <Route path="/signup" element={<Login signup />} />
        <Route path="/join" element={<Join />} />
        <Route path="/unsubscribe/:email" element={<Unsubscribe />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}
